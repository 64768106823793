import { Base } from "./Base";

export class BaseRegistrationPressureEquipment extends Base {
    constructor() {
        super();
        this.step = 1;
        this.workingTitle = null;
        this.intendedUse = null;
        this.inOperationYear = null;

        this.manufacturer = null;
    }
}

export class BaseRegistrationFireBottlesEquipment extends Base {
    constructor() {
        super();
        this.step = 1;
        this.workingTitle = null;
        this.intendedUse = null;
        this.inOperationYear = null;

        this.installator = null;
        this.assetNumber = null;
    }
}
