<template>
    <v-container class="px-2" @click="$emit('click:container')">
        <form-equipment-details-pressure-equipment
            v-if="registration.objectType  === 'PRESSURE_EQUIPMENT'"
            ref="formEquipmentDetailsPressureEquipment"
            v-on:field-change="onFieldChangePressureEquipment"
            :registrationData.sync="registration"
            :readonlyModus="readonlyModus"
            v-bind="$attrs"
            v-on="$listeners"
        >
        </form-equipment-details-pressure-equipment>
        <FormEquipmentDetailsFireBottleEquipment
            v-if="registration.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
            ref="formEquipmentDetailsFireBottlesEquipment"
            v-on:field-change="onFieldChangeFireBottlesEquipment"
            :registrationData.sync="registration"
            :readonlyModus="readonlyModus"
            v-bind="$attrs"
            v-on="$listeners"
        >
        </FormEquipmentDetailsFireBottleEquipment>
    </v-container>
</template>
<script>
import FormEquipmentDetailsFireBottleEquipment from "@/components/tasks/edit/common/forms/form-equipment-details-fire-bottles-equipment.vue";
import FormEquipmentDetailsPressureEquipment from "@/components/tasks/edit/common/forms/form-equipment-details-pressure-equipment.vue";
export default {
    name: "StepObjectPropertiesContent",
    props: {
        registration: {
            type: Object,
            default: () => {},
            required: true
        },
        readonlyModus: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FormEquipmentDetailsFireBottleEquipment,
        FormEquipmentDetailsPressureEquipment
    },
    methods: {
        onFieldChangePressureEquipment({ field, value }) {
            if (this.registration.objectType !== 'PRESSURE_EQUIPMENT') {
                // Not the active form. Ignore field changes.
                return;
            }
            if (!this.readonlyModus) {
                this.$emit("field-change", { field, value });
            }
        },
        onFieldChangeFireBottlesEquipment({ field, value }) {
            if (this.registration.objectType !== 'FIRE_BOTTLES_EQUIPMENT') {
                // Not the active form. Ignore field changes.
                return;
            }
            if (!this.readonlyModus) {
                this.$emit("field-change", { field, value });
            }
        },
        validate() {
            if (this.registration.objectType === 'PRESSURE_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsPressureEquipment.validate();
            }
            if (this.registration.objectType === 'FIRE_BOTTLES_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsFireBottlesEquipment.validate();
            }
            return false;
        },
        reset() {
            if (this.registration.objectType === 'PRESSURE_EQUIPMENT') {
                this.$refs.formEquipmentDetailsPressureEquipment.reset();
            }
            if (this.registration.objectType === 'FIRE_BOTTLES_EQUIPMENT') {
                this.$refs.formEquipmentDetailsFireBottlesEquipment.reset();
            }
        },
        isValid() {
            if (this.registration.objectType === 'PRESSURE_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsPressureEquipment.isValid();
            }
            if (this.registration.objectType === 'FIRE_BOTTLES_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsFireBottlesEquipment.isValid();
            }
        },
        scrollToFirstError() {
            if (this.registration.objectType === 'PRESSURE_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsPressureEquipment.scrollToFirstError();
            }
            if (this.registration.objectType === 'FIRE_BOTTLES_EQUIPMENT') {
                return this.$refs.formEquipmentDetailsFireBottlesEquipment.scrollToFirstError();
            }
        }
    }
};
</script>
