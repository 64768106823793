<template>
    <v-list>
        <SummaryBaseData :task="task" :show-copy-btn="showCopyBtn" />
        <template v-if="task.process === PROCESS_TYPES.REGISTRATION">
            <SummaryObjectDetails :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryEquipmentLocation :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryChambers
                v-if="task.objectType === 'PRESSURE_EQUIPMENT'"
                :task="task"
                :show-copy-btn="showCopyBtn"
            />
            <SummaryExtinguishingBottles
                v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
                :task="task"
                :show-copy-btn="showCopyBtn"
            />
            <SummaryAddressOperator :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryAddressBill :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryAddressOwner :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryAttachments :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryActivelyHeated
                v-if="task.objectType === 'PRESSURE_EQUIPMENT'"
                :task="task"
                :show-copy-btn="showCopyBtn"
            />
            <SummaryInspectionProcedure
                v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
                :task="task"
                :show-copy-btn="showCopyBtn"
            />
        </template>
        <template v-if="task.process === PROCESS_TYPES.DEREGISTRATION">
            <SummaryReason :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryNewAddress :task="task" :show-copy-btn="showCopyBtn" />
            <SummaryOperator :task="task" :show-copy-btn="showCopyBtn" />
        </template>
    </v-list>
</template>

<script>
import Helpers from "@/utils/helpers";
import { PROCESS_TYPES } from "@/data/globals.js";
export default {
    name: "TaskSummaryWithVerification",
    props: {
        task: {
            type: Object,
            default: () => {},
            required: true
        },
        showCopyBtn: {
            type: Boolean,
            defaul: false
        }
    },
    components: {
        SummaryBaseData: () =>
            import("@/components/tasks/common/summary/summary-base-data.vue"),
        SummaryObjectDetails: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-object-details.vue"
            ),
        SummaryEquipmentLocation: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-equipment-location.vue"
            ),
        SummaryChambers: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-chambers.vue"
            ),
        SummaryExtinguishingBottles: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-extinguishing-bottles.vue"
            ),
        SummaryAddressOperator: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-operator.vue"
            ),
        SummaryAddressBill: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-bill.vue"
            ),
        SummaryAddressOwner: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-owner.vue"
            ),
        SummaryAttachments: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-attachments.vue"
            ),
        SummaryActivelyHeated: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-actively-heated.vue"
            ),
        SummaryInspectionProcedure: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-inspection-procedure.vue"
            ),
        SummaryReason: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-reason.vue"
            ),
        SummaryOperator: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-operator.vue"
            ),
        SummaryNewAddress: () =>
            import(
                "@/components/tasks/common/summary/deregistration/summary-new-address.vue"
            )
    },
    data: () => ({
        PROCESS_TYPES
    }),
    computed: {
        isActivelyHeated() {
            return Helpers.isActivelyHeated(this.task);
        }
    }
};
</script>
