export class ExtinguishingBottles {
    constructor() {
        this.bottlesCount = null;
        this.volume = null;
        this.lastMinPressure = null;
        this.lastMaxPressure = null;
        this.lastMinConcessionTemperature = null;
        this.lastMaxConcessionTemperature = null;

        this.coverage = '';
        this.materialCode = '';
        this.testPressure = null;
        this.lastTestDate = null;
    }
}
