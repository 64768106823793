import { SuvaPartnerFinder,BackofficeFields } from "./tasks";
import {
  RegistrationFireBottlesEquipment,
  RegistrationPressureEquipment,
  ReviewVerification
} from "./";

export class RegistrationBackofficePressureEquipment extends RegistrationPressureEquipment {
    constructor() {
        super();
        this.reviewVerification = new ReviewVerification();
        this.backoffice = new BackofficeFields();
        this.isBackoffice = true;
        this.suvaPartnerFinder = new SuvaPartnerFinder();
    }
}

export class RegistrationBackofficeFireBottlesEquipment extends RegistrationFireBottlesEquipment {
    constructor() {
        super();
        this.reviewVerification = new ReviewVerification();
        this.backoffice = new BackofficeFields();
        this.isBackoffice = true;
        this.suvaPartnerFinder = new SuvaPartnerFinder();
    }
}
