<i18n src="./dialog-basic-data-i18n.yaml"></i18n>
<template>
    <base-dialog
        :value="dialog"
        @input="$emit('input', $event)"
        :title="$t('title')"
        max-width="600"
        ref="baseDialog"
        icon="mdi-pencil"
    >
        <template v-slot:content>
            <FormBasicData ref="formBasicData" v-model="formParent" />
        </template>
        <template v-slot:actions>
            <v-spacer />
            <base-btn @click="save">{{ $t("btn-save") }}</base-btn>
        </template>
    </base-dialog>
</template>
<script>
import FormBasicData from "@/components/tasks/edit/common/forms/form-basic-data.vue";
import { BaseRegistrationPressureEquipment } from "@/models/tasks";
export default {
    name: "DialogBasicData",
    components: {
        FormBasicData
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        dialog: false,
        formParent: new BaseRegistrationPressureEquipment()
    }),
    methods: {
        closeDialog() {
            this.dialog = false;
            this.$emit("input", this.dialog);
        },
        onFieldChange(field, value) {
            this.$emit("field-change", { field, value });
        },
        fillForm() {
            if (this.registration) {
                this.formParent = {
                    role: this.registration.role,
                    objectType: this.registration.objectType,
                    workingTitle: this.registration.workingTitle,
                    manufacturer: this.registration.manufacturer,
                    factoryNumber: this.registration.factoryNumber,
                    constructionYear: this.registration.constructionYear,
                    inOperationYear: this.registration.inOperationYear,
                    intendedUse: this.registration.intendedUse
                };
            } else {
                this.formParent = new BaseRegistrationPressureEquipment();
                delete this.formParent.docId;
                delete this.formParent.step;
            }
        },
        scrollToFirstErrorWithScrollOptions() {
            let baseDialog = this.$refs.baseDialog;
            let baseDialogCardTextRef = baseDialog.$refs.baseDialogCardText;
            let scrollOptions = {
                container: baseDialogCardTextRef
            };
            this.$refs.formBasicData.scrollToFirstError(scrollOptions);
        },
        save() {
            if (this.$refs.formBasicData) {
                this.$refs.formBasicData.validate();
                if (this.$refs.formBasicData.isValid()) {
                    this.$emit("complete", this.formParent);
                } else {
                    this.scrollToFirstErrorWithScrollOptions();
                }
            }
        }
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.fillForm();
                this.dialog = newValue;
            },
            immediate: true
        }
    }
};
</script>
