<i18n src="./form-equipment-details-fire-bottles-equipment-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>

        <StepContentTitle class="pa-0 ma-0" :title="$t('questions-title')" />
        <v-container fluid>
            <v-row>
                <base-radio-group
                    v-model="reason"
                    :required="isRequiredField($v.reason)"
                    :label="$t('reason-label') + ':'"
                    :error-messages="reasonErrors"
                >
                    <v-radio :label="$t('reason-new-installation')" value="NEW_INSTALLATION"></v-radio>
                    <v-radio :label="$t('reason-bottle-change')" value="BOTTLE_CHANGE"></v-radio>
                </base-radio-group>
            </v-row>
            <v-row>
                <base-radio-group
                    v-model="tpedStandard"
                    :required="isRequiredField($v.tpedStandard)"
                    :label="$t('tped-standard-label')"
                    :error-messages="tpedStandardErrors"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>
        </v-container>
    </v-form>
</template>
<script>
import {
    required
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import StepContentTitle from '../components/step-content-title.vue';
import validateMixin from '@/mixins/validate';
export default {
    name: 'FormEquipmentDetails',
    mixins: [validateMixin],
    validations: {
        reason: { required },
        tpedStandard: { required }
    },
    components: {
        StepContentTitle
    },
    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        registrationData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
    }),
    methods: {
        ...mapActions('attributes', ['getStoredAttributesByKey']),
        onFieldChange(field, value) {
            this.$emit('field-change', { field, value });
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        reset() {
            this.$v.$reset();
            this.onFieldChange('reason', null);
            this.onFieldChange('tpedStandard', null);
        },
        setFakeData() {
            this.onFieldChange('reason', 'NEW_INSTALLATION');
            this.onFieldChange('tpedStandard', true);
        }
    },
    computed: {
        reason: {
            get() {
                return this.registrationData.reason;
            },
            set(value) {
                this.onFieldChange('reason', value);
            }
        },
        tpedStandard: {
            get() {
                return this.registrationData.tpedStandard;
            },
            set(value) {
                this.onFieldChange('tpedStandard', value);
            }
        },
        ...mapGetters({
            developerMode: 'settings/developerMode'
        }),
        reasonErrors() {
            const errors = [];
            if (!this.$v.reason.$dirty) {
                return errors;
            }
            if (!this.$v.reason.required) {
                errors.push(this.$t('field-required'));
            }
            return errors;
        },
        tpedStandardErrors() {
            const errors = [];
            if (!this.$v.tpedStandard.$dirty) {
                return errors;
            }
            if (!this.$v.tpedStandard.required) {
                errors.push(this.$t('field-required'));
            }
            return errors;
        },
    }
};
</script>
