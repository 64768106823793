<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./stepper-i18n.yaml"></i18n>
<template>
    <v-stepper
        v-model="step"
        vertical
        v-scroll="onScroll"
        class="elevation-0"
        non-linear
        v-if="registration.docId"
        @change="goToStep"
    >
        <app-dev-container color="warning" expanded>
            <template v-slot:content>
                <tree-view
                    :data="Object.assign({}, registration)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
                <v-select
                    :items="devStepsItemsList"
                    label="Jump Step"
                    v-model="step"
                    solo
                ></v-select>
            </template>
        </app-dev-container>
        <RegistrationBasicData
            ref="step0"
            :registration="registration"
            :showLock="!readonlyModus"
            :disabledLock="reviewVerification.step0.approved"
            :disabled="
                registrationState >= TaskStatus.TASK_REGISTRANT_COMPLETED
            "
            :readonly="readonlyModus"
            :expandCard="expandBasicDataCard"
            @field-change="onBasicDataFieldChange"
            @icon-key-click="onRegistrationBasicDataIconKeyClick"
            @click:btn-lock="readonlySteps.step00 = $event"
            @click:btn-expand="$emit('change:expand-basic-data-card')"
        >
            <template
                v-slot:addons
                v-if="
                    registrationState > TaskStatus.TASK_REGISTRANT_COMPLETED &&
                    registrationState < TaskStatus.TASK_CONFIRMED
                "
            >
                <v-container fluid pa-0 ma-0 class="px-5">
                    <v-row v-if="registration.registrantNote">
                        <v-col>
                            <base-alert type="info" dense>
                                <div class="text-h6">
                                    {{ $t("registrant-not-title") }}:
                                </div>
                                <div
                                    v-html="
                                        registration.registrantNote.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            '<br />'
                                        )
                                    "
                                ></div>
                            </base-alert>
                        </v-col>
                    </v-row>
                    <RegistrationVerificationCard
                        v-if="showRegistrationVerificationCard"
                        :reviewVerification="reviewVerification.step0"
                        :step="0"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onUpdateReviewVerificationState('step0', $event)
                        "
                        :valid="reviewVerification.step0.approved"
                    />
                </v-container>
            </template>
        </RegistrationBasicData>
        <v-row v-if="dialogDuplicatesCheck && !readonlyModus">
            <v-col>
                <DialogDuplicatesCheck
                    :value="dialogDuplicatesCheck"
                    :reviewVerification="reviewVerification"
                    :readonlyModus="readonlyModus"
                    :registrationData="registration"
                    :backoffice="backoffice"
                    @input="dialogDuplicatesCheck = $event"
                    @change:review-verification-step="
                        (item) =>
                            onUpdateReviewVerificationState(
                                item.step,
                                item.value
                            )
                    "
                    @save="onSaveDuplicatesCheck"
                />
            </v-col>
        </v-row>

        <BaseStepperStep
            step="1"
            ref="step1"
            @click:btn-lock="readonlySteps.step01 = !readonlySteps.step01"
            :title="$t('step-01-title')"
            :btnLockShow="!readonlyModus && step == 1"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step01"
            :reviewVerification="reviewVerification.step1"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="1"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepObjectPropertiesContent
                ref="step01Content"
                :readonlyModus="readonlySteps.step01"
                :registration="registration"
                @field-change="onFieldChange($event, true)"
                @click:container="onClickStepContentInReadonlyMode(1)"
            >
            </StepObjectPropertiesContent>
            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step1"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                1,
                                $event
                            )
                        "
                        :valid="reviewVerification.step1.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        @click="validateStep01"
                        btnIcon="mdi-arrow-down"
                        type="primary"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 01 #############################  -->
        <!-- ####################### Objectdetails ###########################  -->

        <BaseStepperStep
            step="2"
            ref="step2"
            @click:btn-lock="readonlySteps.step02 = !readonlySteps.step02"
            :title="$t('step-02-title')"
            :btnLockShow="!readonlyModus && step == 2"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step02"
            :reviewVerification="reviewVerification.step2"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="2"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepPressureDeviceLocationContent
                ref="step02Content"
                :objectType="registration.objectType"
                :readonlyModus="readonlySteps.step02"
                :locationAddress="locationAddress"
                :locationContactPerson="locationContactPerson"
                :customerSpecificDesignation="customerSpecificDesignation"
                @reset:equipment-location="
                    onResetEquipmentLocation($event, true)
                "
                @reset:address="onResetEquipmentLocationAddress(true)"
                @reset:contact-person="
                    onResetEquipmentLocationContactPerson(true)
                "
                @reset:customer-specific-designation="
                    onResetCustomerSpecificDesignation(true)
                "
                @change:equipment-location-field="
                    onMergeRegistrationField($event, true)
                "
                @change:customer-specific-designation="
                    onMergeRegistrationField(
                        { field: 'customerSpecificDesignation', value: $event },
                        true
                    )
                "
                @change:contact-person="
                    onUpdateEquipmentLocationContactPerson($event, true)
                "
                @change:address="onUpdateEquipmentLocationAddress($event, true)"
                @click:container="onClickStepContentInReadonlyMode(2)"
            >
            </StepPressureDeviceLocationContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step2"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                2,
                                $event
                            )
                        "
                        :valid="reviewVerification.step2.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(1)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep02"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 03 #############################  -->
        <!-- ########################## Chambers #############################  -->
        <BaseStepperStep
            step="3"
            ref="step3"
            @click:btn-lock="readonlySteps.step03 = !readonlySteps.step03"
            :title="$t('step-03-title')"
            :btnLockShow="!readonlyModus && step == 3"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step03"
            :reviewVerification="reviewVerification.step3"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="3"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepExtinguishingBottlesContent
                ref="step03Content"
                :readonlyModus="readonlySteps.step03"
                :extinguishingBottles="extinguishingBottles"
                @reset="onResetExtinguishingBottles(true)"
                @change="
                    onMergeRegistrationField(
                        { field: 'extinguishingBottles', value: $event },
                        true
                    )
                "
            >
            </StepExtinguishingBottlesContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step3"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                3,
                                $event
                            )
                        "
                        :valid="reviewVerification.step3.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(2)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep03"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 04 #############################  -->
        <!-- ########################## Operator #############################  -->

        <BaseStepperStep
            step="4"
            ref="step4"
            @click:btn-lock="readonlySteps.step04 = !readonlySteps.step04"
            :title="$t('step-04-title')"
            :btnLockShow="!readonlyModus && step == 4"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step04"
            :reviewVerification="reviewVerification.step4"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="4"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressOperatorContent
                ref="step04Content"
                :readonlyModus="readonlySteps.step04"
                :value="operatorAddress"
                :task="registration"
                @clear="onClearAddress"
                @change:address-field="onChangeAddressField($event, true)"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'operatorAddress', value: address },
                            true
                        )
                "
                @click:container="onClickStepContentInReadonlyMode(4)"
            >
            </StepAddressOperatorContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step4"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                4,
                                $event
                            )
                        "
                        :valid="reviewVerification.step4.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(3)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep04"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 05 #############################  -->
        <!-- ##################### Invoice Recipient #########################  -->
        <BaseStepperStep
            step="5"
            ref="step5"
            @click:btn-lock="readonlySteps.step05 = !readonlySteps.step05"
            :title="$t('step-05-title')"
            :btnLockShow="!readonlyModus && step == 5"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step05"
            :reviewVerification="reviewVerification.step5"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="5"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressBillContent
                ref="step05Content"
                :readonlyModus="readonlySteps.step05"
                :value="billingAddress"
                :task="registration"
                :billingAdditionalInfos="billingAdditionalInfos"
                @change:address-field="onChangeAddressField($event, true)"
                @clear="onClearAddress({ field: 'billingAddress' })"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'billingAddress', value: address },
                            true
                        )
                "
                @click:container="onClickStepContentInReadonlyMode(5)"
                @change:billing-additional-infos-field="onChangeBillingAdditionalInfosField($event, true)"
            >
            </StepAddressBillContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step5"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                5,
                                $event
                            )
                        "
                        :valid="reviewVerification.step5.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(4)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep05"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 06 #############################  -->
        <!-- ########################### Owner ###############################  -->

        <BaseStepperStep
            step="6"
            ref="step6"
            @click:btn-lock="readonlySteps.step06 = !readonlySteps.step06"
            :title="$t('step-06-title')"
            :btnLockShow="!readonlyModus && step == 6"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step06"
            :reviewVerification="reviewVerification.step6"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="6"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepAddressOwnerContent
                ref="step06Content"
                :readonlyModus="readonlySteps.step06"
                :value="ownerAddress"
                :task="registration"
                @change:address-field="onChangeAddressField($event, true)"
                @clear="onClearAddress({ field: 'ownerAddress' })"
                @clear:address="onClearAddress($event, true)"
                @change:address="
                    (address) =>
                        onMergeRegistrationAddressValuesByFieldName(
                            { field: 'ownerAddress', value: address },
                            true
                        )
                "
                @click:container="onClickStepContentInReadonlyMode(6)"
            >
            </StepAddressOwnerContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step6"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                6,
                                $event
                            )
                        "
                        :valid="reviewVerification.step6.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(5)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep06"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 07 #############################  -->
        <!-- ###################### Fotos/Documents ##########################  -->

        <BaseStepperStep
            step="7"
            ref="step7"
            @click:btn-lock="readonlySteps.step07 = !readonlySteps.step07"
            :title="$t('step-07-title')"
            :btnLockShow="!readonlyModus && step == 7"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step07"
            :reviewVerification="reviewVerification.step7"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="7"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
            id="step07Content"
        >
            <StepUploadContent
                ref="step07Content"
                :readonlyModus="readonlySteps.step07"
                :taskDocId="registration.docId"
                :attachments="registration.attachments"
                :attachmentsStatesRange="[
                    TaskStatus.TASK_REGISTRANT_IN_PROCESS,
                    TaskStatus.TASK_CONFIRMED
                ]"
                @update="onFieldChange({ field: 'attachments', value: $event })"
                @click:container="onClickStepContentInReadonlyMode(7)"
            >
            </StepUploadContent>
            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step7"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                7,
                                $event
                            )
                        "
                        requireAttachments
                        :valid="reviewVerification.step7.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(6)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep07"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 08 #############################  -->
        <!-- ################## Gewünschtes Prüfverfahren ####################  -->

        <BaseStepperStep
            step="8"
            ref="step8"
            @click:btn-lock="readonlySteps.step08 = !readonlySteps.step08"
            :title="$t('step-08-title')"
            :btnLockShow="!readonlyModus && step == 8"
            :readonlyModus="readonlyModus"
            :readonlyModusStep="readonlySteps.step08"
            :reviewVerification="reviewVerification.step8"
        >
        </BaseStepperStep>

        <v-stepper-content
            step="8"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepInspectionProcedureContent
                ref="step08Content"
                :readonlyModus="readonlySteps.step08"
                :registration="registration"
                :attachmentsStatesRange="[
                    TaskStatus.TASK_REGISTRANT_CREATED,
                    TaskStatus.TASK_CONFIRMED
                ]"
                @field-change="onFieldChange($event, true)"
                @attachments:change="
                    onFieldChange({ field: 'attachments', value: $event })
                "
                @click:container="onClickStepContentInReadonlyMode(8)"
            >
            </StepInspectionProcedureContent>

            <v-row class="px-3" v-if="showRegistrationVerificationCard">
                <v-col>
                    <RegistrationVerificationCard
                        :reviewVerification="reviewVerification.step8"
                        :step="step"
                        :registrationData="registration"
                        @delete="onDeleteRegistrationDialog"
                        @create="onCreateRegistrationDialog"
                        @update-review-verification-state="
                            onChangeReviewVerificationStateByStepCount(
                                8,
                                $event
                            )
                        "
                        :valid="reviewVerification.step8.approved"
                    />
                </v-col>
            </v-row>
            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(7)"
                    >
                        {{ $t("btn-back") }}
                    </base-btn>
                    <base-btn
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-arrow-down"
                        @click="validateStep08"
                    >
                        {{ $t("btn-next") }}
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <!-- ########################### STEP 09 #############################  -->
        <!-- ########################### Summary #############################  -->

        <BaseStepperStep
            :step="8"
            :title="$t('step-09-title')"
            :readonlyModus="true"
            :readonlyModusStep="true"
            complete
            ref="step9"
        >
        </BaseStepperStep>

        <v-stepper-content
            :step="9"
            :class="$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null"
        >
            <StepSummaryContent
                ref="step09Content"
                :registrationData="registration"
                @click:container="onClickStepContentInReadonlyMode(9)"
            >
            </StepSummaryContent>
            <app-dev-container>
                <template v-slot:content>
                    reviewVerificationValid
                    <br />
                    {{ reviewVerificationValid }}
                    <br />
                    hasOpenDialogs
                    <br />
                    {{ hasOpenDialogs }}
                    <br />
                    needToAssignToRegistrant
                    <br />
                    {{ needToAssignToRegistrant }}
                </template>
            </app-dev-container>

            <v-row class="px-3 pb-3">
                <v-col class="text-right">
                    <base-btn
                        type="secondary"
                        btnIcon="mdi-arrow-up"
                        @click="goToStep(8)"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-back") }}
                        </span>
                    </base-btn>
                    <base-btn
                        v-if="
                            registration &&
                            (readonlyModus ||
                                hasOpenDialogs ||
                                needToAssignToRegistrant)
                        "
                        class="ml-2"
                        type="close"
                        @click="close"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-close") }}
                        </span>
                    </base-btn>
                    <base-btn
                        v-if="
                            registration &&
                            !readonlyModus &&
                            reviewVerification &&
                            reviewVerificationValid &&
                            !hasOpenDialogs &&
                            !needToAssignToRegistrant
                        "
                        class="ml-2"
                        type="save"
                        @click="validateStep09"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-complete") }}
                        </span>
                    </base-btn>
                    <base-btn
                        v-if="
                            registrationState >
                                TaskStatus.TASK_REGISTRANT_COMPLETED &&
                            (hasOpenDialogs || needToAssignToRegistrant)
                        "
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-account-arrow-right"
                        @click="onConfirmAndAssingToRegistrant"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-confirm-and-assing-to-registration") }}
                        </span>
                    </base-btn>
                    <base-btn
                        v-if="registrationState === TaskStatus.TASK_CONFIRMED"
                        class="ml-2"
                        type="primary"
                        btnIcon="mdi-account-arrow-right"
                        @click="dialogSapExport = true"
                    >
                        <span v-if="$vuetify.breakpoint.smAndUp">
                            {{ $t("btn-export-to-sap") }}
                        </span>
                    </base-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <DialogCompleteRegistration
            v-if="dialogCompleteRegistration"
            max-width="450"
            icon="mdi-alert"
            :value="dialogCompleteRegistration"
            :title="$t('complete-registration-title')"
            :registrationData="registration"
            :backoffice="backoffice"
            @input="dialogCompleteRegistration = $event"
            @complete="completeRegistration"
        />

        <DialogSapExport
            v-model="dialogSapExport"
            :task="registration"
            @save:success="clearStoreAndGoToTaskView"
        />
    </v-stepper>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import {
    TaskStatus,
    TaskDialogStatus,
    DUPLICATE_DECISION_TYPES
} from "@/data/globals.js";
import { isEmpty } from "@/utils";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
import StepperFireBottlesEquipmentMixin from "@/components/tasks/edit/common/mixins/stepper-fire-bottles-equipment.js";
export default {
    components: {
        RegistrationVerificationCard: () =>
            import(
                "@/components/backoffice/components/tasks-verification-card.vue"
            ),

        DialogDuplicatesCheck: () =>
            import(
                "@/components/tasks/edit/backoffice/components/dialog-duplicates-check.vue"
            ),
        DialogCompleteRegistration: () =>
            import(
                "@/components/tasks/edit/backoffice/components/dialog-complete-registration.vue"
            ),
        DialogSapExport: () =>
            import("@/components/backoffice/components/dialog-sap-export.vue"),
        BaseStepperStep: () =>
            import(
                "@/components/tasks/edit/backoffice/components/base-stepper-step.vue"
            )
    },

    mixins: [StepperFireBottlesEquipmentMixin],

    data() {
        return {
            TaskDialogStatus: TaskDialogStatus,
            TaskStatus: TaskStatus,
            selectedCompany: {},
            isLoadingMsg: this.$t("is-loading-msg"),
            dialogCompleteRegistration: false,
            readonlySteps: {
                step00: true,
                step01: true,
                step02: true,
                step03: true,
                step04: true,
                step05: true,
                step06: true,
                step07: true,
                step08: true
            },
            registrationDiffs: {},
            dialogDuplicatesCheck: false,
            dialogSapExport: false
        };
    },
    computed: {
        ...mapGetters({
            locationAddress: "backofficeRegistration/locationAddress",
            locationContactPerson:
                "backofficeRegistration/locationContactPerson",
            customerSpecificDesignation:
                "backofficeRegistration/customerSpecificDesignation",
            extinguishingBottles:
                "backofficeRegistration/extinguishingBottles",
            reviewVerification: "backofficeRegistration/reviewVerification",
            backoffice: "backofficeRegistration/backoffice",
            registration: "backofficeRegistration/registration",
            referenceId: "backofficeRegistration/referenceId",
            docId: "backofficeRegistration/docId",
            registrationState: "backofficeRegistration/state",
            readonlyModus: "backofficeRegistration/readonlyModus",
            registrationToSave: "backofficeRegistration/registrationToSave",
            billingAdditionalInfos:
                "backofficeRegistration/billingAdditionalInfos"
        }),
        ...mapGetters({
            registrationRegistrant: "registration/registration"
        }),
        decryptedMail() {
            if (this.mail) {
                return atob(this.mail);
            } else {
                return null;
            }
        },
        reviewVerificationValid() {
            let valid = true;
            _.forOwn(this.reviewVerification, (value, step) => {
                if (!this.reviewVerification[step].approved) {
                    valid = false;
                }
            });
            return valid;
        },
        hasOpenDialogs() {
            let openDialogs = 0;
            _.forOwn(this.reviewVerification, (value, step) => {
                if ("dialog" in this.reviewVerification[step]) {
                    if ("state" in this.reviewVerification[step].dialog) {
                        let state = this.reviewVerification[step].dialog.state;
                        if (
                            [
                                TaskDialogStatus.TASK_DIALOG_CREATED,
                                TaskDialogStatus.TASK_DIALOG_REGISTRANT_IN_PROCESS
                            ].includes(state)
                        ) {
                            openDialogs++;
                        }
                    }
                }
            });
            if (openDialogs > 0) {
                return true;
            }
            return false;
        },
        needToAssignToRegistrant() {
            let openDialogs = 0;
            _.forOwn(this.reviewVerification, (value, step) => {
                if ("dialog" in this.reviewVerification[step]) {
                    if ("state" in this.reviewVerification[step].dialog) {
                        let state = this.reviewVerification[step].dialog.state;
                        if (
                            [
                                TaskDialogStatus.TASK_DIALOG_CREATED,
                                TaskDialogStatus.TASK_DIALOG_BACKOFFICE_IN_PROCESS
                            ].includes(state)
                        ) {
                            openDialogs++;
                        }
                    }
                }
            });
            if (openDialogs > 0) {
                return true;
            }
            return false;
        },
        showRegistrationVerificationCard() {
            return !this.readonlyModus;
        }
    },

    methods: {
        ...mapMutations("backofficeRegistration", [
            MUTATION_TYPES.CLEAR_REGISTRATION,
            MUTATION_TYPES.UPDATE_REGISTRATION_FIELD,
            MUTATION_TYPES.MERGE_REGISTRATION_FIELDS,
            MUTATION_TYPES.CLEAR_REGISTRATION_ADDRESS,
            MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS_FIELD,
            MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS,
            MUTATION_TYPES.UPDATE_CHAMBERS,
            MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON,
            MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_ADDRESS,
            MUTATION_TYPES.REMOVE_CHAMBER_BY_INDEX,
            MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_ADDRESS,
            MUTATION_TYPES.RESET_CUSTOMER_SPECIFIC_DESIGNATION,
            MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON,
            MUTATION_TYPES.ENABLE_READONLY_MODUS,
            MUTATION_TYPES.CLEAR_REGISTRATION_REVIEW,
            MUTATION_TYPES.DELETE_REGISTRATION_REVIEW_DIALOG,
            MUTATION_TYPES.UPDATE_REGISTRATION_REVIEW_STATE,
            MUTATION_TYPES.UPDATE_REGISTRATION_REVIEW_DIALOG
        ]),
        ...mapActions("backofficeRegistration", [
            ACTION_TYPES.UPDATE_SCROLL_POSITION,
            ACTION_TYPES.COMPLETE,
            ACTION_TYPES.DEBOUNCE_AUTO_SAVE_REGISTRATION,
            ACTION_TYPES.AUTO_SAVE_REGISTRATION
        ]),
        ...mapActions("backoffice", [ACTION_TYPES.BACKOFICCE_TASK_COMPLETE]),
        onClickStepContentInReadonlyMode(stepCount) {
            if (this.registrationState >= TaskStatus.TASK_CONFIRMED) return;
            const stepId = `step0${stepCount}`;
            if (this.readonlySteps[stepId]) {
                this.$toast.info(
                    this.$t("step-is-readonly-msg", {
                        step: stepCount
                    })
                );
            }
        },
        isObject(value) {
            return _.isObject(value);
        },
        isArray(value) {
            return _.isArray(value);
        },
        clearReviewVerification() {
            this[MUTATION_TYPES.CLEAR_REGISTRATION_REVIEW]();
        },
        onDeleteRegistrationDialog(registrationDialog) {
            this.loader().show();
            registrationDialog.taskDocId = this.registration.docId;
            this.$store
                .dispatch(
                    "registrationDialogs/deleteDialogQuestion",
                    registrationDialog
                )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$toast.success(
                            this.$t("msg-dialog-delete-success")
                        );
                        let step = "step" + registrationDialog.step;
                        if (!isEmpty(step)) {
                            this[
                                MUTATION_TYPES.DELETE_REGISTRATION_REVIEW_DIALOG
                            ]({ step });
                        }
                        this.autoSave();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        onCreateRegistrationDialog(registrationDialog) {
            registrationDialog.taskDocId = this.registration.docId;
            const stepValue = registrationDialog.step;
            let step = null;
            if (!isEmpty(stepValue) || stepValue === 0) {
                step = "step" + registrationDialog.step;
            }
            if (!step) return;
            this.loader().show();
            this.$store
                .dispatch(
                    "registrationDialogs/createDialogQuestion",
                    registrationDialog
                )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$toast.success(
                            this.$t("msg-dialog-create-success")
                        );
                        const resData = response.data.data;
                        let dialogData = resData;
                        if (!isEmpty(stepValue) || stepValue === 0) {
                            this.onUpdateReviewVerificationDialog(
                                step,
                                dialogData
                            );
                        }
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus;
                    let resData;
                    if (error.response) {
                        resStatus = error.response.status;
                        resData = error.response.data;
                    }
                    if (resData && "code" in resData) {
                        let resErrorCode = resData.code;
                        this.$toast.error(
                            resStatus +
                                "(" +
                                resErrorCode +
                                "): " +
                                this.$t("msg-error-occurred")
                        );
                    } else {
                        this.$toast.error(
                            resStatus + ": " + this.$t("msg-error-occurred")
                        );
                    }
                    this.loader().hide();
                });
        },
        isStepValid(step) {
            step = "step" + step;
            if (this.readonlyModus) return true;
            if (!this.reviewVerification) return false;
            if (this.reviewVerification[step].approved) {
                return true;
            } else if (
                !this.reviewVerification[step].approved &&
                (!("dialog" in this.reviewVerification[step]) ||
                    !("messages" in this.reviewVerification[step].dialog))
            ) {
                this.$toast.error(
                    this.$t(
                        "error-verification-review-no-dialog-or-message-found"
                    )
                );
                return false;
            } else if (
                !this.reviewVerification[step].approved &&
                this.reviewVerification[step].dialog.messages.length === 0
            ) {
                this.$toast.error(
                    this.$t("error-verification-review-dialog-has-no-messages")
                );
                return false;
            } else {
                return true;
            }
        },
        validateStep01() {
            if (this.readonlyModus) {
                this.goToStep(2);
                return;
            }
            this.$refs.step01Content.validate();
            if (this.$refs.step01Content.isValid()) {
                this.readonlySteps.step01 = true;
                if (this.isStepValid(1)) {
                    this.goToStep(2);
                }
            } else {
                this.readonlySteps.step01 = false;
                this.$nextTick(() => {
                    this.$refs.step01Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep02() {
            if (this.readonlyModus) {
                this.goToStep(3);
                return;
            }
            this.$refs.step02Content.validate();
            if (this.$refs.step02Content.isValid()) {
                this.readonlySteps.step02 = true;
                if (this.isStepValid(2)) {
                    this.goToStep(3);
                }
            } else {
                this.readonlySteps.step02 = false;
                this.$nextTick(() => {
                    this.$refs.step02Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep03() {
            if (this.readonlyModus) {
                this.goToStep(4);
                return;
            }
            this.$refs.step03Content.validate();
            if (this.$refs.step03Content.isValid()) {
                this.readonlySteps.step03 = true;
                if (this.isStepValid(3)) {
                    this.goToStep(4);
                }
            } else {
                this.readonlySteps.step03 = false;
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
                return;
            }
        },
        validateStep04() {
            if (this.readonlyModus) {
                this.goToStep(5);
                return;
            }
            this.$refs.step04Content.validate();
            if (this.$refs.step04Content.isValid()) {
                this.readonlySteps.step04 = true;
                if (this.isStepValid(4)) {
                    this.goToStep(5);
                }
            } else {
                this.readonlySteps.step04 = false;
                this.$nextTick(() => {
                    this.$refs.step04Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep05() {
            if (this.readonlyModus) {
                this.goToStep(6);
                return;
            }
            this.$refs.step05Content.validate();
            if (this.$refs.step05Content.isValid()) {
                this.readonlySteps.step05 = true;
                if (this.isStepValid(5)) {
                    this.goToStep(6);
                }
            } else {
                this.readonlySteps.step05 = false;
                this.$nextTick(() => {
                    this.$refs.step05Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep06() {
            if (this.readonlyModus) {
                this.goToStep(7);
                return;
            }
            this.$refs.step06Content.validate();
            if (this.$refs.step06Content.isValid()) {
                this.readonlySteps.step06 = true;
                if (this.isStepValid(6)) {
                    this.goToStep(7);
                }
            } else {
                this.readonlySteps.step06 = false;
                this.$nextTick(() => {
                    this.$refs.step06Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep07() {
            if (this.readonlyModus) {
                this.goToStep(8);
                return;
            }
            if (this.isStepValid(7)) {
                this.readonlySteps.step07 = true;
                this.goToStep(8);
            } else {
                this.showToastMsg(
                    "error",
                    this.$t("step-has-errors", { step: this.step })
                );
            }
        },
        validateStep08() {
            if (this.readonlyModus) {
                this.goToStep(9);
                return;
            }
            if (!this.$refs.step08Content) return;
            this.$refs.step08Content.validate();
            if (this.$refs.step08Content.isValid()) {
                this.readonlySteps.step06 = true;
                if (this.isStepValid(8)) {
                    this.goToStep(9);
                }
            } else {
                this.readonlySteps.step06 = false;
                this.$nextTick(() => {
                    this.$refs.step08Content.scrollToFirstError();
                    this.showToastMsg(
                        "error",
                        this.$t("step-has-errors", { step: this.step })
                    );
                });
                return;
            }
        },
        validateStep09() {
            if (this.readonlyModus) {
                return true;
            } else {
                this.validateAllSteps();
                if (this.areAllStepsValid()) {
                    this.dialogCompleteRegistration = true;
                }
            }
        },
        clearStoreAndGoToTaskView() {
            this.$router.push({
                name: "backoffice-task-view",
                params: { referenceId: this.registration.referenceId }
            });
            this.clearRegistrationsStore();
        },
        clearStoreAndGoToBoDashboard() {
            this.clearRegistrationsStore();
            this.$router.push({ name: "backoffice-home" });
        },
        completeRegistration() {
            this.loader().show();
            this.isLoadingMsg = this.$t(
                "is-loading-msg-complete-registration-with-email"
            );
            this[ACTION_TYPES.BACKOFICCE_TASK_COMPLETE](this.registrationToSave)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$emit("registration-complete");
                        this.showToastMsg(
                            "success",
                            this.$t("save-success-msg")
                        );
                        this[MUTATION_TYPES.ENABLE_READONLY_MODUS]();
                        if (
                            this.registrationToSave.duplicateDecision ===
                            DUPLICATE_DECISION_TYPES.DUPLICATE
                        ) {
                            this.onFieldChange({
                                field: "state",
                                value: TaskStatus.TASK_COMPLETED
                            });
                            this.clearStoreAndGoToBoDashboard();
                        } else {
                            this.onFieldChange({
                                field: "state",
                                value: TaskStatus.TASK_CONFIRMED
                            });
                            this.dialogSapExport = true;
                        }
                        this.dialogCompleteRegistration = false;
                    } else {
                        this.showToastMsg("error", this.$t("save-error-msg"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    const resData = error.response.data;
                    const errorCode = resData.code;
                    const errorData = resData.data;
                    if (this.$te(`error-code-${errorCode}`)) {
                        //CHECK IF TRANSLATE EXISTS
                        if (errorCode === 5) {
                            if (errorData.addressType) {
                                this.$toast.error(
                                    this.$t("error-code-5", {
                                        addressType: this.$t(
                                            `address-type-${errorData.addressType}`
                                        )
                                    })
                                );
                            }
                        } else if (errorCode === 7) {
                            //field is missing
                            const missingField = errorData.missingField;
                            if (missingField) {
                                if (
                                    this.$te(
                                        `registration-field-${missingField}`
                                    )
                                ) {
                                    this.$toast.error(
                                        this.$t("error-code-7", {
                                            missingField: this.$t(
                                                `registration-field-${missingField}`
                                            )
                                        })
                                    );
                                } else {
                                    this.$toast.error(
                                        this.$t(`error-code-${errorCode}`, 3000)
                                    );
                                }
                            }
                        } else if (errorCode === 8) {
                            //chamber field is missing
                            const missingField = errorData.missingField;
                            const chamberId = errorData.chamberId;
                            if (missingField) {
                                if (
                                    this.$te(
                                        `registration-field-chambers-${missingField}`
                                    )
                                ) {
                                    this.$toast.error(
                                        this.$t("error-code-8", {
                                            missingField: this.$t(
                                                `registration-field-chambers-${missingField}`
                                            ),
                                            chamberId: chamberId
                                        })
                                    );
                                } else {
                                    this.$toast.error(
                                        this.$t(`error-code-${errorCode}`, 3000)
                                    );
                                }
                            }
                        } else {
                            this.$toast.error(
                                this.$t(`error-code-${errorCode}`, 3000)
                            );
                        }
                    } else {
                        //ELSE WILL DISPLAY AN GENERAL ERROR MESSAGE
                        this.$toast.error(this.$t("error-code-0"));
                    }
                    this.loader().hide();
                });
        },
        clearRegistrationsStore() {
            this[MUTATION_TYPES.CLEAR_REGISTRATION]();
        },
        showToastMsg(type, msg) {
            return this.$toast[type](msg);
        },
        setTitle03Data(timestamp, status) {
            let title03 = {};
            if (timestamp) {
                title03.timestamp = timestamp;
            }
            if (status) {
                title03.status = status;
            }
            this.$store.commit("settings/setTitle03", title03);
        },
        close() {
            this.clearRegistrationsStore();
            this.$router.go(-1);
        },
        onCheckIfBORegistrationHasDiffs() {
            let data = this.registration;
            data.email = this.decryptedMail;
            data.lang = this.$i18n.locale;
            this.$axiosTasks
                .post("/checkIfBORegistrationHasDiffs", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.$toast.success("Task has diffs");
                        this.registrationDiffs = response.data;
                    } else {
                        this.showToastMsg("error", this.$t("save-error-msg"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let errorCode = error.response.data.code;
                    if (this.$te(`error-code-${errorCode}`)) {
                        //CHECK IF TRANSLATE EXISTS
                        this.$toast.error(
                            this.$t(`error-code-${errorCode}`, 3000)
                        );
                    } else {
                        //ELSE WILL DISPLAY AN GENERAL ERROR MESSAGE
                        this.$toast.error(this.$t("error-code-0"));
                    }
                    this.loader().hide();
                });
        },
        onChangeReviewVerificationStateByStepCount(stepCount, value) {
            const step = `step${stepCount}`;
            const stepId = `step0${stepCount}`;
            if (this.readonlySteps[stepId] == false) {
                this.readonlySteps[stepId] = true;
            }
            this.onUpdateReviewVerificationState(step, value);
        },
        onUpdateReviewVerificationState(step, value) {
            this[MUTATION_TYPES.UPDATE_REGISTRATION_REVIEW_STATE]({
                step,
                value
            });
            this.autoSave();
        },
        onUpdateReviewVerificationDialog(step, dialog) {
            this[ACTION_TYPES.UPDATE_REGISTRATION_REVIEW_DIALOG]({
                step,
                dialog
            });
            this.autoSave();
        },
        onConfirmAndAssingToRegistrant() {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-assign-to-registrant-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-assign-to-registrant-msg")
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.$axiosBackoffice
                        .put(
                            `/assignTaskToRegistrant/${this.registration.docId}`
                        )
                        .then((response) => {
                            let status = response.status;
                            if (status === 200) {
                                this.clearRegistrationsStore();
                                this.$router.push("/backoffice/home");
                                this.$toast.success(
                                    this.$t("registration-success-assigned")
                                );
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            let resStatus;
                            let resData;
                            if (error.response) {
                                resStatus = error.response.status;
                                resData = error.response.data;
                            }
                            if (resData && "code" in resData) {
                                let resErrorCode = resData.code;
                                this.$toast.error(
                                    resStatus +
                                        "(" +
                                        resErrorCode +
                                        "): " +
                                        this.$t("msg-error-occurred")
                                );
                            } else {
                                this.$toast.error(
                                    resStatus +
                                        ": " +
                                        this.$t("msg-error-occurred")
                                );
                            }
                            this.loader().hide();
                        });
                }
            });
        },
        onBasicDataFieldChange({ field, value }) {
            if (!this.readonlyModus && !this.readonly) {
                this.autoSave(field, value);
            }
        },
        onFieldChange({ field, value }, autoSave = false) {
            if (!field) return;
            this[MUTATION_TYPES.UPDATE_REGISTRATION_FIELD]({
                field,
                value
            });
            if (autoSave) {
                this.autoSave(field, value);
            }
        },
        checkIfDialogDuplicatesCheckShouldShow() {
            if (!this.backoffice || this.readonlyModus) return false;
            if (
                this.backoffice.duplicateDecision ===
                    DUPLICATE_DECISION_TYPES.MUTATION &&
                !this.backoffice.sapEquipmentNr
            )
                return true;
            if (this.backoffice.duplicateDecision) return false;
            return true;
        },
        onRegistrationBasicDataIconKeyClick(action) {
            if (action == "object-type") {
                this.dialogDuplicatesCheck = true;
            }
        },
        onSaveDuplicatesCheck(values) {
            this.onMergeRegistrationField(
                {
                    field: "backoffice",
                    value: values
                },
                true
            );
            this.dialogDuplicatesCheck = false;
        }
    },
    watch: {
        referenceId: {
            handler: function (newValue) {
                if (newValue) {
                    this.$nextTick(() => {
                        //with this we skip the first change
                        if (
                            this.registrationState >=
                                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS &&
                            !this.registration.step
                        ) {
                            this.goToStep(9);
                        } else if (
                            this.registrationState <
                                TaskStatus.TASK_VERIFICATION_REGISTRAR_IN_PROCESS ||
                            this.registrationState >= TaskStatus.TASK_CONFIRMED
                        ) {
                            this.step = 1;
                            this[MUTATION_TYPES.ENABLE_READONLY_MODUS]();
                        } else {
                            this.step = this.registration.step;
                        }
                        if (this.step !== 1) {
                            this.$emit("change:expand-basic-data-card");
                            this.$emit("change:step", this.step);
                        }
                        if (
                            this.registrationState < TaskStatus.TASK_CONFIRMED
                        ) {
                            if (this.registration?.scrollPosition != null) {
                                this.scrollToPositionTop(
                                    this.registration.scrollPosition
                                );
                            }
                            this.dialogDuplicatesCheck =
                                this.checkIfDialogDuplicatesCheckShouldShow();
                        }
                    });
                }
            },
            immediate: true
        }
    }
};
</script>
