<template>
    <v-list>
        <v-divider></v-divider>
        <app-dev-container>
            <template v-slot:content>
                @taskToCopy:
                <tree-view
                    :data="Object.assign({}, taskToCopy)"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
            </template>
        </app-dev-container>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.objectDetails" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryObjectDetails :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.equipmentLocation" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryEquipmentLocation
                    :task="task"
                    show-copy-btn
                    expandable
                />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0"
            v-if="task.objectType === 'PRESSURE_EQUIPMENT'"
        >
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.chambers" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryChambers :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="pa-0 ma-0"
            v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
        >
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.extinguishingBottles" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryExtinguishingBottles :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.addressOperator" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressOperator :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.addressBill" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressBill :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.addressOwner" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressOwner :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-divider v-if="!isActivelyHeated"></v-divider>
        <v-list-item dense class="pa-0 ma-0"
          v-if="!isActivelyHeated && task.objectType === 'PRESSURE_EQUIPMENT'"
        >
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.activelyHeated" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryActivelyHeated :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-list-item dense class="pa-0 ma-0"
            v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
        >
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="stepsToCopy.inspectionType" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryInspectionProcedure :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import Helpers from "@/utils/helpers";
import {
  Address,
  ObjectPropertiesPressureEquipment,
  ObjectPropertiesFireBottlesEquipment
} from "@/models/tasks";
export default {
    name: "TaskSummaryCopy",
    props: {
        task: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    components: {
        SummaryObjectDetails: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-object-details.vue"
            ),
        SummaryEquipmentLocation: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-equipment-location.vue"
            ),
        SummaryChambers: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-chambers.vue"
            ),
        SummaryExtinguishingBottles: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-extinguishing-bottles.vue"
            ),
        SummaryAddressOperator: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-operator.vue"
            ),
        SummaryAddressBill: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-bill.vue"
            ),
        SummaryAddressOwner: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-owner.vue"
            ),
        SummaryActivelyHeated: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-actively-heated.vue"
            ),
        SummaryInspectionProcedure: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-inspection-procedure.vue"
            )
    },
    data: () => ({
        stepsToCopy: {
            objectDetails: false,
            equipmentLocation: false,
            chambers: false,
            extinguishingBottles: false,
            addressOperator: false,
            addressBill: false,
            addressOwner: false,
            activelyHeated: false,
            inspectionType: false
        }
    }),
    computed: {
        isActivelyHeated() {
            return Helpers.isActivelyHeated(this.task);
        },
        taskToCopy() {
            return this.getTaskDataToCopy();
        }
    },
    methods: {
        getTaskDataToCopy() {
            let task = {};
            if (this.stepsToCopy.objectDetails) {
                const objectType = this.task.objectType;
                let instance = null;
                if (objectType === 'PRESSURE_EQUIPMENT') {
                  instance = new ObjectPropertiesPressureEquipment();
                } else if (objectType === 'FIRE_BOTTLES_EQUIPMENT') {
                  instance = new ObjectPropertiesFireBottlesEquipment();
                } else {
                  throw 'Unhandled object type.';
                }
                let objectProperties = Helpers.assignObjOnlyIfKeysExists(
                    instance,
                    this.task
                );
                task = Object.assign(task, objectProperties);
            }
            if (this.stepsToCopy.equipmentLocation) {
                task.customerSpecificDesignation =
                    this.task.customerSpecificDesignation;
                task.equipmentLocation = this.task.equipmentLocation;
            }
            if (this.stepsToCopy.chambers) {
                let chambers = this.task.chambers;
                task.chambers = chambers;
            }
            if (this.stepsToCopy.extinguishingBottles) {
                task.extinguishingBottles = this.task.extinguishingBottles;
            }
            if (this.stepsToCopy.addressOperator) {
                task.operatorAddress = {};
                let operatorAddress = Object.assign(
                    new Address(),
                    this.task.operatorAddress
                );
                task.operatorAddress = Object.assign(
                    task.operatorAddress,
                    operatorAddress
                );
            }
            if (this.stepsToCopy.addressBill) {
                task.billingAddress = {};
                let billingAddress = Object.assign(
                    new Address(),
                    this.task.billingAddress
                );
                task.billingAddress = Object.assign(
                    task.billingAddress,
                    billingAddress
                );
                task.billingAdditionalInfos = this.task.billingAdditionalInfos;
            }
            if (this.stepsToCopy.addressOwner) {
                task.ownerAddress = {};
                let ownerAddress = Object.assign(
                    new Address(),
                    this.task.ownerAddress
                );
                task.ownerAddress = Object.assign(
                    task.ownerAddress,
                    ownerAddress
                );
            }
            if (this.stepsToCopy.activelyHeated) {
                task.inspectionType = this.task.inspectionType;
                task.specialistCompany = this.task.specialistCompany;
            }
            if (this.stepsToCopy.inspectionType) {
                task.inspectionType = this.task.inspectionType;
            }
            return task;
        }
    },
    watch: {
        taskToCopy: {
            handler: function (newValue) {
                if (newValue) {
                    this.$emit("change", newValue);
                }
            },
            immediate: true
        }
    }
};
</script>

<style scoped>
.align-checkbox-on-top {
    align-self: start !important;
}
</style>
