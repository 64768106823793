<i18n src="./registration-card-content-i18n.yaml"></i18n>
<template>
    <v-list-item-content>
        <v-list-item-title class="text-body-2">
            <span class="font-weight-bold">{{ item.workingTitle }}</span>
        </v-list-item-title>
        <v-list-item-title
          class="text-body-2 text-wrap"
          v-if="item.objectType !== 'FIRE_BOTTLES_EQUIPMENT'"
        >
            <span class="font-weight-bold">{{ item.manufacturer }}</span
            >, {{ $t("label-factory-nr") }}:
            <span class="font-weight-bold">{{ item.factoryNumber }}</span
            >, {{ $t("label-construction-year") }}:
            <span class="font-weight-bold">{{ item.constructionYear }}</span>
        </v-list-item-title>
        <v-list-item-title
          class="text-body-2 text-wrap"
          v-if="item.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
        >
            <span class="font-weight-bold">{{ item.installator }}</span
            >, {{ $t("label-asset-nr") }}:
            <span class="font-weight-bold">{{ item.assetNumber }}</span
            >, {{ $t("label-construction-year") }}:
            <span class="font-weight-bold">{{ item.constructionYear }}</span>
        </v-list-item-title>
        <v-list-item-title v-if="addressExists" class="text-body-2">
            {{ $t("label-location") }}:
            <span class="font-weight-bold" v-if="item.address.companyName">
                {{ item.address.companyName }}
            </span>
            <span class="font-weight-bold" v-if="item.address.department">
                {{ item.address.department }}
            </span>
            <display-address-inline
                class="font-weight-bold"
                :address="item.address"
            >
            </display-address-inline>
        </v-list-item-title>
        <slot name="append-content" />
    </v-list-item-content>
</template>
<script>
import DisplayAddressInline from "@/components/general/display-address-inline.vue";
export default {
    name: "RegistrationCardContent",
    components: {
        DisplayAddressInline
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        addressExists() {
            let address = this.item?.address;
            if (!address) return false;
            if (
                !address.companyName ||
                !address.houseNr ||
                !address.street ||
                !address.country ||
                !address.city ||
                !address.zipCode
            ) {
                return false;
            }
            return true;
        }
    }
};
</script>
