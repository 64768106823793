<i18n src="./summary-extinguishing-bottles-i18n.yaml"></i18n>
<template>
  <BaseListItem :title="$t('title-chambers')" :expandable="expandable">
    <template v-slot:content>
      <ul class="text-subtitle-2 primary--text">
        <li>
          {{
            $t('details', {
                count: task.extinguishingBottles.bottlesCount || 0,
                materialName: materialName,
                pressure: parseFloat(task.extinguishingBottles.lastMaxPressure).toFixed(1),
                volume: parseFloat(task.extinguishingBottles.volume).toFixed(1)
            })
          }}
        </li>
      </ul>
    </template>
  </BaseListItem>
</template>

<script>
import { mapActions } from "vuex";
import mixin from "../mixin";
export default {
    name: "SummaryExtinguishingBottles",
    data() {
      return {
        materialCodes1: {}
      }
    },
    methods: {
      ...mapActions("attributes", ["getStoredAttributesByKey"]),
    },
    mixins: [mixin],
    components: {
      BaseListItem: () => import("../base-list-item.vue")
    },
    computed: {
      materialName() {
        const materialCode = this.task.extinguishingBottles.materialCode;
        const code = this.materialCodes1[materialCode];
        let materialName = '';
        if (code === undefined) {
          materialName = '(unknown material code: ' + materialCode + ')'
        } else {
          const locale = this.$i18n.locale || 'de';
          materialName = code[locale] || '';
        }
        return materialName;
      }
    },
    async created() {
        const me = this;
        const toObj = function(attributeName) {
          return new Promise((resolve, reject) => {
              me.getStoredAttributesByKey(attributeName)
                .then((v) => {
                    const r = {}
                    v.forEach((item) => {
                        r[item.key] = item.value;
                    })
                    resolve(r);
                })
                .catch((error) => {
                    reject([error, attributeName]);
                })
            })
        }
        this.loader().show();
        try {
            this.materialCodes1 = await toObj('stoffcode1');
            this.loader().hide();
        } catch (error) {
            console.log(error[0] || error);
            this.loader().hide();
            this.$toast.error(
                this.$t("load-key-error", {
                    keyName: error[1]
                })
            );
        }
    }
};
</script>
