var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","py-0":"","my-0":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("object-details-info"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('WInstallatorSearch',{ref:"installatorSearch",attrs:{"value":_vm.computedInstallator,"label":_vm.$t('installator-label'),"placeholder":_vm.$t('installator-placeholder'),"error-messages":_vm.installatorErrors,"required":_vm.isRequiredField(_vm.$v.installator)},on:{"change":function($event){_vm.computedInstallator = $event}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('base-text-field',{attrs:{"error-messages":_vm.assetNumberErrors,"counter":_vm.$v.assetNumber.$params.maxLength.max,"label":_vm.$t('asset-number-label'),"placeholder":_vm.$t('asset-number-placeholder'),"required":_vm.isRequiredField(_vm.$v.assetNumber)},model:{value:(_vm.computedAssetNumber),callback:function ($$v) {_vm.computedAssetNumber=$$v},expression:"computedAssetNumber"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('base-select',{attrs:{"items":_vm.constructionYearItems,"error-messages":_vm.constructionYearErrors,"label":_vm.$t('construction-year-label'),"placeholder":_vm.$t('construction-year-placeholder'),"required":_vm.isRequiredField(_vm.$v.constructionYear)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.constructionYearItems.indexOf(item) === 0)?_c('span',[_vm._v(_vm._s(item)+" ("+_vm._s(_vm.$t("this-year"))+")")]):(
                            _vm.constructionYearItems.indexOf(item) === 1
                        )?_c('span',[_vm._v(_vm._s(item)+" ("+_vm._s(_vm.$t("last-year"))+")")]):(
                            _vm.constructionYearItems.indexOf(item) === 2
                        )?_c('span',[_vm._v(_vm._s(item)+" ("+_vm._s(_vm.$t("two-years-ago"))+")")]):_c('span',[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.computedConstructionYear),callback:function ($$v) {_vm.computedConstructionYear=$$v},expression:"computedConstructionYear"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0"},[(
                    _vm.computedConstructionYear &&
                    _vm.computedConstructionYear < new Date().getFullYear()
                )?_c('base-select',{attrs:{"items":_vm.inOperationYearItems,"error-messages":_vm.inOperationYearErrors,"label":_vm.$t('in-operation-year-label'),"required":_vm.isRequiredField(_vm.$v.inOperationYear),"clearable":""},model:{value:(_vm.computedInOperationYear),callback:function ($$v) {_vm.computedInOperationYear=$$v},expression:"computedInOperationYear"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('base-textarea',{attrs:{"label":_vm.$t('intended-use-label'),"error-messages":_vm.intendedUseErrors,"counter":_vm.$v.intendedUse.$params.maxLength.max,"required":_vm.isRequiredField(_vm.$v.intendedUse),"clearable":"","no-resize":""},model:{value:(_vm.computedIntendedUse),callback:function ($$v) {_vm.computedIntendedUse=$$v},expression:"computedIntendedUse"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }