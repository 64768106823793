<i18n       src="@/lang/defaults-i18n.yaml"></i18n>
<i18n       src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <app-dev-container>
            <template v-slot:content>
                @overheatingVulnerable:
                <tree-view
                    :data="registration.overheatingVulnerable"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
            </template>
        </app-dev-container>
        <div v-if="activelyHeated">
            <base-alert width="100%" type="warning">
                {{ $t("actively-heated-msg") }}
            </base-alert>
        </div>
        <div v-else>
            <v-form :disabled="disabled" :readonly="readonlyModus">
                <v-row dense class="px-4">
                    <v-col>
                        <v-checkbox
                            v-model="inspectionType"
                            :label="
                                inspectionType === INSPECTION_TYPES.SVTI
                                    ? $t('inspection-type-svti-label')
                                    : $t('inspection-type-specialist-label')
                            "
                            :error-messages="inspectionTypeErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('inspectionType')"
                            color="primary"
                            @input="onInspectTypeChange"
                            :true-value="INSPECTION_TYPES.SVTI"
                            :false-value="INSPECTION_TYPES.SPECIALIST"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-container
                    fluid
                    py-0
                    my-0
                    v-if="inspectionType === INSPECTION_TYPES.SPECIALIST"
                >
                    <base-alert dense type="info">
                        {{ $t("inspection-type-specialist-text") }}
                    </base-alert>
                    <base-autocomplete
                        :items="sortedSpecialistsCompanies"
                        v-model="specialistCompany"
                        item-value="name"
                        :search-input.sync="search"
                        :label="$t('specialist-company-label')"
                        :placeholder="$t('specialist-company-placeholder')"
                        open-on-clear
                        append="mdi-information-outline"
                        :filter="filter"
                        :error-messages="specialistCompanyErrors"
                        :menu-props="{
                            closeOnContentClick: true,
                            closeOnClick: false
                        }"
                        return-object
                        :required="isRequiredField($v.specialistCompany)"
                        :item-text="(item) => item.name"
                    >
                    </base-autocomplete>
                </v-container>
                <v-container
                    fluid
                    py-0
                    my-0
                    v-if="
                        inspectionType &&
                        inspectionType != INSPECTION_TYPES.SVTI &&
                        specialistCompany
                    "
                >
                    <template v-if="!readonlyModus">
                        <v-row dense>
                            <v-col cols="12">
                                <base-alert
                                    width="100%"
                                    :type="uploadMsgType"
                                    v-html="$t('upload-msg')"
                                    dense
                                >
                                </base-alert>
                            </v-col>
                            <v-col>
                                <BtnUploadFiles
                                    v-if="!readonlyModus"
                                    :disabled="readonlyModus"
                                    v-on:click-item="onCategoryItemClick"
                                    color="info"
                                    :categories="attachmentCategories"
                                >
                                </BtnUploadFiles>
                            </v-col>
                        </v-row>
                    </template>

                    <multimedia-viewer
                        :deletable="!readonlyModus"
                        v-if="computedAttachments"
                        :attachments="computedAttachments"
                        thumbnailWidth="100px"
                        thumbnailMaxWidth="100px"
                        thumbnailHeight="100px"
                        :readonlyModus="readonlyModus"
                        v-on:attachment-deleted="deleteAttachment"
                        v-on:delete-multiple-attachments="
                            deleteMultipleAttachment
                        "
                        :docId="registration.docId"
                    >
                    </multimedia-viewer>
                    <modal-file-uploader
                        v-if="fileCategory"
                        :value="modalFileUploader"
                        @input="modalFileUploader = $event"
                        :docId="registration.docId"
                        :fileCategory="fileCategory"
                        :maxFileSize="maxFileSize"
                    >
                    </modal-file-uploader>
                </v-container>
            </v-form>
        </div>
    </v-container>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalFileUploader from "@/components/general/modal-file-uploader.vue";
import MultimediaViewer from "@/components/general/multimedia-viewer.vue";
import BtnUploadFiles from "@/components/general/btn-upload-files.vue";
import _ from "lodash";
import Helpers from "@/utils/helpers";
import { ATTACHMENTS_TYPES, INSPECTION_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
export default {
    name: "StepActivelyHeatedContent",
    components: {
        ModalFileUploader,
        MultimediaViewer,
        BtnUploadFiles
    },

    mixins: [validateMixin],
    validations: {
        specialistCompany: {
            required: requiredIf(function () {
                if (this.inspectionType === this.INSPECTION_TYPES.SPECIALIST) {
                    return true;
                } else {
                    return false;
                }
            })
        },
        inspectionType: {
            required
        }
    },

    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Object,
            default: () => {},
            required: true
        },
        attachmentsStatesRange: {
            type: Array,
            default: () => [],
            required: true
        },
        uploadMsgType: {
          type: String,
          default: 'info'
        }
    },

    data: () => ({
        INSPECTION_TYPES,
        attachmentObj: {},
        fileCategory: "",
        modalFileUploader: false,
        maxFileSize: 150,
        allowedCategories: [ATTACHMENTS_TYPES.INSPECTION_PLAN],
        attachmentCategories: [],
        specialistsCompanies: [],
        search: null,
        ATTACHMENTS_TYPES: ATTACHMENTS_TYPES
    }),

    methods: {
        filter(item, queryText, itemText) {
            itemText = item.name;
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(itemText);
            const query = hasValue(queryText);

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
        onInspectTypeChange(type) {
            this.attachmentCategories = [];
            let inspectionPlan = { itemId: ATTACHMENTS_TYPES.INSPECTION_PLAN };
            if (type === this.INSPECTION_TYPES.SPECIALIST) {
                this.attachmentCategories.push(inspectionPlan);
            } else {
                this.attachmentCategories = [];
            }
        },
        reset() {
            this.$v.$reset();
            this.specialistCompany = null;
            this.inspectionType = null;
            this.attachmentCategories = [];
        },
        isValid() {
            if (this.inspectionType === this.INSPECTION_TYPES.SVTI) return true;
            if (!this.$v.$dirty) return null;
            if (this.activelyHeated) return true;
            const inspectionPlans = this.computedAttachments.INSPECTION_PLAN || {};
            const documents = inspectionPlans.documents || [];
            if (documents.length === 0) {
              this.uploadMsgType = 'error';
              return false;
            } else {
              this.uploadMsgType = 'info';
            }
            if (this.$v.$anyError) {
                return false;
            } else {
                return true;
            }
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        getAllAttachments() {
            if (
                !this.registration.docId ||
                this.inspectionType === this.INSPECTION_TYPES.SVTI
            ) {
                return;
            }
            let data = {
                docId: this.registration.docId,
                states: _.range(
                    this.attachmentsStatesRange[0],
                    this.attachmentsStatesRange[1]
                )
            };
            this.$axiosBinary
                .post("/getAllTaskAttachmentsByStates", data)
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const resData = response.data.data;
                        const attachments = resData.attachments;
                        this.$emit("attachments:change", attachments);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteAttachment(attachment) {
            this.loadingMsg = this.$t("is-loading-msg-delete");
            let data = {
                docId: this.registration.docId,
                attachmentId: attachment.id
            };
            this.$axiosBinary
                .delete("/", { data })
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.getAllAttachments();
                        this.loader().hide();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        deleteMultipleAttachment(attachments) {
            this.loader().show(this.$t("is-loading-msg-delete"));
            let axiosPromises = [];
            attachments.forEach((attachment) => {
                let data = {
                    docId: this.registration.docId,
                    attachmentId: attachment.id
                };
                axiosPromises.push(this.$axiosBinary.delete("/", { data }));
            });
            Promise.all(axiosPromises)
                .then(() => {
                    this.getAllAttachments();
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                    return;
                });
        },
        onCategoryItemClick(item) {
            this.fileCategory = item;
            this.modalFileUploader = true;
        },
        onFileUploaderComplete() {
            this.getAllAttachments();
        },
        onFieldChange(field, value) {
            this.$emit("field-change", { field, value });
        },
        getSpecialistsCompanies() {
            this.$store
                .dispatch(
                    "specialistsCompanies/fetchAllActiveSpecialistCompanies"
                )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        const companies = response.data.data;
                        this.specialistsCompanies = companies;
                        if (this.specialistCompany) {
                            if (typeof this.specialistCompany === "string") {
                                const item =
                                    this.specialistCompany.toLowerCase();
                                const found = this.specialistsCompanies.find(
                                    (company) =>
                                        company.name.toLowerCase() === item
                                );
                                if (!found) {
                                    this.specialistsCompanies.push({
                                        name: item
                                    });
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getCounterByAttachmentId(attachmentId) {
            if (!this.computedAttachments) return 0;
            const att = this.computedAttachments[attachmentId] || [];
            let counter = 0;
            if (att) {
                if (att.photos) {
                    counter += att.photos.length;
                }
                if (att.documents) {
                    counter += att.documents.length;
                }
            }
            return counter;
        }
    },
    computed: {
        inspectionPlanAttCount() {
            return this.getCounterByAttachmentId(
                ATTACHMENTS_TYPES.INSPECTION_PLAN
            );
        },
        sortedSpecialistsCompanies() {
            if (this.specialistsCompanies.length === 0) return [];

            return this.specialistsCompanies
                .slice(0)
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        computedAttachments() {
            const attachments = this.registration.attachments || {};
            if (!attachments) return {};
            return _.transform(attachments, (obj, val, key) => {
                if (this.allowedCategories.includes(key)) {
                    obj[key.trim()] = val;
                }
            });
        },
        inspectionType: {
            get() {
                if (this.registration) {
                    return this.registration.inspectionType;
                } else {
                    return this.INSPECTION_TYPES.SVTI;
                }
            },
            set(value) {
                this.onFieldChange("inspectionType", value);
            }
        },
        specialistCompany: {
            get() {
                if (this.registration) {
                    let id = this.registration?.specialistCompany?.id;
                    let name = this.registration?.specialistCompany?.name;
                    if (id || name) {
                        return { id, name };
                    }
                }
                return null;
            },
            set(value) {
                this.onFieldChange("specialistCompany", value);
            }
        },
        activelyHeated() {
            return Helpers.isActivelyHeated(this.registration);
        },
        specialistCompanyErrors() {
            const errors = [];
            if (!this.$v.specialistCompany.$dirty) {
                return errors;
            }
            if (!this.$v.specialistCompany.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        inspectionTypeErrors() {
            const errors = [];
            if (!this.$v.inspectionType.$dirty) {
                return errors;
            }
            if (!this.$v.inspectionType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },

    mounted() {
        this.getSpecialistsCompanies();
    },

    watch: {
        inspectionType: {
            handler: function (newValue) {
                if (newValue) {
                    this.onInspectTypeChange(newValue);
                }
            },
            immediate: true
        },
        modalFileUploader: {
            handler: function (newValue) {
                if (newValue == false) {
                    this.getAllAttachments();
                }
            },
            immediate: true
        }
    }
};
</script>
