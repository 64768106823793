import { Scroll } from "vuetify/lib/directives";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
import { Address } from "@/models/tasks";
export default {
    components: {
        StepObjectPropertiesContent: () =>
            import(
                "@/components/tasks/edit/common/steps/object-properties/content.vue"
            ),
        StepPressureDeviceLocationContent: () =>
            import(
                "@/components/tasks/edit/common/steps/pressure-device-location/content.vue"
            ),
        StepExtinguishingBottlesContent: () =>
            import("@/components/tasks/edit/common/steps/extinguishing-bottles/content.vue"),
        StepAddressOperatorContent: () =>
            import(
                "@/components/tasks/edit/common/steps/address-operator/content.vue"
            ),
        StepAddressBillContent: () =>
            import(
                "@/components/tasks/edit/common/steps/address-bill/content.vue"
            ),
        StepAddressOwnerContent: () =>
            import(
                "@/components/tasks/edit/common/steps/address-owner/content.vue"
            ),
        StepUploadContent: () =>
            import("@/components/tasks/edit/common/steps/upload/content.vue"),
        StepInspectionProcedureContent: () =>
            import(
                "@/components/tasks/edit/common/steps/inspection-procedure/content.vue"
            ),
        StepSummaryContent: () =>
            import("@/components/tasks/edit/common/steps/summary/content.vue"),
        RegistrationBasicData: () =>
            import(
                "@/components/tasks/edit/common/components/registration-basic-data.vue"
            )
    },
    inject: ["theme"],
    directives: { Scroll },
    props: {
        stepSync: {
            type: [Number, String],
            default: 1
        },
        expandBasicDataCard: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        step: 1,
    }),
    computed: {
        ...mapGetters("settings", {
            registrationOffice: "getRegistrationOffice"
        }),
        ...mapState("settings", ["developerMode"]),
        ...mapGetters("auth", ["mail"]),
        taskStep: {
            get() {
                return this.registration?.step || 1;
            },
            set(value) {
                this.onFieldChange({ field: "step", value });
            }
        },
        operatorAddress: {
            get() {
                return this.registration?.operatorAddress;
            },
            set: _.debounce(function (value) {
                this.onFieldChange({ field: "operatorAddress", value }, true);
            }, 1000)
        },
        billingAddress: {
            get() {
                return this.registration?.billingAddress;
            },
            set: _.debounce(function (value) {
                this.onFieldChange({ field: "billingAddress", value }, true);
            }, 1000)
        },
        ownerAddress: {
            get() {
                return this.registration?.ownerAddress;
            },
            set: _.debounce(function (value) {
                this.onFieldChange({ field: "ownerAddress", value }, true);
            }, 1000)
        },
        devStepsItemsList() {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9];
        }
    },
    methods: {
        onScroll(e) {
            if (this.readonlyModus) return;
            let scrollPosition = e.target.scrollingElement.scrollTop;
            this[ACTION_TYPES.UPDATE_SCROLL_POSITION](scrollPosition);
        },
        areAllStepsValid(stepsToValidate = []) {
            let defaultStepsToValidate = [1, 2, 3, 4, 5, 6, 8];
            if (stepsToValidate.length === 0) {
                stepsToValidate = defaultStepsToValidate;
            }
            for (let step in stepsToValidate) {
                const isValid = this.isStepValid(step);
                if (!isValid) return false;
            }
            return true;
        },
        scrollToStep(stepCount) {
            // setTimeout beacause the tansaction takes some time
            // without setTimeout will not work
            // is also possible that the transaction maybe takes more time
            // as that is defined in the timeout
            setTimeout(() => {
                const stepRef = `step${stepCount}`;
                let ref = this.$refs[stepRef];
                if (ref) {
                    let options = {
                        duration: 500,
                        offset: 0,
                        easing: "easeInOutCubic"
                    };
                    this.$vuetify.goTo(ref, options);
                }
            }, 1000);
        },
        goToStep(stepCount) {
            this.step = stepCount;
            this.scrollToStep(stepCount);
        },
        validateAllSteps() {
            this.validateStep01();
            this.validateStep02();
            this.validateStep03();
            this.validateStep04();
            this.validateStep05();
            this.validateStep06();
            this.validateStep07();
            this.validateStep08();
        },
        autoSave(field, value) {
            if (field) {
                this[ACTION_TYPES.AUTO_SAVE_REGISTRATION]({
                    field,
                    value
                });
            } else {
                this[ACTION_TYPES.AUTO_SAVE_REGISTRATION]();
            }
        },
        onResetEquipmentLocation(autoSave = false) {
            if (!this.readonlyModus) {
                // TODO
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onUpdateEquipmentLocationContactPerson(data, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_CONTACT_PERSON](
                    data
                );

                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onUpdateEquipmentLocationAddress(data, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.UPDATE_EQUIPMENT_LOCATION_ADDRESS](data);
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onUpdateEquipmentLocationField({ field, value }, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.MERGE_REGISTRATION_FIELDS]({
                    field_name: field,
                    values: value
                });
                if (autoSave) {
                    this.autoSave();
                }
            }
        },

        onResetEquipmentLocationAddress(autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_ADDRESS]();
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onResetCustomerSpecificDesignation(autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.RESET_CUSTOMER_SPECIFIC_DESIGNATION]();
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onResetEquipmentLocationContactPerson(autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON]();
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onResetExtinguishingBottles(autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.RESET_EXTINGUISHING_BOTTLES]();
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onResetEx(autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.RESET_EQUIPMENT_LOCATION_CONTACT_PERSON]();
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onMergeRegistrationField({ field, value }, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.MERGE_REGISTRATION_FIELDS]({
                    field_name: field,
                    values: value
                });

                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onMergeRegistrationAddressValuesByFieldName(
            { field, value },
            autoSave = false
        ) {
            if (!this.readonlyModus) {
                if (field) {
                    if (!value || Object.keys(value).length === 0) {
                        this.onClearAddress({ field }, autoSave);
                    } else {
                        this.onMergeRegistrationField(
                            { field, value },
                            (autoSave = false)
                        );
                    }
                }
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onChangeBillingAdditionalInfosField({field, value}, autoSave = false) {
            if (!this.readonlyModus) {
                let fieldName = "billingAdditionalInfos"
                let fieldValue = { [field]: value }
                this.onMergeRegistrationField({
                    field: fieldName,
                    value: fieldValue
                });
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onRemoveChmaberByIndex(index, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.REMOVE_CHAMBER_BY_INDEX](index);
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onChangeChamber(chamber, autoSave = false) {
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.UPDATE_CHAMBERS](chamber);
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onChangeAddress({ field, value }, autoSave = false) {
            if (!this.readonlyModus) {
                this.onFieldChange({ field, value }, autoSave);
            }
        },
        onChangeAddressField({ address, field, value }, autoSave = false) {
            if (!address || !field) return;
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.UPDATE_REGISTRATION_ADDRESS_FIELD]({
                    address: address,
                    field: field,
                    value: value
                });

                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        onClearAddress({ field, address }, autoSave = false) {
            if (!field) return;
            if (!address) {
                address = new Address();
            }
            if (!this.readonlyModus) {
                this[MUTATION_TYPES.CLEAR_REGISTRATION_ADDRESS]({
                    field,
                    address
                });
                if (autoSave) {
                    this.autoSave();
                }
            }
        },
        scrollToPositionTop(position) {
            let options = {
                top: position,
                left: 0,
                speed: 500,
                behavior: "smooth"
            };
            window.scrollTo(options);
        }
    },
    watch: {
        step: {
            handler: function (newStep) {
                if (newStep !== null) {
                    this.$emit("change:step", newStep);
                    this.taskStep = newStep;
                }
            }
        },
        taskStep: {
            handler: function (newStep) {
                if (newStep !== null) {
                    this.step = newStep;
                }
            },
            immediate: true
        },
        stepSync: {
            handler: function (newStep) {
                if (newStep !== null && newStep != this.step) {
                    this.step = newStep;
                }
            },
            immediate: true
        },
        expandBasicDataCard: {
            handler: function (expand) {
                if (expand === true) {
                    this.goToStep(0);
                }
            }
        }
    }
};
