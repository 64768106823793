<template>
  <SectionBase id="devel-w-fields" title="WFields">
    <SectionBase id="devel-w-fields-w-cascader" title="WCascader">
      <w-cascader
        v-model="cascaderSelectedItem"
        label="Select Product"
        item-value="id"
        item-text="text"
        :items="cascaderItems"
      />
    </SectionBase>
    <SectionBase id="devel-w-fields-w-country-select" title="WCountrySelect">
      <WCountrySelect
        ref="country"
        :preferred-countries="['CH', 'LI', 'DE', 'FR', 'IT', 'AT']"
        @input="country = $event"
        :value="country"
        @click:clear="country = null"
        :required="!country"
      ></WCountrySelect>
      <div v-if="country">
        You habe selected
        <b>{{ country }}</b>
        <country-flag class="ml-2" :country="country.toLowerCase()" />
      </div>
      <div v-if="!country">No country selected!</div>
    </SectionBase>
    <SectionBase id="devel-w-fields-w-canton-field" title="WCantonField">
      <WCantonField
        ref="country"
        @input="canton = $event"
        :value="canton"
        @click:clear="canton = null"
        required
      ></WCantonField>
      <div v-if="canton">
        You habe selected
        <b>{{ canton }}</b>
      </div>
      <div v-if="!canton">No canton selected!</div>
    </SectionBase>
    <SectionBase
      id="devel-w-fields-w-manufacturer-search"
      title="WManufacturerSearch"
    >
      <WManufacturerSearch
        :value="manufacturer"
        @change="manufacturer = $event"
        label="Hersteller suche"
        required
        :error-messages="['error']"
      />
      <span v-if="manufacturer">MANUFACTURER: {{ manufacturer }}</span>
    </SectionBase>
    <SectionBase
      id="devel-w-fields-w-installator-search"
      title="WInstallatorSearch"
    >
      <WInstallatorSearch
        :value="installator"
        @change="installator = $event"
        label="Errichter Suche"
        required
        :error-messages="['error']"
      />
      <span v-if="installator">INSTALLATOR: {{ installator }}</span>
    </SectionBase>
    <SectionBase id="devel-w-fields-w-tel-number-field" title="WTelNumberField">
      <WTelNumberField
        ref="telField"
        required
        clearable
        :label="$t('phone-label')"
        @input="phoneField = $event"
        :value="phoneField.number"
      >
      </WTelNumberField>
      <code v-if="phoneField.number"> {{ Object.assign({}, phoneField) }}</code>
      <template v-slot:card-actions>
        <v-btn
          tile
          depressed
          class="mr-4 error"
          @click="phoneField.number = '+41 79 478 00 44'"
        >
          Set Value
        </v-btn>
        <v-btn
          tile
          depressed
          class="mr-4 warning"
          @click="$refs.telField.validate()"
        >
          Validate
        </v-btn>
        <v-btn
          tile
          depressed
          class="mr-4 error"
          @click="$refs.telField.reset()"
        >
          Reset
        </v-btn>
      </template>
    </SectionBase>
    <SectionBase id="devel-w-fields-w-location-search" title="WLocationSearch">
      <WCountrySelect
        ref="country"
        :preferred-countries="['CH', 'LI', 'DE', 'FR', 'IT', 'AT']"
        @input="countryLocation = $event"
        :value="countryLocation"
        @click:clear="countryLocation = null"
        :required="!countryLocation"
      ></WCountrySelect>
      <WLocationSearch
        required
        v-model="wLocationSearchModel"
        :country="countryLocation"
        @reset="wLocationSearchModel = null"
      />
    </SectionBase>
    <SectionBase id="devel-w-fields-w-numeric-field" title="WNumericField">
      <WNumericField required v-model="WNumericFieldModel" label="WNumericField">
      </WNumericField>
    </SectionBase>
    <SectionBase id="devel-w-fields-w-decimal-field" title="WDecimalField">
      <WDecimalField required v-model="WDecimalFieldModel" label="WDecimalField">
      </WDecimalField>
    </SectionBase>
  </SectionBase>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SectionWFields",
  components: {
    SectionBase: () => import("./base.vue"),
    WCascader: () => import("@/components/fields/w-cascader.vue"),
    WInstallatorSearch: () =>
      import("@/components/fields/WInstallatorSearch.vue"),
    WCountrySelect: () => import("@/components/fields/WCountrySelect.vue"),
    WManufacturerSearch: () =>
      import("@/components/fields/WManufacturerSearch.vue"),
    WTelNumberField: () => import("@/components/fields/WTelNumberField.vue"),
    WLocationSearch: () => import("@/components/fields/WLocationSearch.vue"),
    WDecimalField: () => import("@/components/fields/WDecimalField.vue"),
    WNumericField: () => import("@/components/fields/WNumericField.vue"),
  },
  data: () => ({
    country: null,
    countryLocation: 'CH',
    manufacturer: null,
    installator: null,
    phoneField: { number: "", valid: false },
    entries: [],
    WNumericFieldModel: null,
    wLocationSearchModel: null,
    WDecimalFieldModel: null,
    canton: null,
    cascaderItems: [
      {
        id: 1,
        text: "Phone",
        value: "phone",
        children: [
          {
            id: 2,
            text: "IPhone",
            value: "iphone",
            children: [
              {
                id: 3,
                text: "IPhone 12",
                value: "iphone 12",
              },
              {
                id: 99,
                text: "IPhone 8",
                value: "iphone 8",
              },
            ],
          },
        ],
      },
      {
        id: 11,
        text: "Computer",
        value: "computer",
        children: [
          {
            id: 12,
            text: "Mac",
            value: "mac",
            children: [
              {
                id: 13,
                text: "Mac Air",
                value: "Mac air",
              },
            ],
          },
          {
            id: 14,
            text: "PC",
            value: "PC",
            children: [
              {
                id: 15,
                text: "Surface",
                value: "surface ",
              },
            ],
          },
        ],
      },
    ],
    cascaderSelectedItem: 99,
  }),
  computed: {
    ...mapState({
      isLoading: "settings/showLoader",
    }),
  },
};
</script>
