import { EXTERNAL_LINKS, ACCOUNT_ROLE } from "@/data/globals.js";
import Helpers from "@/utils/helpers";

const _baseIconColor = () => {
    return _getColorByKey("grey", "lighten2");
};

const _getColorByKey = (key, colorization = "base") => {
    return Helpers.getColorByKey(key, colorization);
};

const Menu = [
    {
        title: "title-home",
        icon: "mdi-home",
        name: "home"
    },
    {
        title: "title-account-settings",
        icon: "mdi-account-cog",
        name: "account-settings-user",
        meta: {
            requiresAuth: true
        }
    },
    {
        title: "title-dialog-faq",
        icon: "mdi-comment-question",
        name: "dialogFaq",
        meta: {
            isDialog: true
        }
    },
    {
        title: "title-links",
        group: "related-links",
        icon: "mdi-open-in-new",
        links: EXTERNAL_LINKS
    },
    {
        divider: true,
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.BACKOFFICE]
        }
    },
    {
        header: "title-backoffice",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.BACKOFFICE]
        }
    },
    {
        title: "title-backoffice-dashboard",
        icon: "mdi-monitor-dashboard",
        name: "backoffice",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.BACKOFFICE]
        }
    },
    {
        title: "title-backoffice-my-tasks",
        group: "backofficeListsOwner",
        svgIcon: "w-account.svg",
        svgColor: _baseIconColor(),
        meta: {
            requiresAuth: true,
            badge: true,
            badgeId: "totalCountMyRegistrationsLists",
            requiresRoles: [ACCOUNT_ROLE.BACKOFFICE],
            open: false
        },
        items: [
            {
                name: "backoffice-tasks-list-newreplies",
                title: "title-list-newreplies",
                svgIcon: "w-message-star.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("yellow", "base"),
                meta: {
                    sortId: 1,
                    badge: true,
                    badgeId: "registrationsListNewReplies"
                }
            },
            {
                name: "backoffice-tasks-list-overduequestions",
                title: "title-list-overduequestions",
                svgIcon: "w-file-document-multiple-warning.svg",
                svgColor:
                    _baseIconColor() +
                    " " +
                    _getColorByKey("orange", "lighten2"),
                meta: {
                    sortId: 2,
                    badge: true,
                    badgeId: "registrationsListOverdueQuestions"
                }
            },
            {
                name: "backoffice-tasks-list-opentasks",
                title: "title-list-opentasks",
                svgIcon: "w-message-star.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("blue", "lighten1"),
                meta: {
                    sortId: 3,
                    badge: true,
                    badgeId: "registrationsListOpenRegistrations"
                }
            },
            {
                name: "backoffice-tasks-list-bo-ready-for-sap-export",
                title: "title-list-bo-ready-for-sap-export",
                svgIcon: "w-task-transfer-to-system.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("blue", "lighten1"),
                meta: {
                    sortId: 4,
                    badge: true,
                    badgeId: "boTasksReadyForSapExport"
                }
            }
        ]
    },
    {
        title: "title-all-tasks",
        group: "backofficeListsAll",
        svgIcon: "w-message-star.svg",
        svgColor: _baseIconColor(),
        meta: {
            requiresAuth: true,
            badge: true,
            badgeId: "totalCountAllRegistrationsLists",
            requiresRoles: [ACCOUNT_ROLE.BACKOFFICE],
            open: false
        },
        items: [
            {
                name: "backoffice-tasks-list-pending-pool",
                title: "title-list-pending-pool",
                svgIcon: "w-file-document-multiple-star.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("yellow", "base"),
                meta: {
                    sortId: 1,
                    badge: true,
                    badgeId: "registrationsListPendingPool"
                }
            },
            {
                name: "backoffice-tasks-list-overduetasks",
                title: "title-list-overduetasks",
                svgIcon: "w-message-warning.svg",
                svgColor:
                    _baseIconColor() +
                    " " +
                    _getColorByKey("orange", "lighten2"),
                meta: {
                    sortId: 2,
                    badge: true,
                    badgeId: "registrationsListOverdueRegistrations"
                }
            },
            {
                name: "backoffice-tasks-list-all-ready-for-sap-export",
                title: "title-list-all-ready-for-sap-export",
                svgIcon: "w-task-transfer-to-system.svg",
                svgColor:
                    _baseIconColor() +
                    " " +
                    _getColorByKey("orange", "lighten2"),
                meta: {
                    sortId: 3,
                    badge: true,
                    badgeId: "allTasksReadyForSapExport"
                }
            },
            {
                name: "backoffice-tasks-list-search",
                title: "title-list-search",
                svgIcon: "w-file-document-multiple-new.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("blue", "lighten1"),
                meta: {
                    sortId: 4,
                    badge: true,
                    badgeId: "tasksTotalCount"
                }
            }
        ]
    },
    {
        divider: true,
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.INSURER]
        }
    },
    {
        header: "title-insurer",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.INSURER]
        }
    },
    {
        title: "title-insurer-dashboard",
        icon: "mdi-monitor-dashboard",
        name: "insurer",
        meta: {
            requiresAuth: true,
            onlyForRoles: [ACCOUNT_ROLE.REGISTRANT, ACCOUNT_ROLE.BACKOFFICE],
            requiresRoles: [ACCOUNT_ROLE.INSURER]
        }
    },
    {
        title: "title-insurer-all-tasks",
        group: "insurerListsAll",
        svgIcon: "w-message-star.svg",
        svgColor: _baseIconColor(),
        meta: {
            requiresAuth: true,
            badge: true,
            badgeId: "totalCountAllInsurerTasksLists",
            requiresRoles: [ACCOUNT_ROLE.INSURER],
            open: false
        },
        items: [
            {
                name: "insurer-tasks-list-approval-required",
                title: "title-insurer-tasks-list-approval-required",
                svgIcon: "w-file-document-multiple-star.svg",
                svgColor:
                    _baseIconColor() + " " + _getColorByKey("yellow", "base"),
                meta: {
                    sortId: 1,
                    badge: true,
                    badgeId: "approvalRequiredPoolTasks"
                }
            }
        ]
    },
    { divider: true },
    { header: "title-extras" },
    {
        title: "title-dialog-impressum",
        group: "extras",
        icon: "mdi-foot-print",
        name: "dialogImpressum",
        meta: {
            isDialog: true
        }
    },
    {
        title: "title-dialog-info",
        group: "extras",
        icon: "mdi-information-outline",
        name: "dialogInfo",
        meta: {
            isDialog: true
        }
    },
    {
        divider: true,
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.ADMIN]
        }
    },
    {
        header: "title-header-admin",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.ADMIN]
        }
    },
    {
        title: "title-admin-dashboard",
        icon: "mdi-monitor-dashboard",
        name: "admin-dashboard",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.ADMIN]
        }
    },
    {
        title: "title-admin-config",
        icon: "mdi-cog",
        name: "admin-config-view",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.ADMIN]
        }
    },
    {
        title: "title-admin-items",
        group: "admin",
        icon: "mdi-shield-account",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.ADMIN],
            open: false
        },
        items: [
            {
                title: "title-admin-specialists-companies-view",
                icon: "mdi-office-building",
                name: "admin-specialists-companies-view",
                meta: {
                    requiresAuth: true,
                    requiresRoles: [ACCOUNT_ROLE.ADMIN]
                }
            },
            {
                title: "title-admin-manufacturers-companies-view",
                icon: "mdi-office-building",
                name: "admin-manufacturers-companies-view",
                meta: {
                    requiresAuth: true,
                    requiresRoles: [ACCOUNT_ROLE.ADMIN]
                }
            },
            {
                title: "title-admin-installators-companies-view",
                icon: "mdi-office-building",
                name: "admin-installators-companies-view",
                meta: {
                    requiresAuth: true,
                    requiresRoles: [ACCOUNT_ROLE.ADMIN]
                }
            },
            {
                title: "title-admin-users-view",
                icon: "mdi-account-multiple",
                name: "admin-users-view",
                meta: {
                    requiresAuth: true,
                    requiresRoles: [ACCOUNT_ROLE.ADMIN]
                }
            }
        ]
    },
    {
        divider: true,
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        }
    },
    {
        header: "title-header-devel",
        meta: {
            requiresAuth: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        }
    },
    {
        title: "title-dialog-settings",
        group: "extras",
        icon: "mdi-cog",
        name: "dialogSettings",
        meta: {
            requiresAuth: true,
            isDialog: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        }
    },
    {
        title: "title-my-tasks",
        icon: "mdi-file-cabinet",
        name: "my-tasks",
        meta: {
            requiresAuth: true,
            isDeveloperMode: true,
            onlyForRoles: [ACCOUNT_ROLE.REGISTRANT]
        }
    },
    {
        title: "title-devel-dashboard",
        icon: "mdi-monitor-dashboard",
        name: "devel-dashboard",
        meta: {
            requiresAuth: true,
            isDeveloperMode: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        }
    },
    {
        title: "title-devel",
        group: "devel",
        icon: "mdi-bug",
        meta: {
            requiresAuth: true,
            isDeveloperMode: true,
            open: false,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        },
        items: [
            {
                title: "title-devel-test",
                icon: "mdi-bug",
                name: "devel-test",
                meta: {
                    requiresAuth: true,
                    isDeveloperMode: true,
                    requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
                }
            },

            {
                title: "title-devel-mailspreview",
                icon: "mdi-email",
                name: "devel-mailspreview",
                meta: {
                    requiresAuth: true,
                    isDeveloperMode: true,
                    requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
                }
            },
        ]
    },
    {
        title: "title-devel-error-pages",
        group: "error-pages",
        icon: "mdi-file-cancel-outline",
        meta: {
            requiresAuth: true,
            isDeveloperMode: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        },
        items: [
            {
                title: "title-error-page-403",
                icon: "mdi-file-outline",
                name: "403",
            },
            {
                title: "title-error-page-404",
                icon: "mdi-file-outline",
                name: "404",
            },
            {
                title: "title-error-page-500",
                icon: "mdi-file-cancel-outline",
                name: "500",
            },
            {
                title: "title-error-page-token-invalid",
                icon: "mdi-file-cancel-outline",
                name: "token-invalid",
            }
        ]
    },
    {
        title: "title-devel-utilities-pages",
        group: "utilities-pages",
        icon: "mdi-file-cog-outline",
        meta: {
            requiresAuth: true,
            isDeveloperMode: true,
            requiresRoles: [ACCOUNT_ROLE.DEVELOPER]
        },
        items: [
            {
                title: "title-utilities-page-maintenance",
                icon: "mdi-file-outline",
                name: "maintenance",
            },
            {
                title: "title-utilities-page-coming-soon",
                icon: "mdi-file-outline",
                name: "coming-soon",
            }
        ]
    },
];
// reorder menu
Menu.forEach((item) => {
    if (item.items) {
        item.items.sort((x, y) => {
            if (x.meta && x.meta.sortId && y.meta.sortId) {
                let xId = x.meta.sortId;
                let yId = y.meta.sortId;
                return xId < yId ? -1 : xId > yId ? 1 : 0;
            } else {
                let textA = x.title.toUpperCase();
                let textB = y.title.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            }
        });
    }
});

export default Menu;
