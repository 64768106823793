var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GlobalTable',{attrs:{"items":_vm.installators,"options":_vm.tableOptions,"headers":_vm.headers,"actions":_vm.filteredActions,"funcShowAction":_vm.showAction,"search":_vm.search,"btnAddText":_vm.$t('btn-add-company'),"isLoading":_vm.isGlobalLoading,"loading":_vm.isLoading,"server-items-length":_vm.totalCompaniesCount,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:action":_vm.onActionClick,"click:add":_vm.onAdd,"click:reload":_vm.fetchAllCompanies,"input:search":function($event){_vm.search = $event},"options:change":function (options) {
                Object.assign(_vm.tableOptions, options);
            },"page-count":function (count) { return (_vm.totalPagesCount = count); },"update:page":_vm.fetchAllCompanies,"update:items-per-page":_vm.fetchAllCompanies},scopedSlots:_vm._u([{key:"item.used",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"pt-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(item.deletedState > 0)?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("mdi-delete")]):(item.used)?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v("mdi-check-circle")]):_c('v-icon',_vm._g({attrs:{"color":"grey"}},on),[_vm._v("mdi-close-circle")])]}}],null,true)},[_c('span',[(item.deletedState > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-deleted"))+" ")]):(item.used)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-used"))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-not-used"))+" ")])])]),(
                        item.deletedState == 0 &&
                        !_vm.isValidAddress(item.address)
                    )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("status-address-invalid"))+" ")])])]):_vm._e()],1)]}},{key:"item.country",fn:function(ref){
                    var item = ref.item;
return [(item.country)?_c('div',{staticClass:"pt-3"},[_c('country-flag',{attrs:{"country":item.country}})],1):_vm._e()]}}])}),(_vm.dialogAddress)?_c('DialogAddress',{attrs:{"value":_vm.dialogAddress,"requiredFields":_vm.formAddressRequiredFields,"title":_vm.computedTitle,"address":_vm.address},on:{"input":function($event){_vm.dialogAddress = $event},"save":_vm.onSaveAddress,"address:input":function (newAddress) {
                _vm.address = newAddress;
            },"address:reset":_vm.onResetAddress}}):_vm._e(),(_vm.dialogReportNewAddress && _vm.selectedInstallator)?_c('DialogReportNewAddress',{attrs:{"address":_vm.getAddressFormattedForReport(_vm.selectedInstallator),"referenceId":_vm.selectedInstallator.docId},on:{"complete":function($event){_vm.dialogReportNewAddress = false;}},model:{value:(_vm.dialogReportNewAddress),callback:function ($$v) {_vm.dialogReportNewAddress=$$v},expression:"dialogReportNewAddress"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }