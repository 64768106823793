var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"readonly":_vm.readonlyModus,"disabled":_vm.disabled}},[_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('base-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.setFakeData}},[_c('v-icon',[_vm._v("mdi-bug")])],1),_c('base-btn',{attrs:{"icon":"","color":"yellow"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_vm._v(" @Validation: "),_c('tree-view',{attrs:{"data":_vm.$v,"options":{ maxDepth: 0 }}})]},proxy:true}])}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6"}},[_c('WNumericField',{attrs:{"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.bottlesCount,"disabled":_vm.disabled,"minValue":_vm.$v.extinguishingBottles.bottlesCount.$params.minValue.min,"maxValue":_vm.$v.extinguishingBottles.bottlesCount.$params.maxValue.max,"label":_vm.$t('bottles-count-label'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.extinguishingBottles.bottlesCount.$params.minValue.min,
                        maxValue: _vm.$v.extinguishingBottles.bottlesCount.$params.maxValue.max
                    }),"error-messages":_vm.bottlesCountErrors,"required":_vm.isRequiredField(_vm.$v.extinguishingBottles.bottlesCount)},model:{value:(_vm.bottlesCount),callback:function ($$v) {_vm.bottlesCount=$$v},expression:"bottlesCount"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.volume,"value":_vm.volume,"suffix":_vm.$t('label-liter'),"label":_vm.$t('volume-label'),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-liter')
                    }),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.extinguishingBottles.volume.$params.minValue.min,
                        maxValue: _vm.$v.extinguishingBottles.volume.$params.maxValue.max
                    }),"error-messages":_vm.volumeErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.volume)},on:{"input":function($event){_vm.volume = $event},"click:append":function($event){return _vm.showFaq('chambersVolume')}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMinPressure,"suffix":_vm.$t('label-bar'),"label":_vm.$t('last-min-pressure-label'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.extinguishingBottles.lastMinPressure.$params.minValue.min,
                        maxValue: _vm.$v.extinguishingBottles.lastMinPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.lastMinPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.lastMinPressure)},on:{"input":function($event){_vm.lastMinPressure = $event},"click:append":function($event){return _vm.showFaq('chambersLastMinPressure')}},model:{value:(_vm.lastMinPressure),callback:function ($$v) {_vm.lastMinPressure=$$v},expression:"lastMinPressure"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMaxPressure,"suffix":_vm.$t('label-bar'),"label":_vm.$t('last-max-pressure-label'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.extinguishingBottles.lastMaxPressure.$params.minValue.min,
                        maxValue: _vm.$v.extinguishingBottles.lastMaxPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.lastMaxPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.lastMaxPressure)},on:{"input":function($event){_vm.lastMaxPressure = $event},"click:append":function($event){return _vm.showFaq('chambersLastMaxPressure')}},model:{value:(_vm.lastMaxPressure),callback:function ($$v) {_vm.lastMaxPressure=$$v},expression:"lastMaxPressure"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMinConcessionTemperature,"value":_vm.lastMinConcessionTemperature,"suffix":_vm.$t('label-celsius'),"label":_vm.$t('last-min-concession-temperature-label'),"hint":_vm.$t('hint', {
                        minValue:
                            _vm.$v.extinguishingBottles.lastMinConcessionTemperature.$params.minValue
                                .min,
                        maxValue:
                            _vm.$v.extinguishingBottles.lastMinConcessionTemperature.$params.maxValue
                                .max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-celsius')
                    }),"error-messages":_vm.lastMinConcessionTemperatureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.lastMinConcessionTemperature)},on:{"input":function($event){_vm.lastMinConcessionTemperature = $event},"click:append":function($event){return _vm.showFaq('chambersLastMinConcessionTemperature')}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.lastMaxConcessionTemperature,"value":_vm.lastMaxConcessionTemperature,"suffix":_vm.$t('label-celsius'),"label":_vm.$t('last-max-concession-temperature-label'),"hint":_vm.$t('hint', {
                        minValue:
                            _vm.$v.extinguishingBottles.lastMaxConcessionTemperature.$params.minValue
                                .min,
                        maxValue:
                            _vm.$v.extinguishingBottles.lastMaxConcessionTemperature.$params.maxValue
                                .max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-celsius')
                    }),"error-messages":_vm.lastMaxConcessionTemperatureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.lastMaxConcessionTemperature)},on:{"input":function($event){_vm.lastMaxConcessionTemperature = $event},"click:append":function($event){return _vm.showFaq('chambersLastMaxConcessionTemperature')}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('WDecimalField',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonlyModus,"options":_vm.fieldsOptions.testPressure,"value":_vm.testPressure,"label":_vm.$t('test-pressure-label'),"suffix":_vm.$t('label-bar'),"hint":_vm.$t('hint', {
                        minValue: _vm.$v.extinguishingBottles.testPressure.$params.minValue.min,
                        maxValue: _vm.$v.extinguishingBottles.testPressure.$params.maxValue.max
                    }),"placeholder":_vm.$t('placeholder', {
                        value: 24.4,
                        unit: _vm.$t('label-bar')
                    }),"error-messages":_vm.testPressureErrors,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.testPressure)},on:{"input":function($event){_vm.testPressure = $event},"click:append":function($event){return _vm.showFaq('chambersTestPressure')}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}})],1),_c('base-autocomplete',{attrs:{"items":_vm.sortedMaterialCodeItems,"label":_vm.$t('material-code-label'),"item-value":"key","error-messages":_vm.materialCodeErrors,"clearable":!_vm.readonlyModus,"append-icon":"mdi-information-outline","required":_vm.isRequiredField(_vm.$v.extinguishingBottles.materialCode),"filter":_vm.customFilter},on:{"click:append":function($event){return _vm.showFaq('chambersMaterialCode')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [(item.value[_vm.$i18n.locale])?_c('span',[_vm._v(" "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.value.de)+" (de) ")])]}},{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[(item.value[_vm.$i18n.locale])?_c('span',[_vm._v(" "+_vm._s(item.value[_vm.$i18n.locale])+" ")]):_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.value.de)+" (de) ")])])]}}]),model:{value:(_vm.materialCode),callback:function ($$v) {_vm.materialCode=$$v},expression:"materialCode"}}),_c('base-date-picker',{attrs:{"label":_vm.$t('last-test-date'),"errorMessages":_vm.lastTestDateErrors,"required":_vm.isRequiredField(_vm.$v.extinguishingBottles.lastTestDate),"inputReadonly":"","type":"date","max":_vm.dateNow},model:{value:(_vm.lastTestDate),callback:function ($$v) {_vm.lastTestDate=$$v},expression:"lastTestDate"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-textarea',{attrs:{"error-messages":_vm.coverageErrors,"label":_vm.$t('coverage-label'),"clearable":!_vm.readonlyModus,"counter":_vm.$v.extinguishingBottles.coverage.$params.maxLength.max,"required":_vm.isRequiredField(_vm.$v.extinguishingBottles.coverage)},model:{value:(_vm.coverage),callback:function ($$v) {_vm.coverage=$$v},expression:"coverage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }