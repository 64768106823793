<i18n src="./content-i18n.yaml"></i18n>
<template>
  <v-container class="px-2" @click="$emit('click:container')">
    <FormExtinguishingBottles
      class="pa-2 pt-4"
      ref="formExtinguishingBottles"
      @form-change="onFormChange"
      @reset="onReset"
      :extinguishingBottles="extinguishingBottles"
      :chamberId="1"
      :readonlyModus="readonlyModus"
    >
    </FormExtinguishingBottles>
  </v-container>
</template>
<script>
import FormExtinguishingBottles from "@/components/tasks/edit/common/forms/form-extinguishing-bottles.vue";
export default {
  components: {
    FormExtinguishingBottles
  },

  props: {
    extinguishingBottles: {
        type: Object,
        default: () => {}
    },
    readonlyModus: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    isValid() {
      return this.$refs.formExtinguishingBottles.isValid();
    },
    onFormChange(formData) {
      console.info('formdata----', formData)
      this.$emit("change", formData);
    },
    onReset() {
        this.$emit('reset');
    },
    scrollToFirstError() {
      this.$refs.formExtinguishingBottles.scrollToFirstError();
    },
    validate() {
      this.$refs.formExtinguishingBottles.validate();
    }
  }
};
</script>
