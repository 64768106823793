<i18n src="./task-summary-with-verification-i18n.yaml"></i18n>
<template>
    <v-list>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step0"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step0')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryBaseData :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step1"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step1')"
                />
                <v-tooltip
                    right
                    v-if="disableCheckboxIfHasOpenDialogs('step1')"
                >
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="warning"> mdi-alert </v-icon>
                    </template>
                    {{ $t("maybe-has-an-open-dialog") }}
                </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
                <SummaryObjectDetails :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step2"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step2')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryEquipmentLocation
                    :task="task"
                    show-copy-btn
                    expandable
                />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step3"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step3')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryChambers
                  v-if="task.objectType === 'PRESSURE_EQUIPMENT'"
                  :task="task"
                  show-copy-btn
                  expandable
                />
                <SummaryExinguishingBottles
                  v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
                  :task="task"
                  show-copy-btn
                  expandable
                />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox v-model="step4" color="success" />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressOperator
                    :task="task"
                    show-copy-btn
                    expandable
                    :disabled="disableCheckboxIfHasOpenDialogs('step4')"
                />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step5"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step5')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressBill :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step6"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step6')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAddressOwner :task="task" show-copy-btn expandable />
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item dense class="pa-0 ma-0">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step7"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step7')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryAttachments
                    :task="task"
                    show-copy-btn
                    expandable
                    show-attachments-icon
                />
            </v-list-item-content>
        </v-list-item>
        <v-divider v-if="!isActivelyHeated"></v-divider>
        <v-list-item dense class="pa-0 ma-0" v-if="!isActivelyHeated">
            <v-list-item-action class="align-checkbox-on-top">
                <v-checkbox
                    v-model="step8"
                    color="success"
                    :disabled="disableCheckboxIfHasOpenDialogs('step8')"
                />
            </v-list-item-action>
            <v-list-item-content>
                <SummaryActivelyHeated
                  v-if="task.objectType === 'PRESSURE_EQUIPMENT'"
                  :task="task"
                  show-copy-btn
                  expandable
                />
                <SummaryInspectionProcedure
                  v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
                  :task="task"
                  show-copy-btn
                  expandable
                />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import Helpers from "@/utils/helpers";
export default {
    name: "TaskSummaryWithVerification",
    props: {
        task: {
            type: Object,
            default: () => {},
            required: true
        },
        reviewVerification: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        SummaryBaseData: () =>
            import("@/components/tasks/common/summary/summary-base-data.vue"),
        SummaryObjectDetails: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-object-details.vue"
            ),
        SummaryEquipmentLocation: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-equipment-location.vue"
            ),
        SummaryChambers: () =>
            import("@/components/tasks/common/summary/registration/summary-chambers.vue"),
        SummaryExinguishingBottles: () =>
            import("@/components/tasks/common/summary/registration/summary-extinguishing-bottles.vue"),
        SummaryAddressOperator: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-operator.vue"
            ),
        SummaryAddressBill: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-bill.vue"
            ),
        SummaryAddressOwner: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-address-owner.vue"
            ),
        SummaryAttachments: () =>
            import("@/components/tasks/common/summary/registration/summary-attachments.vue"),
        SummaryActivelyHeated: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-actively-heated.vue"
            ),
        SummaryInspectionProcedure: () =>
            import(
                "@/components/tasks/common/summary/registration/summary-inspection-procedure.vue"
            )
    },
    data: () => ({}),
    computed: {
        isActivelyHeated() {
            return Helpers.isActivelyHeated(this.task);
        },
        step0: {
            get() {
                return this.reviewVerification?.step0?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step0",
                    value
                });
            }
        },
        step1: {
            get() {
                return this.reviewVerification?.step1?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step1",
                    value
                });
            }
        },
        step2: {
            get() {
                return this.reviewVerification?.step2?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step2",
                    value
                });
            }
        },
        step3: {
            get() {
                return this.reviewVerification?.step3?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step3",
                    value
                });
            }
        },
        step4: {
            get() {
                return this.reviewVerification?.step4?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step4",
                    value
                });
            }
        },
        step5: {
            get() {
                return this.reviewVerification?.step5?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step5",
                    value
                });
            }
        },
        step6: {
            get() {
                return this.reviewVerification?.step6?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step6",
                    value
                });
            }
        },
        step7: {
            get() {
                return this.reviewVerification?.step7?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step7",
                    value
                });
            }
        },
        step8: {
            get() {
                return this.reviewVerification?.step8?.approved;
            },
            set(value) {
                this.$emit("change:review-verification-step", {
                    step: "step8",
                    value
                });
            }
        }
    },
    methods: {
        disableCheckboxIfHasOpenDialogs(step) {
            const reviewVerification = this.reviewVerification[step];
            const hasOpenDialogs = Helpers.checkIfVerificationStepHasAnOpenDialog(reviewVerification);
            return hasOpenDialogs;
        }
    }
};
</script>

<style scoped>
.align-checkbox-on-top {
    align-self: start !important;
}
</style>
