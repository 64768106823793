var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.registration.docId)?_c('v-stepper',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"elevation-0",attrs:{"vertical":"","non-linear":""},on:{"change":_vm.goToStep},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('app-dev-container',{attrs:{"color":"warning","expanded":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('tree-view',{attrs:{"data":Object.assign({}, _vm.registration),"options":{ maxDepth: 0 }}}),_c('v-select',{attrs:{"items":_vm.devStepsItemsList,"label":"Jump Step","solo":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}})]},proxy:true}],null,false,4293265925)}),_c('RegistrationBasicData',{ref:"step0",attrs:{"registration":_vm.registration,"showLock":!_vm.readonlyModus,"disabledLock":_vm.reviewVerification.step0.approved,"disabled":_vm.registrationState >= _vm.TaskStatus.TASK_REGISTRANT_COMPLETED,"readonly":_vm.readonlyModus,"expandCard":_vm.expandBasicDataCard},on:{"field-change":_vm.onBasicDataFieldChange,"icon-key-click":_vm.onRegistrationBasicDataIconKeyClick,"click:btn-lock":function($event){_vm.readonlySteps.step00 = $event},"click:btn-expand":function($event){return _vm.$emit('change:expand-basic-data-card')}},scopedSlots:_vm._u([(
                _vm.registrationState > _vm.TaskStatus.TASK_REGISTRANT_COMPLETED &&
                _vm.registrationState < _vm.TaskStatus.TASK_CONFIRMED
            )?{key:"addons",fn:function(){return [_c('v-container',{staticClass:"px-5",attrs:{"fluid":"","pa-0":"","ma-0":""}},[(_vm.registration.registrantNote)?_c('v-row',[_c('v-col',[_c('base-alert',{attrs:{"type":"info","dense":""}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("registrant-not-title"))+": ")]),_c('div',{domProps:{"innerHTML":_vm._s(
                                    _vm.registration.registrantNote.replace(
                                        /(?:\r\n|\r|\n)/g,
                                        '<br />'
                                    )
                                )}})])],1)],1):_vm._e(),(_vm.showRegistrationVerificationCard)?_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step0,"step":0,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step0.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onUpdateReviewVerificationState('step0', $event)}}}):_vm._e()],1)]},proxy:true}:null],null,true)}),(_vm.dialogDuplicatesCheck && !_vm.readonlyModus)?_c('v-row',[_c('v-col',[_c('DialogDuplicatesCheck',{attrs:{"value":_vm.dialogDuplicatesCheck,"reviewVerification":_vm.reviewVerification,"readonlyModus":_vm.readonlyModus,"registrationData":_vm.registration,"backoffice":_vm.backoffice},on:{"input":function($event){_vm.dialogDuplicatesCheck = $event},"change:review-verification-step":function (item) { return _vm.onUpdateReviewVerificationState(
                            item.step,
                            item.value
                        ); },"save":_vm.onSaveDuplicatesCheck}})],1)],1):_vm._e(),_c('BaseStepperStep',{ref:"step1",attrs:{"step":"1","title":_vm.$t('step-01-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 1,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step01,"reviewVerification":_vm.reviewVerification.step1},on:{"click:btn-lock":function($event){_vm.readonlySteps.step01 = !_vm.readonlySteps.step01}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"1"}},[_c('StepObjectPropertiesContent',{ref:"step01Content",attrs:{"readonlyModus":_vm.readonlySteps.step01,"registration":_vm.registration},on:{"field-change":function($event){return _vm.onFieldChange($event, true)},"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(1)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step1,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step1.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            1,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"btnIcon":"mdi-arrow-down","type":"primary"},on:{"click":_vm.validateStep01}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step2",attrs:{"step":"2","title":_vm.$t('step-02-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 2,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step02,"reviewVerification":_vm.reviewVerification.step2},on:{"click:btn-lock":function($event){_vm.readonlySteps.step02 = !_vm.readonlySteps.step02}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"2"}},[_c('StepPressureDeviceLocationContent',{ref:"step02Content",attrs:{"objectType":_vm.registration.objectType,"readonlyModus":_vm.readonlySteps.step02,"locationAddress":_vm.locationAddress,"locationContactPerson":_vm.locationContactPerson,"customerSpecificDesignation":_vm.customerSpecificDesignation},on:{"reset:equipment-location":function($event){return _vm.onResetEquipmentLocation($event, true)},"reset:address":function($event){return _vm.onResetEquipmentLocationAddress(true)},"reset:contact-person":function($event){return _vm.onResetEquipmentLocationContactPerson(true)},"reset:customer-specific-designation":function($event){return _vm.onResetCustomerSpecificDesignation(true)},"change:equipment-location-field":function($event){return _vm.onMergeRegistrationField($event, true)},"change:customer-specific-designation":function($event){return _vm.onMergeRegistrationField(
                    { field: 'customerSpecificDesignation', value: $event },
                    true
                )},"change:contact-person":function($event){return _vm.onUpdateEquipmentLocationContactPerson($event, true)},"change:address":function($event){return _vm.onUpdateEquipmentLocationAddress($event, true)},"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(2)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step2,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step2.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            2,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(1)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep02}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step3",attrs:{"step":"3","title":_vm.$t('step-03-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 3,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step03,"reviewVerification":_vm.reviewVerification.step3},on:{"click:btn-lock":function($event){_vm.readonlySteps.step03 = !_vm.readonlySteps.step03}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"3"}},[_c('StepExtinguishingBottlesContent',{ref:"step03Content",attrs:{"readonlyModus":_vm.readonlySteps.step03,"extinguishingBottles":_vm.extinguishingBottles},on:{"reset":function($event){return _vm.onResetExtinguishingBottles(true)},"change":function($event){return _vm.onMergeRegistrationField(
                    { field: 'extinguishingBottles', value: $event },
                    true
                )}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step3,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step3.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            3,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(2)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep03}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step4",attrs:{"step":"4","title":_vm.$t('step-04-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 4,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step04,"reviewVerification":_vm.reviewVerification.step4},on:{"click:btn-lock":function($event){_vm.readonlySteps.step04 = !_vm.readonlySteps.step04}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"4"}},[_c('StepAddressOperatorContent',{ref:"step04Content",attrs:{"readonlyModus":_vm.readonlySteps.step04,"value":_vm.operatorAddress,"task":_vm.registration},on:{"clear":_vm.onClearAddress,"change:address-field":function($event){return _vm.onChangeAddressField($event, true)},"clear:address":function($event){return _vm.onClearAddress($event, true)},"change:address":function (address) { return _vm.onMergeRegistrationAddressValuesByFieldName(
                        { field: 'operatorAddress', value: address },
                        true
                    ); },"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(4)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step4,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step4.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            4,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(3)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep04}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step5",attrs:{"step":"5","title":_vm.$t('step-05-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 5,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step05,"reviewVerification":_vm.reviewVerification.step5},on:{"click:btn-lock":function($event){_vm.readonlySteps.step05 = !_vm.readonlySteps.step05}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"5"}},[_c('StepAddressBillContent',{ref:"step05Content",attrs:{"readonlyModus":_vm.readonlySteps.step05,"value":_vm.billingAddress,"task":_vm.registration,"billingAdditionalInfos":_vm.billingAdditionalInfos},on:{"change:address-field":function($event){return _vm.onChangeAddressField($event, true)},"clear":function($event){return _vm.onClearAddress({ field: 'billingAddress' })},"clear:address":function($event){return _vm.onClearAddress($event, true)},"change:address":function (address) { return _vm.onMergeRegistrationAddressValuesByFieldName(
                        { field: 'billingAddress', value: address },
                        true
                    ); },"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(5)},"change:billing-additional-infos-field":function($event){return _vm.onChangeBillingAdditionalInfosField($event, true)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step5,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step5.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            5,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(4)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep05}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step6",attrs:{"step":"6","title":_vm.$t('step-06-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 6,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step06,"reviewVerification":_vm.reviewVerification.step6},on:{"click:btn-lock":function($event){_vm.readonlySteps.step06 = !_vm.readonlySteps.step06}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"6"}},[_c('StepAddressOwnerContent',{ref:"step06Content",attrs:{"readonlyModus":_vm.readonlySteps.step06,"value":_vm.ownerAddress,"task":_vm.registration},on:{"change:address-field":function($event){return _vm.onChangeAddressField($event, true)},"clear":function($event){return _vm.onClearAddress({ field: 'ownerAddress' })},"clear:address":function($event){return _vm.onClearAddress($event, true)},"change:address":function (address) { return _vm.onMergeRegistrationAddressValuesByFieldName(
                        { field: 'ownerAddress', value: address },
                        true
                    ); },"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(6)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step6,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step6.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            6,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(5)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep06}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step7",attrs:{"step":"7","title":_vm.$t('step-07-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 7,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step07,"reviewVerification":_vm.reviewVerification.step7},on:{"click:btn-lock":function($event){_vm.readonlySteps.step07 = !_vm.readonlySteps.step07}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"7","id":"step07Content"}},[_c('StepUploadContent',{ref:"step07Content",attrs:{"readonlyModus":_vm.readonlySteps.step07,"taskDocId":_vm.registration.docId,"attachments":_vm.registration.attachments,"attachmentsStatesRange":[
                _vm.TaskStatus.TASK_REGISTRANT_IN_PROCESS,
                _vm.TaskStatus.TASK_CONFIRMED
            ]},on:{"update":function($event){return _vm.onFieldChange({ field: 'attachments', value: $event })},"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(7)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step7,"step":_vm.step,"registrationData":_vm.registration,"requireAttachments":"","valid":_vm.reviewVerification.step7.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            7,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(6)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep07}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step8",attrs:{"step":"8","title":_vm.$t('step-08-title'),"btnLockShow":!_vm.readonlyModus && _vm.step == 8,"readonlyModus":_vm.readonlyModus,"readonlyModusStep":_vm.readonlySteps.step08,"reviewVerification":_vm.reviewVerification.step8},on:{"click:btn-lock":function($event){_vm.readonlySteps.step08 = !_vm.readonlySteps.step08}}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":"8"}},[_c('StepInspectionProcedureContent',{ref:"step08Content",attrs:{"readonlyModus":_vm.readonlySteps.step08,"registration":_vm.registration,"attachmentsStatesRange":[
                _vm.TaskStatus.TASK_REGISTRANT_CREATED,
                _vm.TaskStatus.TASK_CONFIRMED
            ]},on:{"field-change":function($event){return _vm.onFieldChange($event, true)},"attachments:change":function($event){return _vm.onFieldChange({ field: 'attachments', value: $event })},"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(8)}}}),(_vm.showRegistrationVerificationCard)?_c('v-row',{staticClass:"px-3"},[_c('v-col',[_c('RegistrationVerificationCard',{attrs:{"reviewVerification":_vm.reviewVerification.step8,"step":_vm.step,"registrationData":_vm.registration,"valid":_vm.reviewVerification.step8.approved},on:{"delete":_vm.onDeleteRegistrationDialog,"create":_vm.onCreateRegistrationDialog,"update-review-verification-state":function($event){return _vm.onChangeReviewVerificationStateByStepCount(
                            8,
                            $event
                        )}}})],1)],1):_vm._e(),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(7)}}},[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]),_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-arrow-down"},on:{"click":_vm.validateStep08}},[_vm._v(" "+_vm._s(_vm.$t("btn-next"))+" ")])],1)],1)],1),_c('BaseStepperStep',{ref:"step9",attrs:{"step":8,"title":_vm.$t('step-09-title'),"readonlyModus":true,"readonlyModusStep":true,"complete":""}}),_c('v-stepper-content',{class:_vm.$vuetify.breakpoint.mobile ? 'px-0 mx-3' : null,attrs:{"step":9}},[_c('StepSummaryContent',{ref:"step09Content",attrs:{"registrationData":_vm.registration},on:{"click:container":function($event){return _vm.onClickStepContentInReadonlyMode(9)}}}),_c('app-dev-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" reviewVerificationValid "),_c('br'),_vm._v(" "+_vm._s(_vm.reviewVerificationValid)+" "),_c('br'),_vm._v(" hasOpenDialogs "),_c('br'),_vm._v(" "+_vm._s(_vm.hasOpenDialogs)+" "),_c('br'),_vm._v(" needToAssignToRegistrant "),_c('br'),_vm._v(" "+_vm._s(_vm.needToAssignToRegistrant)+" ")]},proxy:true}],null,false,2247571100)}),_c('v-row',{staticClass:"px-3 pb-3"},[_c('v-col',{staticClass:"text-right"},[_c('base-btn',{attrs:{"type":"secondary","btnIcon":"mdi-arrow-up"},on:{"click":function($event){return _vm.goToStep(8)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-back"))+" ")]):_vm._e()]),(
                        _vm.registration &&
                        (_vm.readonlyModus ||
                            _vm.hasOpenDialogs ||
                            _vm.needToAssignToRegistrant)
                    )?_c('base-btn',{staticClass:"ml-2",attrs:{"type":"close"},on:{"click":_vm.close}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-close"))+" ")]):_vm._e()]):_vm._e(),(
                        _vm.registration &&
                        !_vm.readonlyModus &&
                        _vm.reviewVerification &&
                        _vm.reviewVerificationValid &&
                        !_vm.hasOpenDialogs &&
                        !_vm.needToAssignToRegistrant
                    )?_c('base-btn',{staticClass:"ml-2",attrs:{"type":"save"},on:{"click":_vm.validateStep09}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-complete"))+" ")]):_vm._e()]):_vm._e(),(
                        _vm.registrationState >
                            _vm.TaskStatus.TASK_REGISTRANT_COMPLETED &&
                        (_vm.hasOpenDialogs || _vm.needToAssignToRegistrant)
                    )?_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-account-arrow-right"},on:{"click":_vm.onConfirmAndAssingToRegistrant}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-confirm-and-assing-to-registration"))+" ")]):_vm._e()]):_vm._e(),(_vm.registrationState === _vm.TaskStatus.TASK_CONFIRMED)?_c('base-btn',{staticClass:"ml-2",attrs:{"type":"primary","btnIcon":"mdi-account-arrow-right"},on:{"click":function($event){_vm.dialogSapExport = true}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("btn-export-to-sap"))+" ")]):_vm._e()]):_vm._e()],1)],1)],1),(_vm.dialogCompleteRegistration)?_c('DialogCompleteRegistration',{attrs:{"max-width":"450","icon":"mdi-alert","value":_vm.dialogCompleteRegistration,"title":_vm.$t('complete-registration-title'),"registrationData":_vm.registration,"backoffice":_vm.backoffice},on:{"input":function($event){_vm.dialogCompleteRegistration = $event},"complete":_vm.completeRegistration}}):_vm._e(),_c('DialogSapExport',{attrs:{"task":_vm.registration},on:{"save:success":_vm.clearStoreAndGoToTaskView},model:{value:(_vm.dialogSapExport),callback:function ($$v) {_vm.dialogSapExport=$$v},expression:"dialogSapExport"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }