<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0 @click="$emit('click:container')">
        <app-dev-container>
            <template v-slot:content>
                @overheatingVulnerable:
                <tree-view
                    :data="registration.overheatingVulnerable"
                    :options="{ maxDepth: 0 }"
                ></tree-view>
            </template>
        </app-dev-container>
        <v-form :disabled="disabled" :readonly="readonlyModus">
            <v-row dense class="px-4">
                <v-col>
                    <base-radio-group
                        v-model="inspectionType"
                        :required="isRequiredField($v.inspectionType)"
                        :label="$t('inspection-type-label')"
                        :error-messages="inspectionTypeErrors"
                    >
                        <v-radio :label="$t('inspection-type-specialist-label')" :value="INSPECTION_TYPES.SPECIALIST"></v-radio>
                        <v-radio :label="$t('inspection-type-svti-label')" :value="INSPECTION_TYPES.SVTI"></v-radio>
                    </base-radio-group>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { INSPECTION_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
export default {
    name: "StepInspectionProcedureContent",
    mixins: [validateMixin],
    validations: {
        inspectionType: {
            required
        }
    },

    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        registration: {
            type: Object,
            default: () => {},
            required: true
        }
    },

    data: () => ({
        INSPECTION_TYPES
    }),

    methods: {
        reset() {
            this.$v.$reset();
            this.inspectionType = null;
        },
        isValid() {
            if (this.inspectionType === this.INSPECTION_TYPES.SVTI) return true;
            if (!this.$v.$dirty) return null;
            if (this.$v.$anyError) {
                return false;
            } else {
                return true;
            }
        },
        onFieldChange(field, value) {
            this.$emit("field-change", { field, value });
        }
    },
    computed: {
        inspectionType: {
            get() {
                //if (this.registration) {
                    return this.registration.inspectionType;
                //} else {
                //    return this.INSPECTION_TYPES.SVTI;
                //}
            },
            set(value) {
                this.onFieldChange("inspectionType", value);
            }
        },
        inspectionTypeErrors() {
            const errors = [];
            if (!this.$v.inspectionType.$dirty) {
                return errors;
            }
            if (!this.$v.inspectionType.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    }
};
</script>
