import Vue from "vue";
import axios from "axios";
import NProgress from "nprogress";

const _createAxiosApi = (name, config) => {
    // create axios
    const api = axios.create(config);

    // intercept the request and sart the NProgress
    api.interceptors.request.use((config) => {
        NProgress.start();
        return config;
    });

    // intercept the response and set NProgress to done state
    api.interceptors.response.use(
        (response) => {
            // response data
            NProgress.done();
            return response;
        },
        (error) => {
            // response error
            NProgress.done();
            return Promise.reject(error);
        }
    );

    //register api to be available
    Vue.prototype[`$${name}`] = api;
    return api;
};

const DEFAULT_API_ENDPOINT = "/api";
const DEFAULT_MAIN_API_ENDPOINT = `${DEFAULT_API_ENDPOINT}/main/v1`;

// create axios api interceptor for main api
let axiosMainApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}`
};
const axiosMainApi = _createAxiosApi(
    "axiosMainApi",
    axiosMainApiConfig
);

// create axios api interceptor for account-settings
let axiosAccountSettingsConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/account-settings`
};
const axiosAccountSettings = _createAxiosApi(
    "axiosAccountSettings",
    axiosAccountSettingsConfig
);

// create axios api interceptor for global api
let axiosApiConfig = {
    baseURL: DEFAULT_API_ENDPOINT
};
const axiosApi = _createAxiosApi("axiosApi", axiosApiConfig);

// create axios api interceptor for registration attributes
let axiosAttributesConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/attributes`
};
const axiosAttributes = _createAxiosApi(
    "axiosAttributes",
    axiosAttributesConfig
);

// create axios api interceptor for auth
let axiosAuthConfig = {
    baseURL: "/auth"
};
const axiosAuth = _createAxiosApi("axiosAuth", axiosAuthConfig);

// create axios api interceptor for backoffice
let axiosBackofficeConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/backoffice`
};
const axiosBackoffice = _createAxiosApi(
    "axiosBackoffice",
    axiosBackofficeConfig
);

// create axios api interceptor for backoffice
let axiosBinaryConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/binary`
};
const axiosBinary = _createAxiosApi("axiosBinary", axiosBinaryConfig);

// create axios api interceptor for installators
let axiosInstallatorsConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/installators`
};
const axiosInstallators = _createAxiosApi(
    "axiosInstallators",
    axiosInstallatorsConfig
);

// create axios api interceptor for dialogs
let axiosDialogsConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/dialogs`
};
const axiosDialogs = _createAxiosApi("axiosDialogs", axiosDialogsConfig);

// create axios api interceptor for location
let axiosLocationConfig = {
    baseURL: "/api/locations"
};
const axiosLocation = _createAxiosApi("axiosLocation", axiosLocationConfig);

// create axios api interceptor for email
let axiosMailConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/mail`
};
const axiosMail = _createAxiosApi("axiosMail", axiosMailConfig);

// create axios api interceptor for manufacturers
let axiosManufacturersConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/manufacturers`
};
const axiosManufacturers = _createAxiosApi(
    "axiosManufacturers",
    axiosManufacturersConfig
);

// create axios api interceptor for tasks
let axiosTasksConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/tasks`
};
const axiosTasks = _createAxiosApi("axiosTasks", axiosTasksConfig);

// create axios api interceptor for specialistsCompanies
let axiosSpecialistsCompaniesConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/specialists-companies`
};
const axiosSpecialistsCompanies = _createAxiosApi(
    "axiosSpecialistsCompanies",
    axiosSpecialistsCompaniesConfig
);

// create axios api interceptor for suvaParnerFinder
let axiosSuvaApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/suvaParnerFinder`
};
const axiosSuvaApi = _createAxiosApi("axiosSuvaApi", axiosSuvaApiConfig);

// create axios api interceptor for uid
let axiosUidApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/uid`
};
const axiosUidApi = _createAxiosApi("axiosUidApi", axiosUidApiConfig);

// create axios api interceptor for logs
let axiosLogsApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/logs`
};
const axiosLogsApi = _createAxiosApi("axiosLogsApi", axiosLogsApiConfig);

// create axios api interceptor for history
let axiosHistoryApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/history`
};
const axiosHistoryApi = _createAxiosApi(
    "axiosHistoryApi",
    axiosHistoryApiConfig
);

// create axios api interceptor for sap
let axiosSapApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/sap`
};
const axiosSapApi = _createAxiosApi("axiosSapApi", axiosSapApiConfig);

// create axios api interceptor for insurer
let axiosInsurerApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/insurer`
};
const axiosInsurerApi = _createAxiosApi("axiosInsurerApi", axiosInsurerApiConfig);

// create axios api interceptor for db_config
let axiosDbConfigApiConfig = {
    baseURL: `${DEFAULT_MAIN_API_ENDPOINT}/config`
};
const axiosDbConfigApi = _createAxiosApi("axiosDbConfigApi", axiosDbConfigApiConfig);

export {
    axiosInstallators,
    axiosMainApi,
    axiosAuth,
    axiosApi,
    axiosTasks,
    axiosBinary,
    axiosManufacturers,
    axiosAttributes,
    axiosUidApi,
    axiosBackoffice,
    axiosLocation,
    axiosAccountSettings,
    axiosMail,
    axiosSpecialistsCompanies,
    axiosDialogs,
    axiosSuvaApi,
    axiosLogsApi,
    axiosHistoryApi,
    axiosSapApi,
    axiosInsurerApi,
    axiosDbConfigApi
};
