export class ObjectPropertiesPressureEquipment {
    constructor() {
        this.ambulantObject = null;
        this.quickRelease = null;
        this.loadChangeAssessmentNecessary = null;
        this.loadChange = null;
        this.loadChangeMax = null;
        this.loadChangeYear = null;
        this.loadChangeDuration = null;
        this.overheatingVulnerable = null;
        this.coolingMaterialPropertiesChange = null;
        this.autonomousDevice = null;
        this.bob = null;
    }
}

export class ObjectPropertiesFireBottlesEquipment {
    constructor() {
        this.reason = null;
        this.tpedStandard = null;
    }
}
