<i18n src="./view-i18n.yaml"></i18n>
<template>
    <div>
        <base-header>
            <v-icon>mdi-office-building</v-icon>
            {{ $t("title") }}
        </base-header>
        <v-row dense>
            <v-col cols="12">
                <base-subheader>{{ $t("group-title-tools") }}</base-subheader>
            </v-col>
            <v-col cols="12">
                <base-btn color="info" @click="reindexAllInstallators">
                    <v-icon left>mdi-database-search</v-icon>
                    {{ $t("installators-reindex") }}
                </base-btn>
            </v-col>
        </v-row>
        <v-row dense class="pt-10">
            <v-col cols="12">
                <base-subheader>{{
                    $t("group-title-data-management")
                }}</base-subheader>
            </v-col>
            <v-col cols="12">
                <InstallatorsTable />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import InstallatorsTable from "./table.vue";
export default {
    components: {
        InstallatorsTable
    },
    methods: {
        reindexAllInstallators() {
            this.loader().show(this.$t("msg-reindex-all"));
            this.$store
                .dispatch("installators/reindexAll")
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("msg-reindex-all-success"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("msg-reindex-all-error"));
                    console.log(error);
                    this.loader().hide();
                });
        }
    }
};
</script>
