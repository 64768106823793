export class Base {
    constructor() {
        this.docId = null;
        this.referenceId = null;
        this.role = null;
        this.objectType = null;
        this.process = null
        this.factoryNumber = null;
        this.assetNumber = null;
        this.constructionYear = null;
        this.registrantNote = null;
    }
}
