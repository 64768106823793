<i18n src="@/lang/defaults-i18n.yaml"></i18n>
<i18n src="./content-object-details-fire-bottles-equipment-i18n.yaml"></i18n>
<template>
    <v-container fluid py-0 my-0>
        <v-row dense>
            <v-col cols="12" class="mb-3">
                <!-- Objektdetails erfassen -->
                <span class="subtitle-1">
                    {{ $t("object-details-info") }}
                </span>
            </v-col>
            <v-col cols="12">
                <WInstallatorSearch
                    ref="installatorSearch"
                    :value="computedInstallator"
                    @change="computedInstallator = $event"
                    :label="$t('installator-label')"
                    :placeholder="$t('installator-placeholder')"
                    :error-messages="installatorErrors"
                    :required="isRequiredField($v.installator)"
                >
                </WInstallatorSearch>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="8">
                <base-text-field
                    v-model="computedAssetNumber"
                    :error-messages="assetNumberErrors"
                    :counter="$v.assetNumber.$params.maxLength.max"
                    :label="$t('asset-number-label')"
                    :placeholder="$t('asset-number-placeholder')"
                    :required="isRequiredField($v.assetNumber)"
                ></base-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <base-select
                    v-model="computedConstructionYear"
                    :items="constructionYearItems"
                    :error-messages="constructionYearErrors"
                    :label="$t('construction-year-label')"
                    :placeholder="$t('construction-year-placeholder')"
                    :required="isRequiredField($v.constructionYear)"
                >
                    <template v-slot:item="{ item }">
                        <span v-if="constructionYearItems.indexOf(item) === 0"
                            >{{ item }} ({{ $t("this-year") }})</span
                        >
                        <span
                            v-else-if="
                                constructionYearItems.indexOf(item) === 1
                            "
                            >{{ item }} ({{ $t("last-year") }})</span
                        >
                        <span
                            v-else-if="
                                constructionYearItems.indexOf(item) === 2
                            "
                            >{{ item }} ({{ $t("two-years-ago") }})</span
                        >
                        <span v-else>{{ item }}</span>
                    </template>
                </base-select>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col class="py-0">
                <base-select
                    v-if="
                        computedConstructionYear &&
                        computedConstructionYear < new Date().getFullYear()
                    "
                    v-model="computedInOperationYear"
                    :items="inOperationYearItems"
                    :error-messages="inOperationYearErrors"
                    :label="$t('in-operation-year-label')"
                    :required="isRequiredField($v.inOperationYear)"
                    clearable
                >
                </base-select>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <base-textarea
                    v-model="computedIntendedUse"
                    :label="$t('intended-use-label')"
                    :error-messages="intendedUseErrors"
                    :counter="$v.intendedUse.$params.maxLength.max"
                    :required="isRequiredField($v.intendedUse)"
                    clearable
                    no-resize
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { PROCESS_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
import {
    required,
    maxLength,
    minLength,
    requiredIf
} from "vuelidate/lib/validators";
import WInstallatorSearch from "@/components/fields/WInstallatorSearch.vue";
export default {
    name: "ContentObjectDetails",
    mixins: [validateMixin],
    components: {
        WInstallatorSearch
    },
    props: {
        installator: {
            type: Object
        },
        assetNumber: {
            type: String
        },
        constructionYear: {
            type: [String, Number]
        },
        inOperationYear: {
            type: [String, Number]
        },
        intendedUse: {
            type: String
        },
        objectType: {
            type: String
        }
    },
    validations: {
        installator: { required },
        assetNumber: {
            maxLength: maxLength(30),
            required
        },
        constructionYear: { required },
        intendedUse: {
            required,
            maxLength: maxLength(200),
            minLength: minLength(2)
        },
        inOperationYear: {
            required: requiredIf(function () {
                if (this.computedConstructionYear < new Date().getFullYear()) {
                    return true;
                }
                return false;
            })
        }
    },
    data: () => ({
        process: PROCESS_TYPES.REGISTRATION
    }),
    methods: {
        onFieldChange(field, value) {
            this.$emit("value:change", { field, value });
        }
    },
    computed: {
        computedInstallator: {
            get() {
                return this.installator;
            },
            set(value) {
                this.onFieldChange("installator", value);
            }
        },
        computedAssetNumber: {
            get() {
                return this.assetNumber;
            },
            set(value) {
                this.onFieldChange("assetNumber", value);
            }
        },
        computedConstructionYear: {
            get() {
                return this.constructionYear;
            },
            set(value) {
                this.onFieldChange("constructionYear", value);
            }
        },
        computedIntendedUse: {
            get() {
                return this.intendedUse;
            },
            set(value) {
                this.onFieldChange("intendedUse", value);
            }
        },
        computedInOperationYear: {
            get() {
                return this.inOperationYear;
            },
            set(value) {
                this.onFieldChange("inOperationYear", value);
            }
        },
        constructionYearItems() {
            let currentYear = new Date().getFullYear();
            let years = [];
            let startYear = currentYear - 150;
            for (var i = startYear; i <= currentYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        inOperationYearItems() {
            let endYear = new Date().getFullYear() + 2;
            let minYear = this.constructionYear;
            let years = [];
            let startYear = minYear;
            for (var i = startYear; i <= endYear; i++) {
                years.push(startYear++);
            }
            return years.reverse();
        },
        installatorErrors() {
            const errors = [];
            if (!this.$v.installator.$dirty) {
                return errors;
            }
            if (!this.$v.installator.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        assetNumberErrors() {
            const errors = [];
            if (!this.$v.assetNumber.$dirty) {
                return errors;
            }
            if (!this.$v.assetNumber.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.assetNumber.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.assetNumber.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },

        constructionYearErrors() {
            const errors = [];
            if (!this.$v.constructionYear.$dirty) {
                return errors;
            }
            if (!this.$v.constructionYear.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        intendedUseErrors() {
            const errors = [];
            if (!this.$v.intendedUse.$dirty) {
                return errors;
            }
            if (!this.$v.intendedUse.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.intendedUse.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.intendedUse.$params.maxLength.max
                    })
                );
            }
            if (!this.$v.intendedUse.minLength) {
                errors.push(
                    this.$t("field-min-length", {
                        minLength: this.$v.intendedUse.$params.minLength.min
                    })
                );
            }
            return errors;
        },
        inOperationYearErrors() {
            const errors = [];
            if (!this.$v.inOperationYear.$dirty) {
                return errors;
            }
            if (!this.$v.inOperationYear.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    },
    watch: {
        constructionYearMenu(val) {
            val &&
                this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
        }
    }
};
</script>
