export * from './BaseRegistration';
export * from './ObjectProperties';
export * from './EquipmentLocation';
export * from './ExtinguishingBottles';
export * from './BaseAddress';
export * from './LocationAddress';
export * from './Communication';
export * from './ContactPerson';
export * from './CustomerSpecificDesignation';
export * from './CompanyAddress';
export * from './PrivateAddress';
export * from './Address';
export * from './SuvaPartnerFinder';
export * from './BaseDeregistration';
export * from './BaseBackoffice';
export * from './BackofficeFields';
export * from './BillingAdditionalInfos';
