<i18n       src="@/lang/defaults-i18n.yaml"></i18n>
<i18n       src="./content-i18n.yaml"></i18n>
<template>
    <v-container
        v-if="task"
        fluid
        class="pa-0 ma-0"
        @click="$emit('click:container')"
    >
        <app-dev-container>
            <template v-slot:content>
                <tree-view
                    :data="Object.assign({}, task)"
                    :options="{ maxDepth: 0, modifiable: true }"
                ></tree-view>
            </template>
        </app-dev-container>
        <v-container fluid class="pa-0 ma-0">
            <TaskSummary :task="task" />
        </v-container>
    </v-container>
</template>
<script>
import {
  RegistrationPressureEquipment,
  RegistrationFireBottlesEquipment,
} from "@/models";
import Helpers from "@/utils/helpers";
export default {
    name: "StepSummaryContent",

    components: {
        TaskSummary: () =>
            import("@/components/tasks/common/summary/task-summary.vue")
    },

    props: {
        registrationData: {
            type: Object,
            default: () => {}
        }
    },

    data: () => {
        const d = {};
        d.task = new RegistrationPressureEquipment();
        d.task = new RegistrationFireBottlesEquipment();
        return d;
    },

    computed: {
        formattedEquipmentLocationAddress() {
            return Helpers.getFormattedEquipmentLocationAddressOrNull(
                this.task.equipmentLocation
            );
        },
        summaryData() {
            let filterBy = ["factoryNumber", "constructionYear"];
            let data = this.task;
            const filtered = Object.keys(data)
                .filter((key) => filterBy.includes(key))
                .reduce((obj, key) => {
                    obj[key] = data[key];
                    return obj;
                }, {});
            filtered.filesCount = 10;
            filtered.chambersCount = 10;
            return filtered;
        },

        totalChambersVolume() {
            let volume = 0.0;
            let chambers = this.task.chambers || [];
            if (chambers.length > 0) {
                chambers.forEach((value) => {
                    volume = volume + parseFloat(value.volume);
                });
                return parseFloat(volume).toFixed(2);
            } else {
                return 0.0;
            }
        }
    },

    watch: {
        registrationData: {
            handler: function (newVal) {
                // watch it
                if (newVal) {
                    this.task = newVal;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
