<i18n src="./summary-inspection-procedure-i18n.yaml"></i18n>
<template>
    <BaseListItem
        v-if="task.inspectionType"
        :title="$t('title')"
        :expandable="expandable"
    >
        <template v-slot:content>
            <ul class="text-subtitle-2 primary--text">
                <li v-if="task.inspectionType === INSPECTION_TYPES.SPECIALIST">
                    {{ $t("inspection-type-specialist") }}
                </li>
                <li v-if="task.inspectionType === INSPECTION_TYPES.SVTI">
                    {{ $t("inspection-type-svti") }}
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { INSPECTION_TYPES } from "@/data/globals.js";
import mixin from "../mixin";
export default {
    name: "SummaryInspectionProcedure",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    data: () => ({
        INSPECTION_TYPES
    })
};
</script>
