<i18n src="./table-i18n.yaml"></i18n>
<template>
    <div>
        <GlobalTable
            :items="installators"
            :options.sync="tableOptions"
            :headers="headers"
            :actions="filteredActions"
            :funcShowAction="showAction"
            :search="search"
            :btnAddText="$t('btn-add-company')"
            :isLoading="isGlobalLoading"
            :loading="isLoading"
            :server-items-length="totalCompaniesCount"
            :footer-props="footerProps"
            multi-sort
            @click:action="onActionClick"
            @click:add="onAdd"
            @click:reload="fetchAllCompanies"
            @input:search="search = $event"
            @options:change="
                (options) => {
                    Object.assign(tableOptions, options);
                }
            "
            @page-count="(count) => (totalPagesCount = count)"
            @update:page="fetchAllCompanies"
            @update:items-per-page="fetchAllCompanies"
        >
            <template v-slot:item.used="{ item }">
                <div class="pt-2">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <!-- item is deleted  -->
                            <v-icon
                                v-on="on"
                                v-if="item.deletedState > 0"
                                color="red"
                                >mdi-delete</v-icon
                            >
                            <!-- item is already used  -->
                            <v-icon
                                v-on="on"
                                v-else-if="item.used"
                                color="green"
                                >mdi-check-circle</v-icon
                            >
                            <!-- item is not used already -->
                            <v-icon v-on="on" v-else color="grey"
                                >mdi-close-circle</v-icon
                            >
                        </template>
                        <span>
                            <div v-if="item.deletedState > 0">
                                {{ $t("status-deleted") }}
                            </div>
                            <div v-else-if="item.used">
                                {{ $t("status-used") }}
                            </div>
                            <div v-else>
                                {{ $t("status-not-used") }}
                            </div>
                        </span>
                    </v-tooltip>
                    <v-tooltip
                        right
                        v-if="
                            item.deletedState == 0 &&
                            !isValidAddress(item.address)
                        "
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="warning">mdi-alert</v-icon>
                        </template>
                        <span>
                            <div>
                                {{ $t("status-address-invalid") }}
                            </div>
                        </span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.country="{ item }">
                <div class="pt-3" v-if="item.country">
                    <country-flag :country="item.country" />
                </div>
            </template>
        </GlobalTable>
        <DialogAddress
            v-if="dialogAddress"
            :value="dialogAddress"
            @input="dialogAddress = $event"
            :requiredFields="formAddressRequiredFields"
            :title="computedTitle"
            :address="address"
            @save="onSaveAddress"
            @address:input="
                (newAddress) => {
                    address = newAddress;
                }
            "
            @address:reset="onResetAddress"
        />
        <DialogReportNewAddress
            v-if="dialogReportNewAddress && selectedInstallator"
            v-model="dialogReportNewAddress"
            :address="getAddressFormattedForReport(selectedInstallator)"
            :referenceId="selectedInstallator.docId"
            @complete="dialogReportNewAddress = false;"
        >
        </DialogReportNewAddress>
    </div>
</template>
<script>
import GlobalTable from "@/components/general/table.vue";
import { mapState, mapGetters } from "vuex";
import DialogAddress from "@/components/general/dialog-address.vue";
import { CompanyAddress } from "@/models/tasks";
import _ from "lodash";
import Helpers from "@/utils/helpers";
import { ADDRESS_TYPES } from "@/data/globals.js";

const ActionTypes = {
    EDIT: "EDIT",
    DISABLE: "DISABLE",
    ENABLE: "ENABLE",
    DELETE: "DELETE",
    REPORT_NEW_ADDRESS: "REPORT_NEW_ADDRESS",
    RESTORE_FROM_TRASH: "RESTORE_FROM_TRASH",
    VIEW_HISTORY: "VIEW_HISTORY"
};

export default {
    name: "SpecialistsCompaniesTable",
    components: {
        GlobalTable,
        DialogAddress,
        DialogReportNewAddress: () =>
            import(
                "@/components/tasks/edit/backoffice/components/dialog-report-new-address.vue"
            )
    },
    data: () => ({
        tableOptions: {
            itemsPerPage: 5
        },
        footerProps: {},
        actions: [],
        search: "",
        dialogAddress: false,
        formAddressRequiredFields: ["companyName", "country"],
        address: {},
        editedIndex: -1,
        totalPagesCount: 0,
        isLoading: false,
        searchMinLength: 2,
        dialogReportNewAddress: false,
        selectedInstallator: {}
    }),
    computed: {
        ...mapState("installators", {
            installators: "companies",
            totalCompaniesCount: "totalCompaniesCount"
        }),
        ...mapState("settings", {
            isGlobalLoading: "showLoader"
        }),
        ...mapGetters({
            developerMode: "settings/developerMode",
            isUserAdmin: "auth/isUserAdmin"
        }),
        computedTitle() {
            if (this.address.docId) {
                return this.$t("dialog-company-title-edit");
            } else {
                return this.$t("dialog-company-title-new");
            }
        },
        headers() {
            return [
                {
                    text: this.$t("header-status"),
                    value: "used",
                    sortable: false,
                    align: "left",
                    isMobile: true
                },
                {
                    text: this.$t("header-name"),
                    align: "top",
                    sortable: false,
                    value: "name",
                    isMobile: true
                },
                {
                    text: this.$t("header-department"),
                    align: "top",
                    sortable: false,
                    value: "department",
                    isMobile: true
                },
                {
                    text: this.$t("header-country"),
                    value: "country",
                    sortable: false,
                    align: "left",
                    isMobile: true
                }
            ];
        },
        actionsItems() {
            return [
                {
                    title: this.$t("action-edit"),
                    icon: "mdi-pencil",
                    action: ActionTypes.EDIT
                },
                {
                    title: this.$t("action-delete"),
                    icon: "mdi-delete",
                    action: ActionTypes.DELETE
                },
                {
                    title: this.$t("action-report-new-address"),
                    icon: "mdi-email-send",
                    action: ActionTypes.REPORT_NEW_ADDRESS
                },
                {
                    title: this.$t("action-restore-from-trash"),
                    icon: "mdi-delete-restore",
                    action: ActionTypes.RESTORE_FROM_TRASH
                },
                {
                    title: this.$t("action-view-history"),
                    icon: "mdi-history",
                    action: ActionTypes.VIEW_HISTORY,
                    isAdmin: true
                }
            ];
        },
        filteredActions() {
            return this.actionsItems.filter((action) => {
                let show = true;
                if (action.isDevel && !this.developerMode) {
                    show = false;
                }
                if (action.isAdmin && !this.isUserAdmin) {
                    show = false;
                }
                return show;
            });
        }
    },
    methods: {
        onActionClick({ action, item }) {
            this.selectedInstallator = item;
            switch (action) {
                case ActionTypes.EDIT:
                    this.onEdit(item);
                    break;
                case ActionTypes.DISABLE:
                    this.onDisable(item);
                    break;
                case ActionTypes.ENABLE:
                    this.onEnable(item);
                    break;
                case ActionTypes.DELETE:
                    this.onDelete(item);
                    break;
                case ActionTypes.REPORT_NEW_ADDRESS:
                    this.onReportNewAddress(item);
                    break;
                case ActionTypes.RESTORE_FROM_TRASH:
                    this.onRestoreFromTrash(item);
                    break;
                case ActionTypes.VIEW_HISTORY:
                    this.onViewHistory(item);
                    break;
                default:
                    this.$toast.error("Action not found");
            }
        },
        onReportNewAddress() {
            this.dialogReportNewAddress = true;
        },
        onViewHistory(installator) {
            this.$root.$historyModal.show(installator.docId);
        },
        fetchAllCompanies() {
            this.loader().show(this.$t("loading-msg-get-data"));
            const limit = this.tableOptions.itemsPerPage;
            const page = this.tableOptions.page;
            const start = (page - 1) * limit;
            const params = {
                limit: limit,
                start: isNaN(start) ? 0 : start
            };
            this.$store
                .dispatch("installators/fetchAllCompanies", params)
                .then(() => {
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loader().hide();
                });
        },
        showAction(item, actionItem) {
            const itemDeleted = item.deletedState > 0;
            const action = actionItem.action;
            if (action === ActionTypes.RESTORE_FROM_TRASH) {
                if (itemDeleted) {
                    return true;
                }
                return false;
            }
            if (action === ActionTypes.DELETE || action === ActionTypes.EDIT) {
                if (itemDeleted) {
                    return false;
                }
                return true;
            }
            if (action === ActionTypes.REPORT_NEW_ADDRESS) {
                if (this.isValidAddress(item.address)) {
                    return true;
                }
                return false;
            }
            return true;
        },
        onDelete(item) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-delete-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-delete-msg", {
                    companyName: item.name
                }),
                type: "reason"
            };
            this.$root.$msgBox.open(msgOptions).then(({ action, reason }) => {
                if (action === "agree" && reason) {
                    this.loader().show();
                    let data = {};
                    data.docId = item.docId;
                    data.reason = reason;
                    this.$store
                        .dispatch("installators/deleteCompany", data)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("on-update-msg-success")
                                );
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t("on-update-msg-error"));
                            console.log(error);
                            this.loader().hide();
                        });
                }
            });
        },
        onAdd() {
            this.onResetAddress();
            this.dialogAddress = true;
        },
        onEdit(item) {
            const docId = item.docId || item.id;
            if (!docId) return;
            this.$store
                .dispatch("installators/getCompany", docId)
                .then((response) => {
                    if (response.status === 200) {
                        const resData = response.data;
                        const companyData = resData.data;
                        this.address = Object.assign(
                            new CompanyAddress(),
                            companyData.address
                        );
                        this.address.companyName = companyData.name;
                        this.address.department = companyData.department;
                        this.address.docId = companyData.docId;
                        this.dialogAddress = true;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("on-update-msg-error"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        onRestoreFromTrash(item) {
            const docId = item.docId || item.id;
            if (!docId) return;
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-on-delete-title", {
                        companyName: item.name
                    }),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-on-delete-msg", {
                    companyName: item.name
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.loader().show();
                    this.$store
                        .dispatch("installators/restoreFromTrash", docId)
                        .then((response) => {
                            if (response.status === 200) {
                                this.$toast.success(
                                    this.$t("on-update-msg-success")
                                );
                            }
                            this.loader().hide();
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t("on-update-msg-error"));
                            console.log(error);
                            this.loader().hide();
                        });
                }
            });
        },
        dispatchUpdateCompany(company) {
            if (!company) return;
            this.$store
                .dispatch("installators/updateCompany", company)
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t("on-update-msg-success"));
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    this.$toast.error(this.$t("on-update-msg-error"));
                    console.log(error);
                    this.loader().hide();
                });
        },
        onSaveAddress(address) {
            if (!address) return;
            let company = {};
            company.address = address;
            company.name = address.companyName;
            company.department = address.department;
            if (this.address.docId) {
                company.docId = this.address.docId;
                this.$store
                    .dispatch("installators/updateCompany", company)
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t("on-update-msg-success", {
                                    company: company.name
                                })
                            );
                        }
                        this.dialogAddress = false;
                        this.onResetAddress();
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        const response = error.response;
                        const resData = response.data;
                        const errorCode = resData.code || 0;
                        switch (errorCode) {
                            case 1: {
                                const companyData = resData.data;
                                this.$toast.error(
                                    this.$t("error-msg-already-exists", {
                                        company: companyData.name
                                    })
                                );
                                break;
                            }
                            default: {
                                this.$toast.error(this.$t("error-msg"));
                                break;
                            }
                        }
                        this.loader().hide();
                    });
            } else {
                this.$store
                    .dispatch("installators/addCompany", company)
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t("on-add-msg-success", {
                                    company: company.name
                                })
                            );
                        }
                        this.dialogAddress = false;
                        this.onResetAddress();
                        this.loader().hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        const response = error.response;
                        const resData = response.data;
                        const errorCode = resData.code || 0;
                        switch (errorCode) {
                            case 1: {
                                const companyData = resData.data;
                                this.$toast.error(
                                    this.$t("error-msg-already-exists", {
                                        company: companyData.name
                                    })
                                );
                                break;
                            }
                            default: {
                                this.$toast.error(this.$t("error-msg"));
                                break;
                            }
                        }
                        this.loader().hide();
                    });
            }
        },
        onResetAddress() {
            this.address = new CompanyAddress();
        },
        isValidAddress(address) {
            return Helpers.isValidAddress(address);
        },
        getAddressFormattedForReport(installator) {
            if (!installator.address) return {};
            let address = { ...installator.address };
            address.companyName = installator?.name;
            address.department = installator?.department;
            address.addressType = ADDRESS_TYPES.COMPANY;
            return address;
        }
    },
    created() {
        this.fetchData = _.debounce(function (searchValue) {
            if (!searchValue || searchValue.length < this.searchMinLength)
                return;
            this.isLoading = true;
            let params = {};
            params.query = searchValue;
            params.start = 0;
            params.limit = this.tableOptions.itemsPerPage;

            this.$store
                .dispatch("installators/searchCompany", params)
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data.data;
                        const companies = resData.items;
                        this.$store.commit(
                            "installators/setCompanies",
                            companies
                        );
                        this.$store.commit(
                            "installators/setTotalCompaniesCount",
                            companies.total
                        );
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.isLoading = false;
                });
        }, 500);
    },
    mounted() {
        this.fetchAllCompanies();
    },
    watch: {
        totalCompaniesCount: {
            handler: function (companiesLength) {
                if (companiesLength > 5) {
                    this.tableOptions.itemsPerPage = 10;
                }
                if (companiesLength > 10) {
                    this.tableOptions.itemsPerPage = 15;
                }
                let itemsPerPageOptions = [];

                if (_.inRange(companiesLength, 0, 5)) {
                    itemsPerPageOptions.push(5);
                } else if (_.inRange(companiesLength, 5, 10)) {
                    itemsPerPageOptions.push(5);
                    itemsPerPageOptions.push(10);
                } else if (_.inRange(companiesLength, 10, 15)) {
                    itemsPerPageOptions.push(5);
                    itemsPerPageOptions.push(10);
                    itemsPerPageOptions.push(15);
                } else {
                    itemsPerPageOptions.push(5);
                }
                itemsPerPageOptions.push(-1);
                this.footerProps = {
                    "items-per-page-options": itemsPerPageOptions
                };
            },
            immediate: true
        },
        search(val) {
            if (val || val.length > this.searchMinLength) {
                let valTrim = val.trim();
                this.fetchData(valTrim);
            }
        }
    }
};
</script>
