import { aggregation } from "@/utils/aggregation";
import {
    BaseRegistrationPressureEquipment,
    BaseRegistrationFireBottlesEquipment,
    ExtinguishingBottles,
    ObjectPropertiesPressureEquipment,
    ObjectPropertiesFireBottlesEquipment,
    EquipmentLocation,
    Address,
    CustomerSpecificDesignation,
    BillingAdditionalInfos
} from "./tasks";

export class RegistrationPressureEquipment extends aggregation(
    BaseRegistrationPressureEquipment,
    ObjectPropertiesPressureEquipment
) {
    constructor() {
        super();
        this.customerSpecificDesignation = new CustomerSpecificDesignation();
        this.equipmentLocation = new EquipmentLocation();
        this.chambers = [];
        this.operatorAddress = new Address();
        this.billingAddress = new Address();
        this.ownerAddress = new Address();
        this.inspectionType = "SVTI";
        this.specialistCompany = {};
        this.scrollPosition = null;
        this.referenceId = null;
        this.billingAdditionalInfos = new BillingAdditionalInfos();
    }
}

export class RegistrationFireBottlesEquipment extends aggregation(
    BaseRegistrationFireBottlesEquipment,
    ObjectPropertiesFireBottlesEquipment
) {
    constructor() {
        super();
        this.customerSpecificDesignation = new CustomerSpecificDesignation();
        this.equipmentLocation = new EquipmentLocation();
        this.extinguishingBottles = new ExtinguishingBottles();
        this.operatorAddress = new Address();
        this.billingAddress = new Address();
        this.ownerAddress = new Address();
        this.inspectionType = "SVTI";
        this.scrollPosition = null;
        this.referenceId = null;
    }
}
