<i18n src="./form-equipment-details-pressure-equipment-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
                @Validation:
                <tree-view :data="$v" :options="{ maxDepth: 0 }"></tree-view>
            </template>
        </app-dev-container>

        <StepContentTitle class="pa-0 ma-0" :title="$t('questions-title')" />
        <v-container fluid>
            <v-row>
                <!-- ambulantObject -->
                <base-radio-group
                    v-model="ambulantObject"
                    :required="isRequiredField($v.ambulantObject)"
                    :label="$t('ambulant-object-label')"
                    :error-messages="ambulantObjectErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('ambulantObject')"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>
            <v-row v-if="ambulantObject != null">
                <!-- quickRelease -->
                <base-radio-group
                    v-model="quickRelease"
                    :required="isRequiredField($v.quickRelease)"
                    :label="$t('quick-release-label')"
                    :error-messages="quickReleaseErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('quickRelease')"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>

            <v-row v-if="quickRelease != null">
                <!-- Gibt es einen Betreibernachweis für Lastwechsel   -->
                <base-radio-group
                    v-model="loadChangeAssessmentNecessary"
                    :required="
                        isRequiredField($v.loadChangeAssessmentNecessary)
                    "
                    :label="$t('load-change-assessment-necessary-label')"
                    :error-messages="loadChangeAssessmentNecessaryErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('loadChangeAssessmentNecessary')"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>
            <v-container
                v-if="loadChangeAssessmentNecessary"
                fill-height
                fluid
                no-gutters
                style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
                class="pa-0 ma-0"
            >
                <v-row
                    v-if="loadChangeAssessmentNecessary === true"
                    class="ml-3 py-0"
                >
                    <!-- Was steht im Betreibernachweis des Lieferanten: "maximal zulässige Anzahl"/"die Gesamtnutzungsdauer" -->
                    <base-radio-group
                        v-model="loadChange"
                        :required="isRequiredField($v.loadChange)"
                        :label="$t('load-change-label')"
                        :error-messages="loadChangeErrors"
                    >
                        <v-radio
                            :label="$t('load-change-max-allowed-quantity')"
                            :value="
                                REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                            "
                        ></v-radio>
                        <v-radio
                            :label="$t('load-change-total-usage-duration')"
                            :value="
                                REGISTRATION_LOAD_CHANGE_TYPES.TOTAL_USAGE_DURATION
                            "
                        ></v-radio>
                    </base-radio-group>
                </v-row>
                <v-row
                    class="pl-3"
                    v-if="
                        loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                    "
                >
                    <v-col cols="12" md="6">
                        <!-- QUESTION: "Wie viele Lastwechsel sind gemäss Betreibernachweis maximal zulässig" > INT "Lastwechsel" -->
                        <WNumericField
                            :readonly="readonlyModus"
                            v-model="loadChangeMax"
                            :disabled="disabled"
                            :minValue="
                                $v.loadChangeMax.$params.minimumValue.min
                            "
                            :maxValue="$v.loadChangeMax.$params.maxValue.max"
                            :label="$t('load-change-max-label')"
                            :hint="$t('load-change-max-hint')"
                            :error-messages="loadChangeMaxErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('loadChangeMax')"
                            :required="isRequiredField($v.loadChangeMax)"
                        >
                        </WNumericField>
                    </v-col>
                    <v-col cols="12" md="6">
                        <!-- QUESTION: "Wie viele Lastwechsel fahren Sie mit dem Druckgerät PRO JAHR?" > INT "Lastwechsel" -->
                        <WNumericField
                            :readonly="readonlyModus"
                            :disabled="disabled"
                            filled
                            v-model="loadChangeYear"
                            :minValue="
                                $v.loadChangeYear.$params.minimumValue.min
                            "
                            :maxValue="$v.loadChangeYear.$params.maxValue.max"
                            :label="$t('load-change-year-label')"
                            :hint="$t('load-change-year-hint')"
                            :error-messages="loadChangeYearErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('loadChangeYear')"
                            :required="isRequiredField($v.loadChangeYear)"
                        >
                        </WNumericField>
                    </v-col>
                </v-row>
                <v-row
                    v-if="
                        loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.TOTAL_USAGE_DURATION
                    "
                    class="pl-3"
                >
                    <v-col>
                        <!-- QUESTION: "Wie hoch ist die Gesamtnutzungsdauer gemäss Betreibernachweis" > INT "Stunden" -->
                        <WNumericField
                            :readonly="readonlyModus"
                            :disabled="disabled"
                            filled
                            v-model="loadChangeDuration"
                            :minValue="
                                $v.loadChangeDuration.$params.minimumValue.min
                            "
                            :maxValue="
                                $v.loadChangeDuration.$params.maxValue.max
                            "
                            :label="$t('load-change-duration-label')"
                            :hint="$t('load-change-duration-hint')"
                            :error-messages="loadChangeDurationErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('loadChangeDuration')"
                            :required="isRequiredField($v.loadChangeDuration)"
                        >
                        </WNumericField>
                    </v-col>
                </v-row>
            </v-container>
            <v-row
                v-if="
                    loadChangeAssessmentNecessary === false ||
                    (loadChangeAssessmentNecessary === true &&
                        loadChange != null)
                "
            >
                <!-- QUESTION: "Wird das DG oder Komponenten davon aktiv beheizt?" (J/N) -->
                <base-radio-group
                    v-model="overheatingVulnerable"
                    :required="isRequiredField($v.overheatingVulnerable)"
                    :label="$t('overheating-vulnerable-label')"
                    :error-messages="overheatingVulnerableErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('overheatingVulnerable')"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>
            <v-container
                v-if="overheatingVulnerable === true"
                fill-height
                fluid
                no-gutters
                style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
                class="pa-0 ma-0"
            >
                <v-row class="ml-3 pt-0">
                    <!-- QUESTION: "Ändern sich die Werkstoffeigenschaften bei Ausfall der Kühlung?" (J/N) -->
                    <base-radio-group
                        v-model="coolingMaterialPropertiesChange"
                        :required="
                            isRequiredField($v.coolingMaterialPropertiesChange)
                        "
                        :label="$t('cooling-material-properties-change-label')"
                        :error-messages="coolingMaterialPropertiesChangeErrors"
                        append-icon="mdi-information-outline"
                        @click:append="
                            showFaq('coolingMaterialPropertiesChange')
                        "
                    >
                        <v-radio :label="$t('yes')" :value="true"></v-radio>
                        <v-radio :label="$t('no')" :value="false"></v-radio>
                    </base-radio-group>
                </v-row>
            </v-container>

            <v-row
                v-if="
                    (overheatingVulnerable === true) &
                    (coolingMaterialPropertiesChange === true)
                "
            >
                <!-- QUESTION: "Ist das Geräte ausgerüstet für zumindest zeitweise autonomen Betrieb, ohne ständige Überwachung?" (J/N) -->
                <base-radio-group
                    v-model="autonomousDevice"
                    :required="isRequiredField($v.autonomousDevice)"
                    :label="$t('autonomous-device-label')"
                    :error-messages="autonomousDeviceErrors"
                    append-icon="mdi-information-outline"
                    @click:append="showFaq('autonomousDevice')"
                >
                    <v-radio :label="$t('yes')" :value="true"></v-radio>
                    <v-radio :label="$t('no')" :value="false"></v-radio>
                </base-radio-group>
            </v-row>
            <v-container
                v-if="
                    overheatingVulnerable === true &&
                    coolingMaterialPropertiesChange === true &&
                    autonomousDevice != null
                "
                fill-height
                fluid
                no-gutters
                style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
                class="pa-0 ma-0"
            >
                <v-row class="pl-3">
                    <v-col>
                        <!-- WENN "JA" > QUESTION: Wie lange kann das Gerät maximal autonom betrieben werden (siehe Prüfdokument)? > Werteliste -->
                        <!-- bob -->
                        <base-select
                            :items="bobItemsSorted"
                            item-value="key"
                            v-model="bob"
                            :label="autonomousDevice === true ? $t('bob-yes-label') : $t('bob-no-label')"
                            :hint="autonomousDevice === true ? $t('bob-yes-hint') : $t('bob-no-hint')"
                            :clearable="!readonlyModus"
                            :error-messages="bobErrors"
                            append-icon="mdi-information-outline"
                            @click:append="showFaq('bobItems')"
                            :required="isRequiredField($v.bob)"
                            :item-text="
                                (item) =>
                                    item.value[$i18n.locale]
                                        ? item.value[$i18n.locale]
                                        : item.value.de
                            "
                        >
                        </base-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-form>
</template>
<script>
import {
    required,
    requiredIf,
    minValue,
    maxValue
} from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import WNumericField from "@/components/fields/WNumericField.vue";
import StepContentTitle from "../components/step-content-title.vue";
import { REGISTRATION_LOAD_CHANGE_TYPES } from "@/data/globals.js";
import validateMixin from "@/mixins/validate";
export default {
    name: "FormEquipmentDetails",
    mixins: [validateMixin],
    validations: {
        ambulantObject: { required },
        quickRelease: { required },
        loadChangeAssessmentNecessary: { required },
        loadChange: {
            required: requiredIf(function () {
                if (this.loadChangeAssessmentNecessary) {
                    return true;
                } else {
                    return false;
                }
            })
        },
        loadChangeMax: {
            required: requiredIf(function () {
                if (
                    this.loadChangeAssessmentNecessary &&
                    this.loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                ) {
                    return true;
                } else {
                    return false;
                }
            }),
            minimumValue: minValue("0"),
            minValue: minValue(1),
            maxValue: maxValue(9999999)
        },
        loadChangeYear: {
            required: requiredIf(function () {
                if (
                    this.loadChangeAssessmentNecessary &&
                    this.loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                ) {
                    return true;
                } else {
                    return false;
                }
            }),
            minimumValue: minValue("0"),
            minValue: minValue(1),
            maxValue: maxValue(99999)
        },
        loadChangeDuration: {
            required: requiredIf(function () {
                if (
                    this.loadChangeAssessmentNecessary &&
                    this.loadChange ===
                        REGISTRATION_LOAD_CHANGE_TYPES.TOTAL_USAGE_DURATION
                ) {
                    return true;
                } else {
                    return false;
                }
            }),
            minimumValue: minValue("0"),
            minValue: minValue(1),
            maxValue: maxValue(999)
        },
        overheatingVulnerable: { required },
        coolingMaterialPropertiesChange: {
            required: requiredIf(function () {
                if (this.overheatingVulnerable) {
                    return true;
                } else {
                    return false;
                }
            })
        },
        autonomousDevice: {
            required: requiredIf(function () {
                if (
                    this.overheatingVulnerable &&
                    this.coolingMaterialPropertiesChange
                ) {
                    return true;
                } else {
                    return false;
                }
            })
        },
        bob: {
            required: requiredIf(function () {
                if (
                    this.overheatingVulnerable &&
                    this.coolingMaterialPropertiesChange &&
                    this.autonomousDevice != null
                ) {
                    return true;
                } else {
                    return false;
                }
            })
        }
    },
    components: {
        WNumericField,
        StepContentTitle
    },
    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        registrationData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        bobItems: [],
        REGISTRATION_LOAD_CHANGE_TYPES: REGISTRATION_LOAD_CHANGE_TYPES
    }),
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"]),
        onFieldChange(field, value) {
            this.$emit("field-change", { field, value });
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        showFaq(searchIndex) {
            this.$root.$faqDialog.open(searchIndex);
        },
        reset() {
            this.$v.$reset();
            this.onFieldChange("ambulantObject", null);
            this.onFieldChange("quickRelease", null);
            this.onFieldChange("loadChangeAssessmentNecessary", null);
            this.onFieldChange("loadChange", null);
            this.onFieldChange("loadChangeMax", null);
            this.onFieldChange("loadChangeYear", null);
            this.onFieldChange("loadChangeDuration", null);
            this.onFieldChange("overheatingVulnerable", null);
            this.onFieldChange("coolingMaterialPropertiesChange", null);
            this.onFieldChange("autonomousDevice", null);
            this.onFieldChange("bob", null);
        },
        setFakeData() {
            this.onFieldChange("ambulantObject", true);
            this.onFieldChange("quickRelease", true);
            this.onFieldChange("loadChangeAssessmentNecessary", true);
            this.onFieldChange(
                "loadChange",
                REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
            );
            this.onFieldChange("loadChangeMax", "22");
            this.onFieldChange("loadChangeYear", "33");
            this.onFieldChange("loadChangeDuration", "44");
            this.onFieldChange("overheatingVulnerable", true);
            this.onFieldChange("coolingMaterialPropertiesChange", true);
            this.onFieldChange("autonomousDevice", true);
            this.onFieldChange("bob", "A24");
        }
    },
    computed: {
        //two-way-data-binding
        ambulantObject: {
            get() {
                return this.registrationData.ambulantObject;
            },
            set(value) {
                this.onFieldChange("ambulantObject", value);
            }
        },
        quickRelease: {
            get() {
                return this.registrationData.quickRelease;
            },
            set(value) {
                this.onFieldChange("quickRelease", value);
            }
        },
        loadChangeAssessmentNecessary: {
            get() {
                return this.registrationData.loadChangeAssessmentNecessary;
            },
            set(value) {
                this.onFieldChange("loadChangeAssessmentNecessary", value);
            }
        },
        loadChange: {
            get() {
                return this.registrationData.loadChange;
            },
            set(value) {
                this.onFieldChange("loadChange", value);
            }
        },
        loadChangeMax: {
            get() {
                return this.registrationData.loadChangeMax;
            },
            set(value) {
                this.onFieldChange("loadChangeMax", value);
            }
        },
        loadChangeYear: {
            get() {
                return this.registrationData.loadChangeYear;
            },
            set(value) {
                this.onFieldChange("loadChangeYear", value);
            }
        },
        loadChangeDuration: {
            get() {
                return this.registrationData.loadChangeDuration;
            },
            set(value) {
                this.onFieldChange("loadChangeDuration", value);
            }
        },
        overheatingVulnerable: {
            get() {
                return this.registrationData.overheatingVulnerable;
            },
            set(value) {
                this.onFieldChange("overheatingVulnerable", value);
            }
        },
        coolingMaterialPropertiesChange: {
            get() {
                return this.registrationData.coolingMaterialPropertiesChange;
            },
            set(value) {
                this.onFieldChange("coolingMaterialPropertiesChange", value);
            }
        },
        autonomousDevice: {
            get() {
                return this.registrationData.autonomousDevice;
            },
            set(value) {
                this.bob = null;
                this.onFieldChange("autonomousDevice", value);
            }
        },
        bob: {
            get() {
                return this.registrationData.bob;
            },
            set(value) {
                this.onFieldChange("bob", value);
            }
        },
        ...mapGetters({
            developerMode: "settings/developerMode"
        }),
        ambulantObjectErrors() {
            const errors = [];
            if (!this.$v.ambulantObject.$dirty) {
                return errors;
            }
            if (!this.$v.ambulantObject.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        quickReleaseErrors() {
            const errors = [];
            if (!this.$v.quickRelease.$dirty) {
                return errors;
            }
            if (!this.$v.quickRelease.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        loadChangeAssessmentNecessaryErrors() {
            const errors = [];
            if (!this.$v.loadChangeAssessmentNecessary.$dirty) {
                return errors;
            }
            if (!this.$v.loadChangeAssessmentNecessary.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        loadChangeErrors() {
            const errors = [];
            if (!this.$v.loadChange.$dirty) {
                return errors;
            }
            if (!this.$v.loadChange.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        loadChangeMaxErrors() {
            const errors = [];
            if (!this.$v.loadChangeMax.$dirty) {
                return errors;
            }
            if (!this.$v.loadChangeMax.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.loadChangeMax.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.loadChangeMax.$params.minValue.min
                    })
                );
            }
            return errors;
        },
        loadChangeYearErrors() {
            const errors = [];
            if (!this.$v.loadChangeYear.$dirty) {
                return errors;
            }
            if (!this.$v.loadChangeYear.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.loadChangeYear.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue: this.$v.loadChangeYear.$params.minValue.min
                    })
                );
            }
            return errors;
        },
        loadChangeDurationErrors() {
            const errors = [];
            if (!this.$v.loadChangeDuration.$dirty) {
                return errors;
            }
            if (!this.$v.loadChangeDuration.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.loadChangeDuration.minValue) {
                errors.push(
                    this.$t("field-min-value", {
                        minValue:
                            this.$v.loadChangeDuration.$params.minValue.min
                    })
                );
            }
            return errors;
        },
        overheatingVulnerableErrors() {
            const errors = [];
            if (!this.$v.overheatingVulnerable.$dirty) {
                return errors;
            }
            if (!this.$v.overheatingVulnerable.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        coolingMaterialPropertiesChangeErrors() {
            const errors = [];
            if (!this.$v.coolingMaterialPropertiesChange.$dirty) {
                return errors;
            }
            if (!this.$v.coolingMaterialPropertiesChange.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        autonomousDeviceErrors() {
            const errors = [];
            if (!this.$v.autonomousDevice.$dirty) {
                return errors;
            }
            if (!this.$v.autonomousDevice.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        bobErrors() {
            const errors = [];
            if (!this.$v.bob.$dirty) {
                return errors;
            }
            if (!this.$v.bob.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        bobItemsSorted() {
            if (!this.bobItems) return [];
            let keys = ["UMB", "MIB"];
            if (this.autonomousDevice) {
                // IF YES, remove key === "UMB", key === "MIB"
                return this.bobItems.filter((el) => !keys.includes(el.key));
            } else {
                // IF NO display only key === "UMB", key === "MIB"
                return this.bobItems.filter((el) => keys.includes(el.key));
            }
        }
    },
    created() {
        this.loader().show();
        let bobKey = "bob";
        this.getStoredAttributesByKey(bobKey)
            .then((items) => {
                this.bobItems = items;
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    this.$t("attributes-load-error", { attributeName: bobKey })
                );
            });
    }
};
</script>
