<i18n src="./summary-object-details-i18n.yaml"></i18n>
<template>
    <BaseListItem :title="$t('title-object-details')" :expandable="expandable">
        <template v-slot:content v-if="task.objectType === 'PRESSURE_EQUIPMENT'">
            <ul class="text-subtitle-2 primary--text">
                <li v-if="task.ambulantObject === null">
                    <span class="font-italic">{{ $t("not-recorded") }}</span>
                </li>
                <li v-if="task.ambulantObject">
                    {{ $t("ambulant-object") }}
                </li>
                <li v-if="task.quickRelease">
                    {{ $t("quick-release") }}
                </li>
                <li v-if="task.loadChangeAssessmentNecessary">
                    <span
                        v-if="
                            task.loadChange ===
                            REGISTRATION_LOAD_CHANGE_TYPES.MAX_ALLOWED_QUANTITY
                        "
                    >
                        <span v-if="task.loadChangeMax">
                            <i18n path="load-change-max" tag="span">
                                <template #count>
                                    <span class="font-weight-bold">{{
                                        task.loadChangeMax
                                    }}</span>
                                </template>
                            </i18n>
                        </span>
                        <span v-if="task.loadChangeYear">
                            <span v-if="task.loadChangeMax">,&nbsp;</span>
                            <i18n path="load-change-year" tag="span">
                                <template #count>
                                    <span class="font-weight-bold">{{
                                        task.loadChangeYear
                                    }}</span>
                                </template>
                            </i18n>
                        </span>
                    </span>
                    <span
                        v-if="
                            task.loadChange ===
                            REGISTRATION_LOAD_CHANGE_TYPES.TOTAL_USAGE_DURATION
                        "
                    >
                        <span v-if="task.loadChangeDuration">{{
                            $tc(
                                "load-change-duration",
                                task.loadChangeDuration,
                                { count: task.loadChangeDuration }
                            )
                        }}</span>
                    </span>
                </li>
                <li v-if="loadChangeInspectionInterval">
                    {{ $t("load-change-inspection-interval") }}
                    <span class="font-weight-bold">
                        {{
                            $tc(
                                "label-load-change-inspection-interval",
                                loadChangeInspectionInterval,
                                { value: loadChangeInspectionInterval }
                            )
                        }}
                    </span>
                    <CopyToClipboard
                        v-if="showCopyBtn"
                        :value="loadChangeInspectionInterval"
                    />
                </li>
                <li v-if="activelyHeated === true">
                    {{ $t("actively-heated") }}
                </li>
                <li v-if="activelyHeated === false">
                    {{ $t("device-not-actively-heated") }}
                </li>
                <li v-if="task.autonomousDevice != null">
                    <span v-if="task.bob === 'UMB' || task.bob === 'MIB'">
                        {{ $t("bob-1", { bob: bobItem }) }}
                    </span>
                    <span v-else>
                        {{ $t("bob-2", { bob: bobItem }) }}
                    </span>
                </li>
            </ul>
        </template>
        <template v-slot:content v-if="task.objectType === 'FIRE_BOTTLES_EQUIPMENT'">
            <ul class="text-subtitle-2 primary--text">
                <li v-if="task.reason === null">
                    <span class="font-italic">{{ $t("not-recorded") }}</span>
                </li>
                <li v-if="task.reason === 'NEW_INSTALLATION'">
                    {{ $t("reason-new-installation") }}
                </li>
                <li v-if="task.reason === 'BOTTLE_CHANGE'">
                    {{ $t("reason-bottle-change") }}
                </li>
                <li v-if="task.tpedStandard === true">
                    {{ $t("tped-standard-true") }}
                </li>
            </ul>
        </template>
    </BaseListItem>
</template>

<script>
import { REGISTRATION_LOAD_CHANGE_TYPES } from "@/data/globals.js";
import { mapActions } from "vuex";
import mixin from "../mixin";
export default {
    name: "SummaryObjectDetails",
    mixins: [mixin],
    components: {
        BaseListItem: () => import("../base-list-item.vue")
    },
    data: () => ({
        bobItems: [],
        REGISTRATION_LOAD_CHANGE_TYPES
    }),
    computed: {
        activelyHeated() {
            let ret = false;
            if (this.task) {
                if (
                    "overheatingVulnerable" in this.task &&
                    "coolingMaterialPropertiesChange" in this.task
                ) {
                    if (
                        this.task.overheatingVulnerable &&
                        this.task.coolingMaterialPropertiesChange
                    ) {
                        ret = true;
                    }
                }
            }
            return ret;
        },
        bobItem() {
            if (this.bobItems.length === 0 || !this.task || !this.task.bob)
                return null;
            const sorted = this.bobItems.filter(
                (item) => item.key === this.task.bob
            );
            const locale = this.$root.$i18n.locale || "de";
            if (sorted.length != 1) return null;
            const item = sorted[0];
            if ("value" in item) {
                return item.value[locale];
            }
            return null;
        },
        loadChangeInspectionInterval() {
            const loadChangeMax = this.task.loadChangeMax;
            const loadChangeYear = this.task.loadChangeYear;
            if (loadChangeMax !== null && loadChangeYear !== null) {
                return parseFloat(loadChangeMax / loadChangeYear).toFixed(1);
            }
            return null;
        }
    },
    methods: {
        ...mapActions("attributes", ["getStoredAttributesByKey"])
    },
    created() {
        this.loader().show();
        let bobKey = "bob";
        this.getStoredAttributesByKey(bobKey)
            .then((items) => {
                this.bobItems = items;
                this.loader().hide();
            })
            .catch((error) => {
                console.log(error);
                this.loader().hide();
                this.$toast.error(
                    this.$t("attributes-load-error", { attributeName: bobKey })
                );
            });
    }
};
</script>
