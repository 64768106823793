<i18n src="./Create-i18n.yaml"></i18n>
<template>
    <v-container fluid pa-0 ma-0>
        <template v-if="isLoading">
            <v-sheet class="px-3 pt-3 pb-3" v-for="n in 12" :key="n">
                <v-skeleton-loader
                    class="mx-auto"
                    type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                >
                </v-skeleton-loader>
            </v-sheet>
        </template>
        <template if="registration" fluid fill-height pa-0 ma-0>
            <app-dev-container color="info lighten-3" expanded>
                <template v-slot:content>
                    <tree-view
                        :data="Object.assign({}, registration)"
                        :options="{ maxDepth: 1 }"
                    ></tree-view>
                </template>
            </app-dev-container>
            <StepperPressureEquipment
                v-if="registration.objectType === 'PRESSURE_EQUIPMENT'"
                ref="stepperPressureEquipment"
                :expandBasicDataCard="expandBasicDataCard"
                @change:expand-basic-data-card="
                    expandBasicDataCard = !expandBasicDataCard
                "
            />
            <StepperFireBottlesEquipment
                v-if="registration.objectType === 'FIRE_BOTTLES_EQUIPMENT'"
                ref="stepperPressureEquipment"
                :expandBasicDataCard="expandBasicDataCard"
                @change:expand-basic-data-card="
                    expandBasicDataCard = !expandBasicDataCard
                "
            />
        </template>
    </v-container>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import _ from "lodash";
import * as ACTION_TYPES from "@/store/types/action-types";
import * as MUTATION_TYPES from "@/store/types/mutation-types";
export default {
    name: "RegistrantTaskCreate",
    components: {
        StepperPressureEquipment: () =>
            import(
                "@/components/tasks/edit/registrant/steppers/pressure-equipment/stepper.vue"
            ),
        StepperFireBottlesEquipment: () =>
            import(
                "@/components/tasks/edit/registrant/steppers/fire-bottles-equipment/stepper.vue"
            )
    },

    props: {
        referenceId: {
            type: String,
            default: null
        }
    },

    data: () => ({
        isEditing: true,
        expandBasicDataCard: true
    }),

    computed: {
        ...mapState("settings", {
            isLoading: "showLoader"
        }),
        ...mapGetters({
            developerMode: "settings/developerMode",
            registrationTimestampStatus: "settings/registrationTimestampStatus",
            registrationTimestamp: "settings/registrationTimestamp",
            registration: "registration/registration",
            readonlyModus: "registration/readonlyModus",
            isAutoSaving: "registration/isAutoSaving"
        }),
        ...mapGetters("auth", {
            isAuth: "isAuthenticated"
        }),
        workingTitle() {
            return this.$store.state.registration.workingTitle;
        },
        manufacturer() {
            return this.$store.state.registration.manufacturer;
        },
        factoryNumber() {
            return this.$store.state.registration.factoryNumber;
        },
        constructionYear() {
            return this.$store.state.registration.constructionYear;
        }
    },
    methods: {
        ...mapMutations("registration", [MUTATION_TYPES.CLEAR_REGISTRATION]),
        ...mapActions("registration", [
            ACTION_TYPES.FETCH_OPEN_REGISTRATION_DATA_BY_REFERENCE_ID
        ]),
        getData() {
            if (!this.referenceId) {
                return;
            }
            this.loader().show();
            this[ACTION_TYPES.FETCH_OPEN_REGISTRATION_DATA_BY_REFERENCE_ID](
                this.referenceId
            )
                .then((response) => {
                    let status = response.status;
                    if (status === 200) {
                        this.setTitles();
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    let resStatus = error?.response?.status;
                    if (resStatus === 404) {
                        this.$router.push("/404");
                    } else if (resStatus === 403) {
                        this.$router.push("/403");
                    } else {
                        this.$router.push("/500");
                    }
                    this.loader().hide();
                });
        },
        setTitles() {
            if (!this.registration) return;
            if (
                !this.workingTitle ||
                !this.manufacturer ||
                !this.factoryNumber ||
                !this.constructionYear
            ) {
                this.$store.commit("settings/setDefaultTitles");
                return;
            }
            let data = {};
            data.title01 = this.getTitle01();
            data.title02 = this.getTitle02();
            data.registrationTimestamp = this.registration.lastChangeTimestamp;
            this.$store.commit("settings/setTitles", data);
        },
        getTitle01() {
            let title = "";
            if (this.workingTitle) {
                title += this.workingTitle;
            }
            return title;
        },
        getTitle02() {
            let title = "";
            if (this.registration) {
                if (_.isObject(this.manufacturer)) {
                    title += this.manufacturer.name + "<br>";
                } else {
                    title += this.manufacturer + "<br>";
                }
                title +=
                    this.$t("serial-number-short") +
                    ":" +
                    this.factoryNumber +
                    ", " +
                    this.$t("construction-year-short") +
                    ":" +
                    this.constructionYear;
            }
            return title;
        },
        reset() {
            //reset to defautl values
            this[MUTATION_TYPES.CLEAR_REGISTRATION]();
        },
        preventNav(event) {
            if (!this.isAutoSaving) return;
            event.preventDefault();
            event.returnValue = "";
        }
    },
    watch: {
        referenceId: {
            handler: function (newValue) {
                if (newValue) {
                    this.reset();
                    this.getData();
                } else {
                    if (this.isAuth) {
                        this.$router.push({ name: "user-dashboard" });
                    } else {
                        this.$router.push("/");
                    }
                }
            },
            immediate: true
        }
    },
    destroyed() {
        this.$store.commit("settings/setDefaultTitles");
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    beforeRouteLeave(to, from, next) {
        if (!this.registration.state || this.readonlyModus) {
            this.reset();
            next();
            return;
        } else if (this.isAutoSaving) {
            this.$toast.warning(this.$t("task-is-saving-warning"));
        } else if (this.referenceId) {
            let msgOptions = {
                title: {
                    text: this.$t("msg-box-leave-title"),
                    icon: "mdi-alert"
                },
                message: this.$t("msg-box-leave-text", {
                    time: this.$moment
                        .utc(this.registrationTimestamp)
                        .local()
                        .from(this.$moment())
                })
            };
            this.$root.$msgBox.open(msgOptions).then((result) => {
                if (result === "agree") {
                    this.reset();
                    next();
                } else {
                    next(false);
                }
            });
        } else {
            next();
        }
    }
};
</script>
