<i18n src="@/data/countries/i18n/all.json"></i18n>
<i18n src="./WManufacturerSearch-i18n.yaml"></i18n>
<template>
    <div>
        <v-autocomplete
            v-model="selectedManufacturer"
            v-bind="$attrs"
            v-on="$listeners"
            :items="manufacturersList"
            @click:clear="reset"
            @change="onAutocompleteChange"
            :clearable="!readonly"
            :search-input.sync="search"
            :loading="isLoading"
            return-object
            no-filter
            filled
            hide-selected
            full-width
            open-on-clear
            v-disabled-icon-focus
            :required="required"
            :class="showRequiredFieldLine ? 'cls-required-field' : undefined"
        >
            <template v-slot:item="{ item }">
                <div
                    :class="item.deletedState > 0 ? 'grey--text' : undefined"
                    style="
                        display: flex;
                        align-items: center;
                        column-gap: 6px;
                        flex-wrap: wrap;
                    "
                >
                    <span v-html="item.name"></span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span
                                v-on="on"
                                style="display: flex; align-items: center"
                            >
                                (<country-flag
                                    size="small"
                                    v-if="item.country"
                                    :country="item.country"
                                />
                                <span class="pl-1 text-caption">{{
                                    item.country
                                }}</span
                                >)<span v-if="item.department">,</span>
                            </span>
                        </template>
                        <span>
                            {{
                                getCountryTranslationByCountryCode(item.country)
                            }}
                        </span>
                    </v-tooltip>
                    <span
                        v-if="item.department"
                        v-html="item.department"
                    ></span>
                    <span v-if="item.deletedState > 0"
                        >( {{ $t("manufacturer-inactive") }} )</span
                    >
                </div>
            </template>
            <template v-slot:selection="{ item }">
                <div :class="item.deletedState > 0 ? 'grey--text' : undefined">
                    <span
                        v-if="item.department"
                        v-html="item.name + ', ' + item.department"
                    ></span>
                    <span v-else v-html="item.name"></span>
                    <span v-if="item.deletedState > 0" class="pl-2"
                        >( {{ $t("manufacturer-inactive") }} )</span
                    >
                </div>
            </template>
            <template v-slot:no-data>
                <v-list-item
                    disabled
                    v-if="
                        !search ||
                        (search &&
                            !isLoading &&
                            search.length <= searchMinLength)
                    "
                >
                    <v-icon class="pr-2">mdi-keyboard</v-icon>
                    <span>
                        {{
                            $t("no-results-no-search", {
                                minLength: searchMinLength
                            })
                        }}
                    </span>
                </v-list-item>
                <v-list-item
                    v-if="
                        manufacturersList.length == 0 &&
                        search &&
                        !isLoading &&
                        search.length >= searchMinLength
                    "
                    @click="dialogAddManufacturer = true"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            <i18n path="add-manufacturer-text" tag="span">
                                <template #manufacturer>
                                    <strong class="regOfficePrimary--text">{{
                                        search
                                    }}</strong>
                                </template>
                                <template #action>
                                    <v-btn
                                        icon
                                        color="info"
                                        @click="dialogAddManufacturer = true"
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </template>
                            </i18n>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template
                v-slot:append-item
                v-if="manufacturersList.length > 0 && !selectedManufacturer"
            >
                <v-divider class="mb-2"></v-divider>
                <v-list-item
                    dense
                    v-if="
                        (manufacturersList.length == numberOfHits &&
                            numberOfHits > 0) ||
                        (manufacturersList.length == 0 &&
                            !isLoading &&
                            search.length >= searchMinLength)
                    "
                    @click="dialogAddManufacturer = true"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            <i18n path="add-manufacturer-text" tag="span">
                                <template #manufacturer>
                                    <strong class="regOfficePrimary--text">{{
                                        search
                                    }}</strong>
                                </template>
                                <template #action>
                                    <v-btn
                                        icon
                                        color="info"
                                        @click="dialogAddManufacturer = true"
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </template>
                            </i18n>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <infinite-loading
                    v-if="
                        manufacturersList.length < numberOfHits &&
                        numberOfHits > 0
                    "
                    @infinite="infiniteHandler"
                >
                    <div slot="spinner">
                        {{
                            $t("all-items-match-loaded", {
                                itemsLength: manufacturersList.length,
                                numberOfHits: numberOfHits
                            })
                        }}
                    </div>
                    <div slot="no-more">
                        {{ $t("no-more-items", { type: $t("type") }) }}
                    </div>
                    <div slot="no-results">{{ $t("no-results") }}</div>
                </infinite-loading>
            </template>
        </v-autocomplete>
        <DialogAddress
            @save="onSaveManufacturer"
            :value="dialogAddManufacturer"
            @input="dialogAddManufacturer = $event"
            :requiredFields="manufacturersRequiredFields"
            :title="$t('manufacturer-dialog-title')"
            :address="address"
            @address:input="
                (newAddress) => {
                    address = newAddress;
                }
            "
            @address:reset="onResetAddress"
        />
    </div>
</template>
<script>
import _ from "lodash";
import InfiniteLoading from "vue-infinite-loading";
import DialogAddress from "@/components/general/dialog-address.vue";
import { CompanyAddress } from "@/models/tasks";
import mixinWField from "@/mixins/w-field";
export default {
    name: "WManufacturerSearch",
    mixins: [mixinWField],
    components: {
        InfiniteLoading,
        DialogAddress
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            searchValue: null,
            manufacturersList: [],
            selectedManufacturer: null,
            numberOfHits: 0,
            search: null,
            page: 0,
            isLoading: false,
            limit: 25,
            searchMinLength: 2,
            dialogAddManufacturer: false,
            manufacturersRequiredFields: ["companyName", "country"],
            address: new CompanyAddress()
        };
    },
    created() {
        this.fetchData = _.debounce(function () {
            this.isLoading = true;
            let params = {};
            params.query = this.searchValue;
            params.start = this.page;
            params.limit = this.limit;

            this.$store
                .dispatch("manufacturers/searchCompany", params)
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        const resData = response.data.data;
                        const itemsData = resData.items;
                        this.numberOfHits = resData.total;
                        this.manufacturersList.push(...itemsData);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t("error-occured"));
                    this.isLoading = false;
                });
        }, 500);
        this.fetchDataInfinite = _.debounce(
            function ($state) {
                if (
                    this.searchValue &&
                    this.searchValue.length < this.searchMinLength
                ) {
                    return;
                }

                if (this.manufacturersList.length === this.numberOfHits) {
                    $state.complete();
                    return;
                }
                this.isLoading = true;
                this.page = this.page + this.limit;

                let params = {};
                params.query = this.searchValue;
                params.start = this.page;
                params.limit = this.limit;

                this.$store
                    .dispatch("manufacturers/searchCompany", params)
                    .then((response) => {
                        let resStatus = response.status;
                        if (resStatus === 200) {
                            const resData = response.data.data;
                            const itemsData = resData.items;
                            this.numberOfHits = resData.total;
                            this.manufacturersList.push(...itemsData);
                            $state.loaded();
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        $state.error();
                        this.$toast.error(this.$t("error-occured"));
                        this.isLoading = false;
                    });
            },
            2000,
            { leading: true }
        );
    },
    computed: {
        countries() {
            return this.$t("countries") || [];
        }
    },
    methods: {
        getCountryTranslationByCountryCode(countryCode) {
            if (this.countries.length === 0 || !countryCode) return null;
            return this.countries[countryCode.toUpperCase()];
        },
        infiniteHandler($state) {
            this.isLoading = true;
            this.fetchDataInfinite($state);
        },
        onAutocompleteChange(val) {
            if (val) {
                this.$emit("change", val);
            }
        },
        reset() {
            this.searchValue = null;
            this.search = null;
            this.resetSearch();
            this.onResetAddress();
            this.$emit("change", null);
        },
        resetSearch() {
            this.selectedManufacturer = null;
            this.manufacturersList = [];
            this.numberOfHits = 0;
        },
        onResetAddress() {
            this.address = new CompanyAddress();
        },
        onSaveManufacturer(data) {
            this.loader().show();
            let company = {};
            company.address = data;
            company.name = data.companyName;
            company.department = data.department;
            this.$store
                .dispatch("manufacturers/addCompany", company)
                .then((response) => {
                    let resStatus = response.status;
                    if (resStatus === 200) {
                        this.$toast.success(
                            this.$t("manufacturer-save-success")
                        );
                        const resData = response.data;
                        const company = resData.data;
                        let manufacturer = {};
                        manufacturer.id = company.docId;
                        manufacturer.name = company.name;
                        manufacturer.department = company.department;
                        manufacturer.country = company.address.country;
                        this.resetSearch();
                        this.manufacturersList.push(manufacturer);
                        this.selectedManufacturer = manufacturer;
                        this.$emit("change", manufacturer);
                        this.dialogAddManufacturer = false;
                    }
                    this.loader().hide();
                })
                .catch((error) => {
                    console.log(error);
                    const errorCode = error.response.data.code;
                    switch (errorCode) {
                        case 0:
                            this.$toast.error(
                                this.$t("manufacturer-error-save")
                            );
                            break;
                        case 1:
                            this.$toast.error(
                                this.$t("manufacturer-error-already-exists")
                            );
                            break;
                        default:
                            this.$toast.error(this.$t("error-occured"));
                    }
                    this.loader().hide();
                });
        }
    },
    watch: {
        search(val) {
            if (!val || val.length < this.searchMinLength) {
                this.onResetAddress();
                if (!this.selectedManufacturer) {
                    this.resetSearch();
                }
            } else {
                let valTrim = val.trim();
                this.address.companyName = valTrim;
                this.manufacturersList = [];
                this.page = 0;
                this.numberOfHits = 0;
                this.isLoading = true;
                this.searchValue = valTrim;
                this.fetchData();
            }
        },
        value: {
            handler: function (val) {
                // watch it
                if (val) {
                    if (!this.search) {
                        this.manufacturersList.push(val);
                        this.search = val.name;
                        this.selectedManufacturer = val;
                    }
                } else {
                    this.reset();
                }
            },
            immediate: true
        }
    }
};
</script>
