<i18n src="./form-location-i18n.yaml"></i18n>
<template>
    <v-form :readonly="readonlyModus" :disabled="disabled">
        <app-dev-container>
            <template v-slot:content>
                <base-btn icon color="warning" @click="setFakeData">
                    <v-icon>mdi-bug</v-icon>
                </base-btn>
                <base-btn icon color="yellow" @click="reset">
                    <v-icon>mdi-refresh</v-icon>
                </base-btn>
            </template>
        </app-dev-container>

        <StepContentTitle :title="$t('location-title')" />
        <v-container fluid class="pa-0 ma-0">
            <!-- Standort -->
            <v-row>
                <v-col class="pb-1">
                    <WCountrySelect
                        ref="country"
                        v-model="country"
                        :disabled="disabled"
                        :readonly="readonlyModus"
                        :preferred-countries="[
                            'CH',
                            'LI',
                            'DE',
                            'FR',
                            'IT',
                            'AT'
                        ]"
                        @click:clear="reset"
                        :error-messages="countryErrors"
                        :required="isRequiredField($v.address.country)"
                    ></WCountrySelect>
                </v-col>
            </v-row>

            <template v-if="country">
                <v-row>
                    <v-col class="py-1" cols="12" sm="6">
                        <!-- companyName -->
                        <base-text-field
                            v-model="companyName"
                            :label="$t('company-name-label')"
                            :hint="$t('company-name-hint')"
                            :error-messages="companyNameErrors"
                            :counter="
                                $v.address.companyName.$params.maxLength.max
                            "
                            :required="isRequiredField($v.address.companyName)"
                        ></base-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="6">
                        <!-- position -->
                        <base-text-field
                            v-model="position"
                            :label="positionLabel"
                            :hint="positionHint"
                            :error-messages="positionErrors"
                            :counter="$v.address.position.$params.maxLength.max"
                            :required="isRequiredField($v.address.position)"
                        ></base-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-1" cols="12" sm="10">
                        <!-- Strasse -->
                        <base-text-field
                            v-model="street"
                            :label="$t('street-label')"
                            :counter="$v.address.street.$params.maxLength.max"
                            :error-messages="streetErrors"
                            :required="isRequiredField($v.address.street)"
                        ></base-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="2">
                        <base-text-field
                            v-model="houseNr"
                            :label="$t('house-nr-label')"
                            :error-messages="houseNrErrors"
                            :counter="$v.address.houseNr.$params.maxLength.max"
                            :required="isRequiredField($v.address.houseNr)"
                        ></base-text-field>
                    </v-col>
                </v-row>
                <w-location-search
                    v-if="isLocationSearchAvailable(country)"
                    v-model="locationSearch"
                    :disabled="disabled"
                    :country="country"
                    :readonly="readonlyModus"
                    :error-messages="locationSearchErrors"
                    :required="
                        isRequiredField($v.address.zipCode) ||
                        isRequiredField($v.address.city) ||
                        isRequiredField($v.address.canton)
                    "
                    @reset="onWLocationSearchReset"
                />
                <v-row v-else>
                    <v-col class="py-1" cols="12" sm="6" md="2">
                        <!-- PLZ -->
                        <base-text-field
                            v-model="zipCode"
                            :label="$t('zip-code-label')"
                            :counter="$v.address.zipCode.$params.maxLength.max"
                            :error-messages="zipCodeErrors"
                            :required="isRequiredField($v.address.zipCode)"
                        ></base-text-field>
                    </v-col>
                    <v-col
                        class="py-1"
                        cols="12"
                        :md="address.country === 'CH' ? 8 : 10"
                    >
                        <!-- Ort -->
                        <base-text-field
                            v-model="city"
                            :label="$t('city-label')"
                            :counter="$v.address.city.$params.maxLength.max"
                            :error-messages="cityErrors"
                            :required="isRequiredField($v.address.city)"
                        ></base-text-field>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </v-form>
</template>
<script>
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import WCountrySelect from "@/components/fields/WCountrySelect.vue";
import StepContentTitle from "../components/step-content-title.vue";
import _ from "lodash";
import validateMixin from "@/mixins/validate";
import Helpers from "@/utils/helpers";
export default {
    name: "FormLocation",
    mixins: [validateMixin],
    validations() {
        return {
            address: {
                street: {
                    required,
                    maxLength: maxLength(30)
                },
                houseNr: {
                    maxLength: maxLength(30)
                },
                canton: {
                    required: requiredIf(function () {
                        if (this.address.country === "CH") {
                            return true;
                        } else {
                            return false;
                        }
                    })
                },
                zipCode: {
                    required,
                    maxLength:
                        this.address.country === "CH"
                            ? maxLength(4)
                            : maxLength(10)
                },
                city: {
                    required,
                    maxLength: maxLength(30)
                },
                companyName: {
                    required,
                    maxLength: maxLength(30)
                },
                position: {
                    required,
                    maxLength: maxLength(30)
                },
                country: {
                    required
                }
            }
        };
    },
    components: {
        WCountrySelect,
        StepContentTitle
    },
    props: {
        readonlyModus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        address: {
            type: Object,
            default: () => {}
        },
        objectType: {
            type: String
        }
    },
    methods: {
        onWLocationSearchReset() {
            let eqLocation = _.cloneDeep(this.address);
            eqLocation = _.set(eqLocation, "canton", null);
            eqLocation = _.set(eqLocation, "zipCode", null);
            eqLocation = _.set(eqLocation, "city", null);
            this.onFormChange(eqLocation);
        },
        onFieldChange(field, value) {
            if (!field) return;
            this.onFormFieldChange(field, value);
            this.$emit("field-change", { field, value });
        },
        onFormFieldChange(field, value) {
            let eqLocation = _.cloneDeep(this.address);
            eqLocation = _.set(eqLocation, field, value);
            this.onFormChange(eqLocation);
        },
        onFormChange(form) {
            this.$emit("form-change", form);
        },
        onValidateFields() {
            if (this.$v.$dirty) {
                this.validate();
            } else {
                return;
            }
        },
        reset() {
            this.$v.$reset();
            this.$emit("reset");
        },
        setFakeData() {
            let eqLocation = _.cloneDeep(this.address);
            eqLocation.companyName = "WAS.ch GmbH";
            eqLocation.position = "Raum 02 im Betriebsgebäud 2";
            eqLocation.street = "Vonwilstrasse";
            eqLocation.houseNr = "23";
            eqLocation.canton = "SG";
            eqLocation.zipCode = "9000";
            eqLocation.city = "St. Gallen";
            eqLocation.country = "CH";
            this.onFormChange(eqLocation);
        },
        isLocationSearchAvailable(country) {
            return Helpers.isLocationSearchAvailable(country);
        }
    },
    computed: {
        locationSearch: {
            get() {
                let search = {};
                search.zipCode = this.zipCode || null;
                search.city = this.city || null;
                search.canton = this.canton || null;
                if (search.zipCode || search.city || search.canton) {
                    return search;
                }
                return null;
            },
            set(value) {
                if (!value) return;
                let eqLocation = _.cloneDeep(this.address);
                eqLocation = _.set(eqLocation, "canton", value.canton);
                eqLocation = _.set(eqLocation, "zipCode", value.zipCode);
                eqLocation = _.set(eqLocation, "city", value.city);
                this.onFormChange(eqLocation);
                this.$emit("location-search-changed", value);
            }
        },
        country: {
            get() {
                return this.address.country;
            },
            set(value) {
                this.reset();
                this.onFieldChange("country", value);
            }
        },
        companyName: {
            get() {
                return this.address.companyName;
            },
            set(value) {
                this.onFieldChange("companyName", value);
            }
        },
        positionLabel() {
            const objectType = this.objectType;
            return this.$t('position-label-' + objectType);
        },
        positionHint() {
            const objectType = this.objectType;
            return this.$t('position-hint-' + objectType);
        },
        position: {
            get() {
                return this.address.position;
            },
            set(value) {
                this.onFieldChange("position", value);
            }
        },
        street: {
            get() {
                return this.address.street;
            },
            set(value) {
                this.onFieldChange("street", value);
            }
        },
        houseNr: {
            get() {
                return this.address.houseNr;
            },
            set(value) {
                this.onFieldChange("houseNr", value);
            }
        },
        zipCode: {
            get() {
                return this.address.zipCode;
            },
            set(value) {
                this.onFieldChange("zipCode", value);
            }
        },
        city: {
            get() {
                return this.address.city;
            },
            set(value) {
                this.onFieldChange("city", value);
            }
        },
        canton: {
            get() {
                return this.address.canton;
            },
            set(value) {
                this.onFieldChange("canton", value);
            }
        },
        streetErrors() {
            const errors = [];
            if (!this.$v.address.street.$dirty) {
                return errors;
            }
            if (!this.$v.address.street.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.street.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.street.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        houseNrErrors() {
            const errors = [];
            if (!this.$v.address.houseNr.$dirty) {
                return errors;
            }
            if (!this.$v.address.houseNr.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.houseNr.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        locationSearchErrors() {
            const errors = [];
            if (
                !this.$v.address.canton.$dirty ||
                !this.$v.address.zipCode.$dirty ||
                !this.$v.address.city.$dirty
            ) {
                return errors;
            }
            if (
                !this.$v.address.canton.required ||
                !this.$v.address.zipCode.required ||
                !this.$v.address.city.required
            ) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        cantonErrors() {
            const errors = [];
            if (!this.$v.address.canton.$dirty) {
                return errors;
            }
            if (!this.$v.address.canton.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        },
        zipCodeErrors() {
            const errors = [];
            if (!this.$v.address.zipCode.$dirty) {
                return errors;
            }
            if (!this.$v.address.zipCode.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.zipCode.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.zipCode.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.address.city.$dirty) {
                return errors;
            }
            if (!this.$v.address.city.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.city.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength: this.$v.address.city.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        companyNameErrors() {
            const errors = [];
            if (!this.$v.address.companyName.$dirty) {
                return errors;
            }
            if (!this.$v.address.companyName.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.companyName.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.companyName.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        positionErrors() {
            const errors = [];
            if (!this.$v.address.position.$dirty) {
                return errors;
            }
            if (!this.$v.address.position.required) {
                errors.push(this.$t("field-required"));
            }
            if (!this.$v.address.position.maxLength) {
                errors.push(
                    this.$t("field-max-length", {
                        maxLength:
                            this.$v.address.position.$params.maxLength.max
                    })
                );
            }
            return errors;
        },
        countryErrors() {
            const errors = [];
            if (!this.$v.address.country.$dirty) {
                return errors;
            }
            if (!this.$v.address.country.required) {
                errors.push(this.$t("field-required"));
            }
            return errors;
        }
    }
};
</script>
